const ENDPOINTS = {
  json: 'api/cache/db',
  product: 'api/products/',
  accessory: 'api/accessories/',
  user: 'api/user-editor/sso/me',
  login: 'api/user-editor/sso/virtual-showroom/redirect',
  servicesMenu: 'api/change-service-menu',
  logout: 'api/user-editor/sso/virtual-showroom/logout'
}

export {
  ENDPOINTS
}
