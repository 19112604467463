import React from 'react'
import {
  StyledMenuDropdownButton,
  StyledMenuDropdownIconButton,
  StyledMenuDropdownButtonIcon,
  StyledMenuDropdownButtonLabel
} from './styled'
import { ButtonLabel } from '../Typography'
import Icon from '../Icon'
import { useTranslation } from 'react-i18next'

const MenuDropdownButton = ({ icon, label, asButton, onButtonClick }) => {
  const { t } = useTranslation()
  return (
    <>
      {
        asButton ?
          <StyledMenuDropdownButton onClick={onButtonClick}>
            {icon && <StyledMenuDropdownButtonIcon>
              <Icon name={icon} />
            </StyledMenuDropdownButtonIcon>}
            <StyledMenuDropdownButtonLabel>
              <ButtonLabel tiny semiBold>{label}</ButtonLabel>
            </StyledMenuDropdownButtonLabel>
          </StyledMenuDropdownButton>
          :
          <StyledMenuDropdownIconButton onClick={onButtonClick}>
            <Icon name={icon} />
          </StyledMenuDropdownIconButton>
      }
    </>
  )
}

export default MenuDropdownButton
