import React, { useState, useContext, createContext, useEffect, useRef } from 'react'
import { GtmContext } from './Gtm'

const EVENT_TYPES = {
  menu: 'Menu',
  home: 'VSR Home',
  neighborhood: 'VSR Neighborhood',
  building: 'VSR Building',
  priceAndAccessories: 'Catalog Price and Accessories',
}

const commonEventCallback = (baseConfig, overrideConfig, injectParams) => {
  /**
   * Push the current object for the specified event to the GTM instance
   * @param {object} param0
   * @param {string} param0.eventAction Value of the eventAction field of the object to push
   * @param {string} param0.eventLabel Value of the eventLabel field of the object to push
   */
  const push = ({ eventAction, eventLabel, ...additionalData }) => {
    const pushObj = {
      ...baseConfig,
      ...additionalData,
      // allow passing these two fields too when building the context
      // the values passed during context building have precedence
      eventAction: baseConfig?.eventAction || eventAction,
      eventLabel: baseConfig?.eventLabel || eventLabel,
      ...overrideConfig
    }

    injectParams?.(pushObj)
    dataLayer.push(pushObj)

    console.debug({ pushObj })
    // console.debug(JSON.stringify(pushObj))
  }
  return push
}

// TODO: check whether we will need different behaviors based on the event type
const registerableEventsCallbacks = {
  [EVENT_TYPES.menu]: commonEventCallback,
  [EVENT_TYPES.home]: commonEventCallback,
  [EVENT_TYPES.neighborhood]: commonEventCallback,
  [EVENT_TYPES.building]: commonEventCallback,
  [EVENT_TYPES.priceAndAccessories]: commonEventCallback
}

const DataLayerContext = createContext({})

/**
 * Context provider which allows components to register a custom configuration for the dataLayer.push function
 * to provide to nested components. In this way they can simply call the pusToDataLayer function passing some
 * component instance-specific data (the label for example) and the correct function (chosen and possibly
 * initializated by some ancestor component) will be called.
 * @param {object} param0
 * @param {string} param0.for eventType the nested components should push events to
 * @param {object} param0.customBaseConfig Optional base configuration to init the pushed object. Use this object
 * to send to the push function initialization values which are only available in the ancestor component (the one
 * which instantiates this provider). In this way there is no need to drill down such values to the nested
 * component which will actually call the pushToDataLayer function.
 * @param {object} param0.overrideConfig Optional parameter with base configuration to apply AFTER the child
 * configuration has been loaded, overwriting values set from there.
 * @param {function} param0.mergeFn Optional function to inject additional data into the push object after it has
 * been constructed. Use this function to add to the push object values from a child component but using context and
 * logic provided by a parent component (usually the one which renders the DataLayerProvider component).
 * @returns 
 */
const DataLayerProvider = ({
  children,
  for: eventType,
  customBaseConfig = {},
  overrideConfig = {},
  mergeFn = null
}) => {
  const { userId, baseConfig } = useContext(GtmContext)
  // use the `current` property of this object to customize the injectData funtion
  // from intermediate components
  const { injectData } = useRef(mergeFn)

  // must define this context provider to serve a valid gtm state type to push
  if (typeof eventType !== 'string' || !(eventType in registerableEventsCallbacks)) {
    throw new Error(`Invalid eventType value: ${eventType}`)
  }

  const categorizedBaseConfig = {
    ...baseConfig,
    ...customBaseConfig,
    eventCategory: eventType
  }
  const pushToDataLayerBuilder = registerableEventsCallbacks[eventType]
  const pushToDataLayer = pushToDataLayerBuilder(categorizedBaseConfig, overrideConfig, mergeFn)

  return (
    <DataLayerContext.Provider value={{ pushToDataLayer, injectData }}>
      {children}
    </DataLayerContext.Provider>
  )
}

export { EVENT_TYPES, DataLayerContext }
export default DataLayerProvider