import React, { useState, useContext, createContext, useEffect, useMemo, useRef } from 'react'
import { AuthContext } from './Auth'
import { useParams } from 'react-router'

const USER_ID_HASH = 'on3t3am'

/**
 * Compute the digest for a message using an hashing algorithm, after optionally appending a salt string.
 * @param {string} message Text to hash
 * @param {string} salt Salt to use when hashing the text; defaults to none
 * @param {string} algorithm Name of the algorithm to use; defaults to SHA-256
 * @returns Promise which resolves to the hashed string using the specified algorithm
 * @see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
 */
const computeDigest = async (message, salt = '', algorithm = 'SHA-256') => {
  const saltedMessage = message + (salt ?? '')
  const messageBuffer = new TextEncoder().encode(saltedMessage) // to UTF-8
  const hashBuffer = await crypto.subtle.digest(algorithm, messageBuffer) // hash message
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b =>
    b.toString(16).padStart(2, '0')).join('') // convert bytes to hex strings
  return hashHex
}

const GtmContext = createContext({})

const GtmProvider = ({ children, for: eventType }) => {
  const [userId, setUserId] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  /**
   * since the sha256 computation is asynchronous we use a state variable to tell
   * when that function has returned and the userId has been set
   */
  const [waitingForUserId, setWaitingForUserId] = useState(true)
  const authContextData = useContext(AuthContext)
  const { lang } = useParams()
  const dataLayerEventsQueue = useRef([])

  useEffect(() => {
    setUserEmail(authContextData.email)
  }, [authContextData.email])

  // log the hashed user email as the user id 
  useEffect(() => {
    const computeUserId = async () => {
      const sha256Digest = await computeDigest(userEmail, USER_ID_HASH, 'SHA-256')
      setUserId(sha256Digest)
      setWaitingForUserId(false)
    }

    computeUserId()
    setWaitingForUserId(true)
  }, [])

  // object to use as base configuration for dataLyer push events
  const baseConfig = {
    event: 'analyticsEvent',
    eventNonInt: false
  }

  /**
   * Logs an error (4xx, 5xx, ..) to the GTM instance
   * @param {number} code Status code of the error
   */
  const pushError = (code) => {
    if (typeof code !== 'number') {
      throw new Error('The error code must be a valid HTTP status code number.')
    }

    dataLayer.push({
      errorType: code
    })
  }

  /**
   * Build the noindex configuration hint
   */
  const getNoindexEventObject = () => {
    const robotsMeta = document.querySelector('meta[name="robots"]')
    const robotsDirectives = robotsMeta?.getAttribute('content')
    if (robotsDirectives?.includes('noindex') && robotsDirectives?.includes('nofollow')) {
      return { robots: 'noindex' }
    }

    return undefined
  }

  /**
   * Push the event of navigating on a page to the GTM instance
   * @param {object} pageAttributes Object containing the attributes of the page where the user just landed
   */
  const pushPageLanding = (pageAttributes) => {
    const pushToDataLayerFunc = async () => {
      // try to automatically retrieve all the possible values
      const pushObject = {
        country: lang,
        loggedStatus: !!(userEmail?.length),
        userId: '', // this will mean that the userId must be set before sending the event
        ...pageAttributes
      }

      // build the object for the noindex configuration
      const noindexObj = getNoindexEventObject()

      if (waitingForUserId) {
        // if a userId is not yet available, put the current object in a queue
        // which will be emptied when the user id becomes available
        dataLayerEventsQueue.current.push(pushObject)
        if (noindexObj) {
          // push the noindex obj too if needed
          dataLayerEventsQueue.current.push(noindexObj)
        }
      } else {
        // the userId is already available, store it in the push object
        // and send it to the GTM instance
        pushObject.userId = userId
        dataLayer.push(pushObject)
        if (noindexObj) {
          dataLayer.push(noindexObj)
        }

        console.debug({ pushObject })
      }
    }

    pushToDataLayerFunc().catch(console.error)
  }

  useEffect(() => {
    // the userId is now available, push all the objects in the queue
    if (!waitingForUserId) {
      dataLayerEventsQueue.current.forEach(ev => {
        const pushObject = ev
        if ('userId' in pushObject) {
          // only set the user id if the original object contained
          // a property with that name
          pushObject.userId = userId
        }

        dataLayer.push(pushObject)
        console.debug({ pushObject })
      })

      dataLayerEventsQueue.current = []
    }
  }, [waitingForUserId])

  return (
    <GtmContext.Provider value={{ userId, baseConfig, pushError, pushPageLanding }}>
      {children}
    </GtmContext.Provider>
  )
}

export { GtmContext }
export default GtmProvider