import gsap, { Power2 } from "gsap"

const duration = 0.6
const delay = 0

const showPanel = (roomExplorerPanel) => {
    const tl = gsap.timeline({ defaults: { duration, delay, ease: Power2.easeOut }})
    
    tl.to(roomExplorerPanel, {y: '0'})
    
    return tl
}

const shrinkVideo = (videoWrapper) => {
    const tl = gsap.timeline({ defaults: { duration, delay, ease: Power2.easeOut }})

    tl.set(videoWrapper, {
        top: `0`,
        width: '100%',
        left: 'auto',
        transform: 'none'
    })
    .to(videoWrapper, { height: '63vh'})
    

    return tl
}

export const roomReveal = (videoWrapper, roomExplorerPanel) => {
    const tl = gsap.timeline()
    

    tl.add(showPanel(roomExplorerPanel), 0)
    tl.add(shrinkVideo(videoWrapper), 0)

    return tl
}