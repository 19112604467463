import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { LinkLabel } from '../Typography'
import TrackedLink from './TrackedLink'

const CustomLink = ({ url, label, icon = 'arrow-small', iconLeft, ...props }) => {
    return (
      <TrackedLink to={url || "#"} {...props}>
        {icon && iconLeft && <Icon name={icon}/>}
        {label && (
          <LinkLabel {...props}>{label}</LinkLabel>
        )}
        {icon && !iconLeft && <Icon name={icon}/>}
      </TrackedLink>
    )
}

CustomLink.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
}

export default CustomLink
