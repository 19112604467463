import React, { useState, useEffect, useRef, useContext } from 'react'
import {
  StyledCatalogDetailsModal,
  StyledCatalogDetailsModalBackdrop,
  StyledCatalogDetailsModalContent,
  StyledCatalogDetailsModalClose,
  StyledCatalogDetailsModalSlider,
  StyledCatalogDetailsModalSliderSlide,
  StyledCatalogDetailsModalSliderImage,
  StyledCatalogDetailsModalSliderCommands,
  StyledCatalogDetailsModalSliderButtonPrev,
  StyledCatalogDetailsModalSliderButtonNext,
  StyledCatalogDetailsModalSliderPagination,
  StyledCatalogDetailsModalSingleImage,
  StyledCatalogDetailsModalAccessoryCode

} from './styled.js'
import { Icon, Button, Spinner } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout/index.js'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useParams } from 'react-router-dom'
import { getCatalogDetailsData } from '../../api'
import { AuthContext } from '../../contexts/Auth.jsx'
import { DataLayerContext } from '../../contexts/DataLayer'

const CatalogDetailsModal = ({ isVisible, id, setProductDetails, type = 'product', link }) => {
  const [ data, setData ] = useState(false)
  const [ currentSlideIndex, setCurrentSlideIndex ] = useState(1)
  const [ swiper, setSwiper ] = useState()
  const swiperRef = useRef(null)
  const { lang } = useParams()
  const { t } = useTranslation()
  const { authToken } = useContext(AuthContext)
  const { pushToDataLayer } = useContext(DataLayerContext)

  useEffect(() => {
    if (!id) return
    if (isVisible) {
      getCatalogDetailsData(lang, authToken, id, type)
        .then(res => {
          setData(res)
        })  
    }
  }, [id])

  const handleClose = () => {
    setProductDetails({isVisible: false, id, type, link})
    setTimeout(() => {
      if (type !== 'accessory') {
        setCurrentSlideIndex(1)
        swiper.slideTo(0)
      }
    }, 400)
  }

  const renderProductContent = () => {
    let linkTo = `${process.env.CATALOG_URL}${lang}/products/${data.data.attributes.id}`

    if (link && link.type === 'external') {
      linkTo = link.url
    } else if (link && link.type === 'none') {
      linkTo = false
    }

    return (
      <>
        <StyledCatalogDetailsModalSlider>
          <Swiper
            ref={swiperRef}
            onSlideChange={(slide) => setCurrentSlideIndex(slide.activeIndex + 1)}
            onSwiper={(swiper) => {
              setSwiper(swiper)
            }}
          >
            {data.data.attributes.gallery.map((image, index) => {

              return (
                <SwiperSlide key={index}>
                  <StyledCatalogDetailsModalSliderSlide>
                    <StyledCatalogDetailsModalSliderImage src={`${process.env.CDN_URL}${lang}/${image}`} />
                  </StyledCatalogDetailsModalSliderSlide>
                </SwiperSlide>
              )
            })}
          </Swiper>
          {data.data.attributes.gallery.length > 1 ? (
            <StyledCatalogDetailsModalSliderCommands>
              <StyledCatalogDetailsModalSliderButtonPrev
                onClick={() => swiperRef.current.swiper.slidePrev()}
                isDisabled={currentSlideIndex === 1}
              >
                <Icon name="arrow-small" />
              </StyledCatalogDetailsModalSliderButtonPrev>
              <StyledCatalogDetailsModalSliderPagination>
                <Heading as={'h6'} gray4 bold>
                  { Math.floor(data.data.attributes.gallery.length / 10)}{currentSlideIndex} / {Math.floor(data.data.attributes.gallery.length / 10)}{data.data.attributes.gallery.length }
                </Heading>
              </StyledCatalogDetailsModalSliderPagination>
              <StyledCatalogDetailsModalSliderButtonNext
                onClick={() => swiperRef.current.swiper.slideNext()}
                isDisabled={currentSlideIndex === data.data.attributes.gallery.length}
              >
                <Icon name="arrow-small" />
              </StyledCatalogDetailsModalSliderButtonNext>
            </StyledCatalogDetailsModalSliderCommands>
          ) : null}
        </StyledCatalogDetailsModalSlider>
        <Heading as={'h6'} center bold tertiary >
          { data.data.attributes.subtitle }
        </Heading>
        <Spacer xs={1} />
        <Heading as={'h3'} center bold black>
          { data.data.attributes.title }
        </Heading>
        <Spacer xs={1} />
        <Paragraph regular center>
          { data.data.attributes.description }
        </Paragraph>
        <Spacer xs={4} />
        {linkTo ? (
          <Button 
            label={t('scopri di più')} 
            small 
            href={linkTo} 
            target="_blank"
            handleClick={() => {
              pushToDataLayer?.({
                eventLabel: `${data?.data.attributes.title} - ${data?.data.id} - gotocatalog`,
                productName: data?.data.attributes.title,
                productId: data?.data.id,
              })
            }}
          />
        ) : null}
      </>
    )
  }

  const renderAccessoryContent = () => {
    let linkTo = `${process.env.CATALOG_URL}${lang}/accessories/${data.data.attributes.id}`

    if (link && link.type === 'external') {
      linkTo = link.url
    } else if (link && link.type === 'none') {
      linkTo = false
    }

    return (
      <>
        <StyledCatalogDetailsModalSingleImage src={`${process.env.CDN_URL}${lang}/${data.data.attributes.gallery[0]}`} />
        <Heading as={'h6'} center bold tertiary uppercase>
          { t('accessorio') }
        </Heading>
        <Spacer xs={1} />
        <Heading as={'h3'} center bold black>
          { data.data.attributes.title }
        </Heading>
        <Spacer xs={3} />
        <StyledCatalogDetailsModalAccessoryCode>
          <span>
            { t('codice') } 
          </span>
          { data.data.attributes.item_code }
        </StyledCatalogDetailsModalAccessoryCode>
        <Spacer xs={4} />
        {linkTo ? (
          <Button 
            label={t('scopri di più')} 
            small 
            href={linkTo}
            target="_blank"
          />
        ) : null}
      </>
    )
  }

  return (
    <StyledCatalogDetailsModal isVisible={isVisible}>
      <StyledCatalogDetailsModalBackdrop onClick={() => handleClose()} />
      { data ? (
        <StyledCatalogDetailsModalContent>
          <StyledCatalogDetailsModalClose onClick={() => handleClose()}>
            <Icon name={'close'} />
          </StyledCatalogDetailsModalClose>
          {type === 'product' ? renderProductContent() : null}
          {type === 'accessory' ? renderAccessoryContent() : null}
        </StyledCatalogDetailsModalContent>
      ) : <Spinner />}
    </StyledCatalogDetailsModal>
  )
}

export default CatalogDetailsModal
