import React from 'react'

export default () => (
  <svg viewBox="0 0 16 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.6562 5.58292V0.47619H9.7446V6.05911C9.7446 6.32211 9.94866 6.5353 10.2004 6.5353H15.5442V5.58292H10.6562Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0.47619C0 0.213198 0.204067 0 0.455796 0H10.2004C10.3213 0 10.4372 0.0501699 10.5227 0.139473L15.8665 5.7224C15.952 5.8117 16 5.93282 16 6.05911V19.5238C16 19.7868 15.7959 20 15.5442 20H0.455796C0.204067 20 0 19.7868 0 19.5238V0.47619ZM0.911591 0.952381V19.0476H15.0884V6.25636L10.0116 0.952381H0.911591Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.45776 15.5829C3.45776 15.3199 3.66183 15.1067 3.91356 15.1067H12.0864C12.3382 15.1067 12.5422 15.3199 12.5422 15.5829C12.5422 15.8459 12.3382 16.0591 12.0864 16.0591H3.91356C3.66183 16.0591 3.45776 15.8459 3.45776 15.5829Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.45776 12.2989C3.45776 12.0359 3.66183 11.8227 3.91356 11.8227H12.0864C12.3382 11.8227 12.5422 12.0359 12.5422 12.2989C12.5422 12.5618 12.3382 12.775 12.0864 12.775H3.91356C3.66183 12.775 3.45776 12.5618 3.45776 12.2989Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.45776 9.01478C3.45776 8.75179 3.66183 8.53859 3.91356 8.53859H12.0864C12.3382 8.53859 12.5422 8.75179 12.5422 9.01478C12.5422 9.27777 12.3382 9.49097 12.0864 9.49097H3.91356C3.66183 9.49097 3.45776 9.27777 3.45776 9.01478Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.45776 5.73071C3.45776 5.46771 3.66183 5.25452 3.91356 5.25452H7.37132C7.62305 5.25452 7.82711 5.46771 7.82711 5.73071C7.82711 5.9937 7.62305 6.2069 7.37132 6.2069H3.91356C3.66183 6.2069 3.45776 5.9937 3.45776 5.73071Z" fill="currentColor"/>
  </svg>
)
