import styled, { css, ThemeContext } from 'styled-components/macro'

export const StyledCatalogDetailsModal = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 20% 0;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 50;
    opacity: 0;
    visibility: hidden;
    /* transition: all ${({ theme }) => theme.defaults.baseTransition}; */
    transition: all .2s ease-out .2s;

    ${({ theme }) => theme.mediaQuery.desktop(`
      align-items: center;
      padding: 0;
    `)}

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        visibility: visible;
    `}
`

export const StyledCatalogDetailsModalBackdrop = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: .8;
`   

export const StyledCatalogDetailsModalContent = styled.div`
    width: ${({ theme }) => theme.rem(342)};
    padding: ${({ theme }) => theme.rem(0, 16, 32, 16)};
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    z-index: 10;
    border-radius: ${({ theme }) => theme.rem(4)};
    text-align: center;

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(480)};
        padding: ${theme.rem(0, 32, 48, 32)};
    `)}
`

export const StyledCatalogDetailsModalClose = styled.div`
    position: absolute;
    right: 0;
    top: ${({ theme }) => theme.rem(-40)};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    transition: color ${({ theme }) => theme.defaults.baseTransition};

    &:hover {
      color: ${({ theme }) => theme.colors.primary.default};
    }

    i {
        font-size: ${({ theme }) => theme.rem(24)};
    }
`

export const StyledCatalogDetailsModalSlider = styled.div`
    min-height: ${({ theme }) => theme.rem(262)};
    position: relative;

    ${({ theme }) => theme.mediaQuery.desktop(`
        min-height: ${theme.rem(300)};
    `)}
`

export const StyledCatalogDetailsModalSliderSlide = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.rem(24, 0)};
    aspect-ratio: 16 / 9;
    background-image: url('/assets/images/radial-bg.jpg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(30, 0)};
    `)}
`

export const StyledCatalogDetailsModalSliderImage = styled.img`
    height: 100%;
`

export const StyledCatalogDetailsModalSliderCommands = styled.div`
  width: ${({ theme }) => theme.rem(164)};
  position: absolute;
  bottom: ${({ theme }) => theme.rem(40)};
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.desktop(`
    bottom: ${theme.rem(20)};
  `)}
`

export const StyledCatalogDetailsModalSliderPagination = styled.div`
`

export const StyledCatalogDetailsModalSliderButton = styled.div`
  width: ${({ theme }) => theme.rem(32)};
  height: ${({ theme }) => theme.rem(24)};
  border-radius: ${({ theme }) => theme.rem(24)};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.commons.boxShadow};
  color: ${({ theme }) => theme.colors.primary.default};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const StyledCatalogDetailsModalSliderButtonPrev = styled(StyledCatalogDetailsModalSliderButton)`
  ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}

  i {
    transform: scaleX(-1);
  }
`

export const StyledCatalogDetailsModalSliderButtonNext = styled(StyledCatalogDetailsModalSliderButton)`
  ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}
`

export const StyledCatalogDetailsModalSingleImage = styled.img`
  width: 100%;
  height: ${({ theme }) => theme.rem(262)};
  padding: ${({ theme }) => theme.rem(24, 0)};
  margin-bottom: ${({ theme }) => theme.rem(32)};
  aspect-ratio: 16 / 9;
  object-fit: contain;
  object-position: center;

  ${({ theme }) => theme.mediaQuery.desktop(`
      padding: ${theme.rem(30, 0)};
  `)}
`

export const StyledCatalogDetailsModalAccessoryCode = styled.div`
  height: ${({ theme }) => theme.rem(48)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.gray2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
  ${({ theme }) => theme.typo('paragraphRegular')}

  span {
    margin-right: ${({ theme }) => theme.rem(8)};
    font-weight: bold;
  }

  ${({ theme }) => theme.mediaQuery.desktop(`
      max-width: ${theme.rem(300)};
      margin: 0 auto;
  `)}
`