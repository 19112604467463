import styled, { css, ThemeProvider } from 'styled-components/macro'
import Button from '../Button'
import { Paragraph } from '../Typography'

export const StyledSystemExplorerModal = styled.div`
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${({ theme }) => theme.rem(150)};
        background-color: ${({ theme }) => theme.colors.gray6};
        z-index: -1;
        border-radius: ${({ theme }) => theme.rem(4, 4, 0, 0)};
    }

    ${({ theme }) => theme.mediaQuery.desktop(`
        &:after {
            height: ${theme.rem(176)};
        }
    `)}

`

export const StyledSystemExplorerModalHeader = styled.div`
    padding: ${({ theme }) => theme.rem(24, 30)};
    text-align: center;

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(32, 82, 24)};
    `)}
`

export const StyledSystemExplorerModalSlider = styled.div`
    padding: ${({ theme }) => theme.rem(0, 16)};
    margin-bottom: ${({ theme }) => theme.rem(24)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(0, 63)};
        margin-bottom: ${theme.rem(24)};
    `)};

    .swiper {
        width: 100%;
    }

    .swiper-slide {
        transition: all ${({ theme }) => theme.defaults.baseTransition};
        transform: scale(0.8);
        opacity: .6;
        box-shadow: none;
    }

    .swiper-slide-active {
        transform: scale(1);
        opacity: 1;
        box-shadow: ${({ theme }) => theme.commons.boxShadow};
    }
`

export const StyledSystemExplorerModalSliderSlide = styled.div`
    width: ${({ theme }) => theme.rem(122)};
    aspect-ratio: 16 / 9;
    color: ${({ theme }) => theme.colors.white };
    position: relative;

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 100%;
    `)}
    
    ${Paragraph} {
        width: 100%;
        padding: ${({ theme }) => theme.rem(0, 4)};
        position: absolute;
        left: 0;
        bottom: ${({ theme }) => theme.rem(4)};
        z-index: 11;
        text-transform: capitalize;
        text-align: center;
    }
`

export const StyledSystemExplorerModalSliderImage = styled.img`
    object-fit: cover;
    border-radius: ${({ theme }) => theme.rem(4)};
`

export const StyledSystemExplorerModalSliderCommands = styled.div`
    padding: ${({ theme }) => theme.rem(0, 24)};
    margin-bottom: ${({ theme }) => theme.rem(20)};
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(0, 40)};
        margin-bottom: ${theme.rem(24)};
    `)}

    ${({ center }) => center && css`justify-content: center;`}
`

export const StyledSystemExplorerModalSliderPagination = styled.div`
    text-align: center;
`

export const StyledSystemExplorerModalSliderButton = styled.div`
    width: ${({ theme }) => theme.rem(32)};
    height: ${({ theme }) => theme.rem(24)};
    border-radius: ${({ theme }) => theme.rem(24)};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.commons.boxShadow};
    color: ${({ theme }) => theme.colors.primary.default};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const StyledSystemExplorerModalSliderButtonPrev = styled(StyledSystemExplorerModalSliderButton)`
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}

    i {
    transform: scaleX(-1);
    }
`

export const StyledSystemExplorerModalSliderButtonNext = styled(StyledSystemExplorerModalSliderButton)`
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}
`

export const StyledSystemExplorerModalList = styled.ul`
    padding: ${({ theme }) => theme.rem(0, 24)};
    min-height: ${({ theme }) => theme.rem(120)};
    max-height: calc(100vh - ${({ theme }) => theme.rem(
        88 + // system explorer modal footer
        132 + // system explorer modal header
        80 + // system explorer modal slider
        47 + // system explorer modal slider commands
        48 + // modal close button
        100 // bonus
    )});
    overflow: scroll;

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(0, 40)};
    `)}
`

export const StyledSystemExplorerModalListItem = styled.li`
    min-height: ${({ theme }) => theme.rem(38)};
    padding: ${({ theme }) => theme.rem(8, 0)};
    display: flex;
    align-items: center;
    border-top: 1px solid ${({ theme }) => theme.colors.gray2};
    border-bottom: transparent;
    position: relative;

    &:last-child {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    ${Paragraph} {
        &:nth-child(2) {
            padding-right: ${({ theme }) => theme.rem(100)};
        }

        &:last-child {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
`

export const StyledSystemExplorerModalListItemImage = styled.img`
    width: ${({ theme }) => theme.rem(24)};
    height: ${({ theme }) => theme.rem(24)};
    margin-right: ${({ theme }) => theme.rem(8)};
    object-fit: contain;
`

export const StyledSystemExplorerModalFooter = styled.div`
    padding: ${({ theme }) => theme.rem(16, 42)};
    box-shadow: ${({ theme }) => theme.commons.boxShadow};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(24, 84)};
    `)}

    button {
        width: 100%;
    }
`