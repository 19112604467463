import React from 'react'
import Icon from '../Icon'
import { Paragraph } from '../Typography'
import {
  StyledMenuDropdownItem,
  StyledMenuDropdownItemLink
} from './styled'
import { FlexContainer } from '../Layout'
import { useTranslation } from 'react-i18next'

const MenuDropdownItem = (props) => {
  const { label, url, icon, target, onLinkClick, skipTranslation, ...itemProps } = props
  const { t } = useTranslation()

  const handleClick = (e) => {
    if (!url) e.preventDefault()
    if (typeof onLinkClick === 'function') onLinkClick()
  }

  return (
    <StyledMenuDropdownItem {...itemProps}>
      <StyledMenuDropdownItemLink href={url} target={target} onClick={handleClick}>
        <FlexContainer>
          {
            icon && <Icon name={icon} />
          }
          <Paragraph>{ skipTranslation ? label : t(label.toLowerCase()) }</Paragraph>
        </FlexContainer>
        {
          props.active && <Icon name="check" />
        }
      </StyledMenuDropdownItemLink>
    </StyledMenuDropdownItem>
  )
}

export default MenuDropdownItem
