import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'

export const StyledLink = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-bottom: 2px;
  transition: color .2s ease-in 0s;
  cursor: pointer;

  color: ${styledMap`
    primary: ${({ theme }) => theme.colors.primary.default};
    default: inherit;
  `};
  font-size: ${styledMap`
    small: 12px;
    regular: 14px;
    large: 16px;
  `};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.dark};
  }

  svg {
    width: ${styledMap`
      large: 2em;
      default: 1em;
    `};
  }

  i {
    margin-left: ${({ theme }) => theme.rem(8)};
  }

  ${({ theme, iconLeft }) => iconLeft && css`
    margin-left: 0;
    margin-right: ${theme.rem(8)};
  `}
`