import styled, { css } from 'styled-components/macro'
import { Paragraph } from '../Typography'

export const StyledIntro = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 1;
    visibility: visible;
    transition: all ${({ theme }) => theme.defaults.baseTransition};

    ${({ hide }) => hide && css`
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    `}
`

export const StyledIntroContent = styled.div`
    width: 85%;
    text-align: center;
    color: ${({ theme }) => theme.colors.white};

    i {
        font-size: ${({ theme }) => theme.rem(36)};
    }

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 75%;
        ${Paragraph} {
            width: ${theme.rem(500)};
            margin: 0 auto;
        }

        h1 {
            font-size: ${theme.rem(80)};
        }

        i {
            font-size: ${theme.rem(66)};
        }
    `)}

    button {
        width: ${({ theme }) => theme.rem(250)};
    }
`