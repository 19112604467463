import React from 'react'
import { 
  StyledRecapModal,
  StyledRecapModalBackdrop,
  StyledRecapModalWrapper,
  StyledRecapModalImage,
  StyledRecapModalContent,
  StyledRecapModalActions
 } from './styled.js'
import { Button } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const RecapModal = (
    { 
      image, 
      system,
      explore, 
      openSystemModal, 
      openSystemExplorerModal,
      systemModalOpen,
      systemExplorerModalOpen,
      show
    }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { lang, neighborhood, house } = useParams()
  const navigateUrl = explore === 'house' ? `/${lang}/${neighborhood}/${house}` : `/${lang}/${neighborhood}`

  return (
    <StyledRecapModal hide={systemModalOpen || systemExplorerModalOpen} show={show}>
      <StyledRecapModalBackdrop />
      <StyledRecapModalWrapper>
        <StyledRecapModalImage src={image} />
        <StyledRecapModalContent>
          <Heading as={'h5'} bold uppercase>
            { t('grazie per aver visitato il sistema') }
          </Heading>
          <Spacer xs={1} />
          <Paragraph large bold>
            {system}
          </Paragraph>
          <Spacer xs={1} />
          <Paragraph small>
            { t('continua ad esplorare le soluzioni impiantistiche Ariston o ricomincia da capo questo tour') }.
          </Paragraph>
        </StyledRecapModalContent>
        <StyledRecapModalActions>
          <Button 
            small 
            secondary 
            icon={'map'} 
            label={t('rivedi questo sistema')} 
            handleClick={() => openSystemModal()}
          />
          <Spacer xs={1} />
          <Button 
            small 
            secondary 
            icon={'pin'} 
            label={t('rivedi gli ambienti')} 
            handleClick={() => openSystemExplorerModal()}
          />
          <Spacer xs={4} />
          <Button label={t('esplora altri sistemi')} small handleClick={() => navigate(navigateUrl)} />
        </StyledRecapModalActions>
      </StyledRecapModalWrapper>
    </StyledRecapModal>
  )
}

export default RecapModal
