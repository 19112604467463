import React, { useState, useRef, useContext } from 'react'
import {
	StyledApartmentSelector,
	StyledApartmentSelectorSliderWrapper,
	StyledApartmentSelectorInfopointWrapper,
	StyledApartmentSelectorSlide,
	StyledApartmentSelectorSlideButton,
	StyledApartmentSelectorSlideList,
	StyledApartmentSelectorSlideListItem,
	StyledApartmentSelectorSliderSlides,
	StyledApartmentSelectorRevealImage,
	StyledApartmentSelectorSliderContent,
	StyledApartmentSelectorSmallImage,
	StyledApartmentSelectorSliderCommands,
	StyledApartmentSelectorSliderButtonPrev,
	StyledApartmentSelectorSliderButtonNext,
} from './styled.js'
import { Infopoint, Button, Icon, SystemModal, Modal } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataLayerContext } from '../../contexts/DataLayer.jsx'

const ApartmentSelector = ({
	wrapperRef,
	sliderRef,
	infopointsRef,
	data,
	rewindAnimations,
	setSelectedApartment,
	setSystemModalOpen,
}) => {
	const [currentSlideIndex, setCurrentSlideIndex] = useState(1)
	const [swiper, setSwiper] = useState()
	const swiperRef = useRef(null)
	const infopoints = data && data.apartments.map((item) => item.infopoint)
	const revealImages = data && data.apartments.map((item) => item.revealImage)
	const baseImage = data && data.baseImage
	const { t } = useTranslation()
	const { checkBreakpoint } = useContext(ThemeContext)
	const navigate = useNavigate()
	const { pushToDataLayer } = useContext(DataLayerContext)
	const isMobile = window.innerWidth < 768

	const pagination = {
		clickable: true,
		renderBullet: (index, className) => {
			return '<span class="' + className + '"></span>'
		},
	}

	const discoverButtonLabel = t('scopri il sistema')
	const startTourButtonLabel = t('inizia tour')

	const handleSlideButtonClick = (label, apartment, fn) => {
		pushToDataLayer({
			eventAction: apartment.title,
			eventLabel: label,
			placeName: apartment.title,
			action: label,
		})

		fn?.()
	}

	return (
		<StyledApartmentSelector ref={wrapperRef}>
			<StyledApartmentSelectorRevealImage src={baseImage} />
			<StyledApartmentSelectorInfopointWrapper ref={infopointsRef}>
				{revealImages &&
					revealImages.map((image, index) => {
						const index1 = index + 1
						return (
							<StyledApartmentSelectorRevealImage src={image} alt='' key={index} show={index1 === currentSlideIndex} />
						)
					})}

				{infopoints && infopoints.length > 1 && infopoints.map((infopoint, index) => {
					return (
						<Infopoint
							{...infopoint}
							key={index}
							handleClick={() => {
								const index1 = index + 1
								setCurrentSlideIndex(index1)
								setSelectedApartment(index)
								swiper.slideTo(index)
							}}
							showDetails={index + 1 === currentSlideIndex}
						/>
					)
				})}
			</StyledApartmentSelectorInfopointWrapper>
			<StyledApartmentSelectorSliderWrapper>
				<StyledApartmentSelectorSliderContent ref={sliderRef}>
					<Responsive on={checkBreakpoint.not.desktop}>
						{data && data.apartments.length ? (
							<StyledApartmentSelectorSliderSlides>
								<Paragraph small>
									{currentSlideIndex} / {data.apartments.length}
								</Paragraph>
							</StyledApartmentSelectorSliderSlides>
						) : null}
					</Responsive>
					<Responsive on={checkBreakpoint.desktop}>
						{data && data.apartments.length > 1 ? (
							<StyledApartmentSelectorSliderCommands>
								<StyledApartmentSelectorSliderButtonPrev
									onClick={() => swiperRef.current.swiper.slidePrev()}
									isDisabled={currentSlideIndex === 1}
								>
									<Icon name='arrow-small' />
								</StyledApartmentSelectorSliderButtonPrev>
								<StyledApartmentSelectorSliderButtonNext
									onClick={() => swiperRef.current.swiper.slideNext()}
									isDisabled={currentSlideIndex === data.apartments.length}
								>
									<Icon name='arrow-small' />
								</StyledApartmentSelectorSliderButtonNext>
							</StyledApartmentSelectorSliderCommands>
						) : null}
					</Responsive>

					<Swiper
						onSlideChange={(slide) => {
							setCurrentSlideIndex(slide.activeIndex + 1)
							setSelectedApartment(slide.activeIndex)
						}}
						ref={swiperRef}
						// modules={[Pagination]}
						// pagination={pagination}
						onSwiper={(swiper) => {
							setSwiper(swiper)
						}}
					>
						{data &&
							data.apartments.map((apartment, index) => {
								return (
									<SwiperSlide key={index}>
										<StyledApartmentSelectorSlide>
											<Responsive on={checkBreakpoint.desktop}>
												{data && data.apartments ? (
													<StyledApartmentSelectorSmallImage src={apartment.smallImage} />
												) : null}
												{data && data.apartments.length > 1 ? (
													<StyledApartmentSelectorSliderSlides>
														<Paragraph small>
															{currentSlideIndex} / {data && data.apartments.length}
														</Paragraph>
													</StyledApartmentSelectorSliderSlides>
												) : null}
											</Responsive>
											<Heading as='h4' bold center>
												{apartment.title}
											</Heading>
											<Spacer xs={1} />
											<Paragraph small bold center>
												{apartment.text}
											</Paragraph>
											<Spacer xs={1} />
											<StyledApartmentSelectorSlideButton center>
												<Button
													tertiary
													label={discoverButtonLabel}
													icon={'map'}
													handleClick={() =>
														handleSlideButtonClick(discoverButtonLabel, apartment, () => setSystemModalOpen())
													}
												/>
											</StyledApartmentSelectorSlideButton>
											<StyledApartmentSelectorSlideList>
												{apartment.charateristics.map((item, index) => {
													return (
														<StyledApartmentSelectorSlideListItem key={index}>
															<div>
																<Icon name={item.icon.name} />
																<Paragraph small bold>
																	{t(item.label.toLowerCase())}
																</Paragraph>
															</div>
															<div>
																<Paragraph small>{item.value}</Paragraph>
															</div>
														</StyledApartmentSelectorSlideListItem>
													)
												})}
											</StyledApartmentSelectorSlideList>
										</StyledApartmentSelectorSlide>
									</SwiperSlide>
								)
							})}
					</Swiper>
				</StyledApartmentSelectorSliderContent>
				<StyledApartmentSelectorSlideButton bottom>
					<Button
						primary={true}
						label={startTourButtonLabel}
						small={true}
						handleClick={() => {
							const currentApartment = data.apartments[currentSlideIndex - 1]
							handleSlideButtonClick(startTourButtonLabel, currentApartment)
							rewindAnimations()
							if (isMobile) {
								if (data && data.apartments) navigate(currentApartment.link.url)
							} else {
								setTimeout(() => {
									if (data && data.apartments) navigate(currentApartment.link.url)
								}, 2000)
							}

						}}
					/>
				</StyledApartmentSelectorSlideButton>
			</StyledApartmentSelectorSliderWrapper>
		</StyledApartmentSelector>
	)
}

export default ApartmentSelector
