import React, { useState, useContext } from 'react'
import { Link, useParams } from "react-router-dom"
import PropTypes from 'prop-types'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import { StyledHeader, StyledHeaderContainer, StyledHeaderWrapper, StyledHeaderHead, StyledHeaderHeadTitle, StyledHeaderLogo } from './styled'
import { primaryTheme, secondaryTheme } from './config'
import { Container } from '../Layout'
import Menu from '../Menu'
import glyphs from '../Icon/glyphs'
import MenuDropdown from '../MenuDropdown'
import MenuBurger from '../Menu/MenuBurger'
import MenuMobile from '../Menu/MenuMobile'
import Icon from '../Icon'
import { routes } from '../../routes/config'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { AuthContext } from '../../contexts/Auth'
import { getServiceMenuData } from '../../api'
import TrackedLink from '../Link/TrackedLink'

const mapAction = (currentVertical, ssoRoles) => s => ({
  label: s.label,
  url: s.url,
  icon: s.icon,
  target: s.target_blank ? '_blank' : null,
  // compare domain to determine current vertical
  active: (new URL(s.url)).hostname === currentVertical,
  // disable if none of the roles match with the user roles
  disabled: !s.sso_roles.find(r => ssoRoles.includes(r)),
  skipTranslation: true
})

const Header = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { secondary, current = 'current', search, actions: actionsFixtures, appData, lang, disable } = props
  const [actions, setActions] = useState(actionsFixtures)
  const Logo = glyphs['logo']
  const { checkBreakpoint } = useContext(ThemeContext)
  const { authToken, ssoRoles } = useContext(AuthContext)
  const { t } = useTranslation()

  const items = [
    {
      label: t('quartiere ristrutturazione'),
      url: `/${lang}/renew`,
      items: [
        {
          link: { url: `/${lang}/renew/condo` },
          name: t('Condominio')
        },
        {
          link: { url: `/${lang}/renew/bi` },
          name: t('Villa Bifamiliare')
        },
        {
          link: { url: `/${lang}/renew/uni` },
          name: t('Villa Unifamiliare')
        }
      ]
    },
    {
      label: t('quartiere nuovo'),
      url: `/${lang}/new`,
      items: [
        {
          link: { url: `/${lang}/new/condo` },
          name: t('Condominio')
        },
        {
          link: { url: `/${lang}/new/bi` },
          name: t('Villa Bifamiliare')
        },
        {
          link: { url: `/${lang}/new/uni` },
          name: t('Villa Unifamiliare')
        }
      ]
    },
  ]

  useEffect(() => {
    const fetchMenuItems = async () => {
      const {data} = await getServiceMenuData(lang, authToken)

      const currentVertical = (new URL(window.location)).hostname
      
      setActions(
        actions.map(action => {
          if (action.content.isServices) {
            action.content.items = data
              .filter(s => s.sso_roles.find(r => ssoRoles.includes(r)))
              .map(mapAction(currentVertical, ssoRoles))
          }
          return action
        })
      )
    }

    fetchMenuItems()
  }, [])


  const handleMenu = (status) => {
    const menuShouldBeOpen = status ?? !isMenuOpen
    document.body.style.overflow = menuShouldBeOpen ? 'hidden' : 'auto'
    setMenuOpen(menuShouldBeOpen)
  }

  return (
    <ThemeProvider theme={secondary ? secondaryTheme : primaryTheme}>
      <StyledHeader disable={disable}>
        <StyledHeaderContainer>
          <Container>
            <StyledHeaderWrapper>
              <Responsive on={checkBreakpoint.not.desktop}>
                <MenuBurger isOpen={isMenuOpen} onMenuClick={() => handleMenu()}/>
              </Responsive>
              <Responsive on={checkBreakpoint.not.desktop}>
                {isMenuOpen && <MenuMobile onLinkClick={() => handleMenu(false)} { ...props } items={items} actions={actions} />}
              </Responsive>
              <StyledHeaderHead>
                <a href={`//homepage.${process.env.VERTICAL_BASE_URL}/${lang}/${lang}.html`} onClick={() => handleMenu(false)}>
                  <StyledHeaderLogo><Logo /></StyledHeaderLogo>
                </a>
                <TrackedLink to={`/${lang}`} onClick={() => handleMenu(false)}>
                  {/* <Responsive on={checkBreakpoint.tablet}>
                  </Responsive> */}
                    <StyledHeaderHeadTitle as="h5" semiBold>{t(current.toLowerCase())}</StyledHeaderHeadTitle>
                </TrackedLink>
              </StyledHeaderHead>
              <Responsive on={checkBreakpoint.not.desktop}>
                {/* //TODO sound */}
                <div style={{marginLeft: '24px', width: '36px'}} />
              </Responsive>
              <Responsive on={checkBreakpoint.desktop}>
                <Menu secondary={secondary} items={items} />
              </Responsive>
              <Responsive on={checkBreakpoint.desktop}>
                {
                  actions.length && actions.map((action, index) => {
                    if (action.type === 'MenuDropdown') {
                      return <MenuDropdown key={index} {...action}/>
                    }
                  })
                }
              </Responsive>
            </StyledHeaderWrapper>
          </Container>
        </StyledHeaderContainer>
      </StyledHeader>
    </ThemeProvider>
  )
}

Header.propTypes = {
  secondary: PropTypes.bool,
  current: PropTypes.string,
  items: PropTypes.array,
  actions: PropTypes.array,
}

Header.defaultProps = {
  items: [],
  breadcrumbs: []
}

export default Header
