export default [
  {
    icon: {
      mobile: 'tutorial-tap',
      desktop: 'tutorial-tap'
    },
    text: 'Clicca i tag per esplorare quartieri e prodotti Ariston'
  },
  {
    icon: {
      mobile: 'tutorial-tap',
      desktop: 'tutorial-tap'
    },
    text: 'Clicca su una abitazione di interesse per scoprire il sistema'
  },
  {
    icon: {
      mobile: 'tutorial-pinch',
      desktop: 'tutorial-move'
    },
    text: 'Esplora i vari sistemi e i prodotti Ariston che lo compongono'
  },
  {
    icon: {
      mobile: 'tutorial-tap',
      desktop: 'tutorial-infopoint'
    },
    text: 'Naviga velocemente tra i prodotti di un sistema tramite la mappa'
  }
]
