import React, { useState, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  StyledMenuMobile,
  StyledMenuMobileNav,
  StyledMenuMobileCurrent,
  StyledMenuMobileItemsList,
  StyledMenuMobileItem,
  StyledMenuMobilePanel,
  StyledMenuMobilePanelButton,
  StyledMenuMobileAction,
  StyledMenuMobileContainer,
  StyledMenuMobileSubMenuLink,
  StyledMenuMobileLinkLabel,
  StyledMenuMobileSubMenuGridItem,
  StyledMenuMobileGrid,
  StyledMenuMobileDropdownIcon
} from './styled'
import { Paragraph, LinkLabel, ButtonLabel } from '../../Typography'
import { StyledMenuDropdownButton } from '../../MenuDropdown/styled'
import MenuDropdownItem from '../../MenuDropdown/MenuDropdownItem'
import { StyledMenuDropdownItems } from '../../MenuDropdown/styled'
import Icon from '../../Icon'
import { Container, FlexContainer, FlexSpacer, Grid, GridItem } from '../../Layout'
import { StyledSubMenuLink } from '../styled'
import { open, close } from '../../../animations/accordion-submenu'
import { useEffect } from 'react'
import { Spacer } from '../../Layout/index'
import TrackedLink from '../../Link/TrackedLink'

const MenuMobile = ({ current, items, actions, onLinkClick }) => {
  const [isPanelOpen, setPanelOpen] = useState(false)
  const [openedSubmenu, setOpenedSubmenu] = useState(null)  // current name of the open submenu
  const [closingSubmenu, setClosingSubmenu] = useState(null)

  // refs to use to perform the open/close animation of a list of items
  const [
    currentlyOpenSubmenu,
    currentlyOpenSubmenuWrapper
  ] = [useRef(), useRef()]

  useEffect(() => {
    // when the currently open submenu changes, start the open animation
    if (currentlyOpenSubmenu.current && currentlyOpenSubmenuWrapper.current) {
      open({
        content: currentlyOpenSubmenu,
        wrapper: currentlyOpenSubmenuWrapper
      })
    }
  }, [openedSubmenu])

  const openPanel = () => {
    setPanelOpen(true)
  }

  const closePanel = () => {
    setPanelOpen(false)
  }

  const handleItemClick = (targetLabel) => {
    const toggleSubmenus = () => {
      setClosingSubmenu(null)
      setOpenedSubmenu(targetLabel === openedSubmenu ? null : targetLabel)
    }

    if (currentlyOpenSubmenuWrapper.current) {
      // if there is a currently open menu, always close it
      // signal that this submenu is closing to restore the close position of the chevron
      setClosingSubmenu(targetLabel)
      close({
        wrapper: currentlyOpenSubmenuWrapper
      }, toggleSubmenus)

      return
    }

    // always toggle the open menu
    toggleSubmenus()
  }

  const handleClick = (onLinkClick, url) => (e) => {
    if (!url) e.preventDefault()
    if (typeof onLinkClick === 'function') {
      closePanel()
      onLinkClick()
    }
  }

  return (
    <StyledMenuMobile>
      <StyledMenuMobileNav>
        <StyledMenuMobileItemsList>
          {
            current &&
            <StyledMenuMobileCurrent>
              <Icon name="logo-catalog" />
              <Paragraph semibold uppercase>{current}</Paragraph>
            </StyledMenuMobileCurrent>
          }
          {
            items.map((item, i) => (
              item.items && item.items.length > 0 ?
                (
                  <StyledMenuMobileItem key={i}>
                    <TrackedLink to="#" onClick={handleItemClick.bind(this, item.label)}>
                      <FlexContainer>
                        <StyledMenuMobileLinkLabel semiBold>
                          {item.label}
                        </StyledMenuMobileLinkLabel>
                        <StyledMenuMobileDropdownIcon name="chevron-down" open={openedSubmenu === item.label && closingSubmenu !== item.label} />
                      </FlexContainer>
                    </TrackedLink>
                    {openedSubmenu === item.label &&
                      <StyledMenuMobileContainer ref={currentlyOpenSubmenuWrapper}>
                        <StyledMenuMobileGrid ref={currentlyOpenSubmenu}>
                          {item.items.map(it => (
                            <StyledMenuMobileSubMenuGridItem key={it.name}
                              noMargin spaced sm={12}>
                              <StyledMenuMobileSubMenuLink to={it.link.url} onClick={onLinkClick}>
                                <StyledMenuMobileLinkLabel>
                                  {it.name}
                                </StyledMenuMobileLinkLabel>
                              </StyledMenuMobileSubMenuLink>
                            </StyledMenuMobileSubMenuGridItem>
                          ))}
                        </StyledMenuMobileGrid>
                      </StyledMenuMobileContainer>
                    }
                  </StyledMenuMobileItem>
                )
                :
                (
                  <StyledMenuMobileItem key={i}>
                    <TrackedLink to={item.url} onClick={onLinkClick}>
                      <StyledMenuMobileLinkLabel semiBold key={i}>
                        {item.label}
                      </StyledMenuMobileLinkLabel>
                    </TrackedLink>
                  </StyledMenuMobileItem>
                )
            ))
          }
        </StyledMenuMobileItemsList>
      </StyledMenuMobileNav>
      {
        actions.length && actions.map(({ type, label, asButton, content }, i) => {
          const { head, back, items } = content
          if (type === 'MenuDropdown' && !asButton) {
            return (
              <StyledMenuMobileNav key={`MobileNav-${i}`}>
                {
                  head &&
                  <StyledMenuMobileCurrent>
                    {head.icon && <Icon name={head.icon} />}
                    {head.label && <Paragraph semibold uppercase>{head.label}</Paragraph>}
                  </StyledMenuMobileCurrent>
                }
                <ul>
                  {
                    items.map((item, i) => (
                      <StyledMenuMobileItem key={i} disabled={item.disabled}>
                        <TrackedLink to={item.url || '#'} onClick={handleClick(item.onLinkClick, item.url)}>
                          <StyledMenuMobileLinkLabel key={i}>
                            {item.label}
                          </StyledMenuMobileLinkLabel>
                        </TrackedLink>
                      </StyledMenuMobileItem>
                    ))
                  }
                </ul>
              </StyledMenuMobileNav>
            )
          }
          if (type === 'MenuDropdown' && asButton) {
            return (
              <React.Fragment key={`MenuDropdown-${i}`}>
                {i === actions.length - 1 && <FlexSpacer />}
                <StyledMenuMobileAction >
                  <StyledMenuDropdownButton full onClick={openPanel}>
                    <FlexContainer alignItems="center" justifyContent="center">
                      <Icon name="menu-app" />
                      <ButtonLabel semiBold>{label}</ButtonLabel>
                    </FlexContainer>
                    <Icon name="chevron-right" />
                  </StyledMenuDropdownButton>
                  <StyledMenuMobilePanel isVisible={isPanelOpen}>
                    <StyledMenuMobilePanelButton onClick={closePanel}>
                      <Icon name="arrow-small" />
                      <ButtonLabel semiBold>{back.label}</ButtonLabel>
                    </StyledMenuMobilePanelButton>
                    <StyledMenuDropdownItems>
                      <ul>
                        {
                          items.map((item, i) => (
                            <MenuDropdownItem key={i} {...item} onClick={onLinkClick} />
                          ))
                        }
                      </ul>
                    </StyledMenuDropdownItems>
                  </StyledMenuMobilePanel>
                </StyledMenuMobileAction>
              </React.Fragment>
            )
          }
        })
      }
    </StyledMenuMobile>
  )
}

MenuMobile.propTypes = {
  items: PropTypes.array,
  current: PropTypes.string,
  actions: PropTypes.array
}

MenuMobile.defaultProps = {
  items: []
}

export default MenuMobile
