import styled, { css } from 'styled-components/macro'

export const StyledDescriptionBox = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.rem(50, 120, 50, 24)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(360)};
        padding: ${theme.rem(50, 0)};
    `)}
`
