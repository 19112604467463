import styled, { css } from 'styled-components/macro'

export const StyledTutorial = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 20% 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow-y: scroll;
  z-index: 50;
  opacity: 0;
  visibility: hidden;
  transition: all ${({ theme }) => theme.defaults.baseTransition};

  ${({ theme }) => theme.mediaQuery.desktop(`
    align-items: center;
    padding: 0;
  `)}

  ${({ isVisible }) => isVisible && css`
    opacity: 1;
    visibility: visible;
  `}
`

export const StyledTutorialBackdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.black};
  opacity: .8;
`

export const StyledTutorialContent = styled.div`
  width: ${({ theme }) => theme.rem(342)};
  padding: ${({ theme }) => theme.rem(40)};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 10;
  border-radius: ${({ theme }) => theme.rem(4)};

  ${({ theme }) => theme.mediaQuery.desktop(`
    width: ${theme.rem(782)};
  `)}
`

export const StyledTutorialHeading = styled.div`
  width: ${({ theme }) => theme.rem(228)};
  margin: 0 auto;

  ${({ theme }) => theme.mediaQuery.desktop(`
    width: ${theme.rem(330)};
  `)}
`

export const StyledTutorialClose = styled.div`
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.rem(-40)};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;

  i {
    font-size: ${({ theme }) => theme.rem(24)};
  }
`

export const StyledTutorialItems = styled.div`
  ${({ theme }) => theme.mediaQuery.desktop(`
    display: flex;
    flex-wrap: wrap;
  `)}
`

export const StyledTutorialItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(24)};
  }

  i {
    font-size: ${({ theme }) => theme.rem(40)};
    margin-right: ${({ theme }) => theme.rem(16)};
  }

  ${({ theme }) => theme.mediaQuery.desktop(`
    width: 25%;
    flex-direction: column;
    text-align: center;
    padding: 0 ${theme.rem(24)};
    position: relative;

    i {
      font-size: ${theme.rem(64)};
      margin-right: 0;
      margin-bottom: ${theme.rem(18)};
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 0;

      &:before {
        content: '';
        width: 1px;
        height: ${theme.rem(90)};
        position: absolute;
        right: 0;
        top: ${theme.rem(30)};
        background-color: ${theme.colors.gray2};
      }
    }
  `)}
`

export const StyledTutorialActions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;

  button {
    align-self: center;
  }
`
