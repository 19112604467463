import React, { useState, useContext } from 'react'
import { StyledInfopointsList, StyledInfopointsListBackdrop } from './styled'
import Infopoint from './'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import Tooltip from './Tooltip'
import { DataLayerContext } from '../../contexts/DataLayer'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../contexts/Auth'
import { getCatalogDetailsData } from '../../api'

/**
 *
 * @param {object} param0
 * @param {boolean} param0.gtmImmediateSubmit Submit the event to the GTM instance immediately when clicking the infopoint
 * instead of waiting for the click on the tooltip link.
 */
const InfopointsList = ({
  infopoints,
  setExperience,
  experience,
  hide,
  showCatalogDetails,
  showAccessoryDetails,
  gtmImmediateSubmit,
  room = false
}) => {
  const { showTooltip } = experience
  const { checkBreakpoint } = useContext(ThemeContext)
  const { pushToDataLayer } = useContext(DataLayerContext)
  const { lang } = useParams()
  const { authToken } = useContext(AuthContext)

  const defaultClickBehaviour = (index) => {
    if (index === showTooltip) {
      setExperience((prevState) => ({
        ...prevState,
        showTooltip: false,
      }))
    } else {
      setExperience((prevState) => ({
        ...prevState,
        showTooltip: index,
      }))
    }
  }

  return (
    <StyledInfopointsList hide={hide} room={room}>
      <StyledInfopointsListBackdrop
        onClick={() =>
          showTooltip !== false &&
          setExperience((prevState) => ({
            ...prevState,
            showTooltip: false,
          }))
        }
      />
      {infopoints.map((infopoint, index) => {
        return (
          <Infopoint
            {...infopoint}
            key={index}
            handleClick={async () => {
              if (typeof showCatalogDetails === 'function') {
                showCatalogDetails(infopoint.catalogDetails.id, infopoint.catalogDetails.type, infopoint.catalogDetails.link)
                if (gtmImmediateSubmit) {
                  const catalogDetailsData = await getCatalogDetailsData(
                    lang,
                    authToken,
                    infopoint.catalogDetails.id,
                    infopoint.catalogDetails.type
                  )
                  pushToDataLayer({
                    eventLabel: `${catalogDetailsData?.data.attributes.title} - ${catalogDetailsData?.data.id} - open`,
                    productName: catalogDetailsData?.data.attributes.title,
                    productId: catalogDetailsData?.data.id,
                  })
                }
              } else {
                defaultClickBehaviour(index)
              }
            }}
            showDetails={index === showTooltip}
          />
        )
      })}
    </StyledInfopointsList>
  )
}

export default InfopointsList
