import React, { useContext } from 'react'
import { StyledIntro, StyledIntroContent } from './styled.js'
import { Icon, Button } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'
import { useTranslation } from 'react-i18next'
import { DataLayerContext } from '../../contexts/DataLayer'

const Intro = ({ startExperience, setStartExperience, ctaLabel, content }) => {
	const { t } = useTranslation()
	const { pushToDataLayer } = useContext(DataLayerContext)

  // TODO: the submitted text should be in english so we should change localization keys base language
	const buttonLabel = t('inizia a esplorare', { lng: 'en' })
	const handleButtonClick = (fn) => {
		pushToDataLayer({
			eventAction: buttonLabel,
		})

		fn?.()
	}

	return (
		<StyledIntro hide={startExperience}>
			<StyledIntroContent>
				<Icon name={'oneteam-showroom'} />
				<Spacer xs={3} lg={4} />
				<Heading bold uppercase center as={'h1'}>
					{content.title}
				</Heading>
				<Spacer xs={2} />
				<Paragraph center regular>
					{content.text}
				</Paragraph>
				<Spacer xs={5} lg={7} />
				<Button
					tertiary
					small
					label={ctaLabel}
					handleClick={() => handleButtonClick(() => setStartExperience(true))}
				/>
			</StyledIntroContent>
		</StyledIntro>
	)
}

export default Intro
