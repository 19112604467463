import React, { useState, useContext, useRef, useLayoutEffect, useEffect } from "react"
import { Page, TopContainer, ShowTutorial, FloatingContainer, Container, BottomLeftBox } from '../components/Layout'
import { useOutletContext, useParams } from "react-router-dom"
import { DescriptionBox, Scene, Tutorial, Intro, Button } from "../components"
import { ThemeContext } from 'styled-components'
import { Responsive } from '../contexts/Responsive'
import Tooltip from '../components/Infopoint/Tooltip'
import { handleResize, getStepData, checkCookieExists, setCookie } from '../utils'
import InfopointsList from '../components/Infopoint/InfopointsList'

import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import DataLayerProvider, { EVENT_TYPES } from '../contexts/DataLayer'
import { PAGE_TYPES } from "../routes/config"

gsap.registerPlugin(Draggable, InertiaPlugin)

const Homepage = () => {
	const [showTutorial, setShowTutorial] = useState(false)
	const [introPlayed, setIntroPlayed] = useState(checkCookieExists('introPlayed'))
	const [startExperience, setStartExperience] = useState(false)
	const { hide, experience, setExperience, setNewPage, appData, logPageLanding } = useOutletContext()
	const { showTooltip } = experience
	const params = useParams()
	const stepData = getStepData('home', appData, params)
	const { components } = stepData
	const { descriptionBox, infopoints } = components
	const { checkBreakpoint } = useContext(ThemeContext)
	const hasInfopoints = infopoints && infopoints.length
	const floatingContainer = useRef(null)
	const mainRef = useRef(null)
	const isMobile = window.innerWidth < 768

	console.log(appData)

	useEffect(() => {
		logPageLanding?.({
			pageType: PAGE_TYPES.home
		})
	}, [])

	useLayoutEffect(() => {
		if (checkCookieExists('introPlayed')) {
			setExperience(prevState => ({
				...prevState,
				played: true
			}))
		}

		handleResize(floatingContainer)

		if (isMobile) {
			Draggable.create(floatingContainer.current, {
				type: 'x',
				edgeResistance: 1,
				bounds: mainRef.current,
				inertia: true,
			})
		}

		window.addEventListener('resize', () => handleResize(floatingContainer))

		return () => {
			if (isMobile && Draggable.get(floatingContainer.current)) Draggable.get(floatingContainer.current).kill()
			window.removeEventListener('resize', () => handleResize(floatingContainer))
		}
	}, [])

	useLayoutEffect(() => {
		if (introPlayed) {
			if (!checkCookieExists()) {
				setShowTutorial(true)
			}
		}
	}, [introPlayed])

	const addNeighborhoodInfo = (pushObj) => {
		if (pushObj) {
			pushObj.neighborhoodType = pushObj.eventAction
		}
	}

	return (
		<Page ref={mainRef}>
			<FloatingContainer ref={floatingContainer}>
				<Scene
					experience={experience}
					setExperience={setExperience}
					setNewPage={setNewPage}
					data={stepData}
					startExperience={startExperience}
					playedCallback={() => {
						setCookie('introPlayed')
						setIntroPlayed(true)
					}}
					homepage
					introPlayed={introPlayed}
				/>
				{hasInfopoints ? (
					<DataLayerProvider
						for={EVENT_TYPES.home}
						customBaseConfig={{ eventLabel: 'visit' }}
						mergeFn={addNeighborhoodInfo}
					>
						<InfopointsList
							infopoints={infopoints}
							setExperience={setExperience}
							experience={experience}
							hide={hide || !introPlayed}
						/>
					</DataLayerProvider>
				) : null}
			</FloatingContainer>
			<Container hide={hide || !introPlayed}>
				<BottomLeftBox>
					<DescriptionBox {...descriptionBox} as={'h4'} />
				</BottomLeftBox>
				<Responsive on={checkBreakpoint.not.desktop}>
					<Tooltip show={showTooltip !== false} {...(infopoints[showTooltip] ? infopoints[showTooltip].tooltip : {})} />
				</Responsive>
			</Container>
			<TopContainer hide={hide || !introPlayed}>
				<div></div>
                <div>
                    <ShowTutorial>
                        <Button tertiary icon={'tutorial-icon'} handleClick={() => setShowTutorial(true)} small/>
                    </ShowTutorial>
                </div>
			</TopContainer>
			<Tutorial isVisible={showTutorial} setShowTutorial={setShowTutorial} />
			{!introPlayed ? (
				<DataLayerProvider for={EVENT_TYPES.home} customBaseConfig={{ eventLabel: 'visit' }}>
					<Intro 
						startExperience={startExperience} 
						setStartExperience={setStartExperience} 
						ctaLabel={appData.home.introCTALabel}
						content={appData.home.introInfo}
					/>
				</DataLayerProvider>
			) : null}
		</Page>
	)
}

export default Homepage
