import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    font-family: ${({ theme }) => theme.fonts.default};
    font-size: 100%;
    font-variant-ligatures: none;
    text-size-adjust: none;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    min-width: 320px;
    height: 100%;
    overflow-x: hidden;
    margin: 0;
    font-size: ${({ theme }) => theme.rem(16)};
  }

  /* body {
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }
  
  html {
      height: fill-available;
      height: -webkit-fill-available;
  } */

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6, p, ol, ul {
    margin: 0;
    padding: 0;
    font-size: inherit;
  }

  small {
    font-size: ${({ theme }) => theme.rem(12)};
  }

  audio,
  canvas,
  iframe,
  svg,
  video,
  img {
    vertical-align: middle;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ol,
  ul {
    padding: 0;
    list-style: none;
  }

  figure,
  figcaption {
    margin: 0;
  }

  fieldset {
    margin: 0;
    padding: 0;

    border: 0;
  }

  textarea {
    resize: vertical;
  }

  img, svg {
    max-width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color linear 3600s;
    -webkit-text-fill-color: inherit;
    box-shadow: 0 0 0 64rem ${({ theme}) => theme.color('white')} inset;
    -webkit-box-shadow: 0 0 0 64rem ${({ theme}) => theme.color('white')} inset;
  }
`

export default GlobalStyles
