import React, { useState, useRef } from 'react'
import {
  StyledSlider,
} from './styled.js'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'

SwiperCore.use([Pagination])

const Slider = ({children, ...props}) => {

  return (
    <StyledSlider {...props} >
      {children}
    </StyledSlider>
  )
}

export default Slider
