import styled, { css } from 'styled-components/macro'

export const StyledInfopoint = styled.div`
    width: ${({ theme }) => theme.rem(28)};
    height: ${({ theme }) => theme.rem(28)};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primary.default};
    color: ${({ theme }) => theme.colors.white};
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${({ pulse }) => pulse ? '15' : '11'};
    cursor: pointer;
    ${({ x }) => x && css`left: ${x}%;`}
    ${({ y }) => y && css`bottom: ${y}%;`}

    i {
        font-size: ${({ theme }) => theme.rem(12)};
    }

    &:after {
        content: '';
        width: ${({ theme }) => theme.rem(30)};
        height: ${({ theme }) => theme.rem(30)};
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid ${({ theme }) => theme.colors.primary.default};
        border-radius: 50%;
        ${({ pulse }) => pulse && css`animation: pulse infinite 1.2s ease-out;`}
    }

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(40)};
        height: ${theme.rem(40)};
        
        &:hover {
            background-color: ${theme.colors.primary.default};
            color: ${theme.colors.white};
            overflow: visible;

            &:after {
                animation: pulse infinite 1.2s ease-out;
            }
        }

        &:after {
            width: ${theme.rem(42)};
            height: ${theme.rem(42)};
        }

        i {
            font-size: initial;
        }
    `)}

    @keyframes pulse {
        0% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
        100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1.8);
        }
    }
`

export const StyledInfopointTooltip = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.rem(24, 24, 50, 24)};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.rem(10, 10, 0, 0)};
    position: absolute;
    color: ${({ theme }) => theme.colors.black};
    transform: translateY(0%);
    transition: all ${({ theme }) => theme.defaults.fastTransition};
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    text-align: center;
    pointer-events: all;

    ${({ show }) => show && css`
        opacity: 1; 
        visibility: visible;
        transform: translateY(-100%);
    `}

    ${({ theme, show }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(288)};
        padding: ${theme.rem(24)};
        opacity: ${show ? 1 : 0};
        visibility: ${show ? 1 : 0};
        right: ${theme.rem(-300)};
        background-color: rgba(255, 255, 255, .9);
        transform: none;
        border-radius: ${theme.rem(10)};
        text-align: left;
    `)}
`

export const StyledInfopointsList = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    visibility: visible;

    ${({ hide }) => hide && css`visibility: hidden;`};

    ${({ room }) => room && css`width: 200%;`}
`

export const StyledInfopointsListBackdrop = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`