import React, { createContext, useContext, useEffect, useReducer } from 'react'

const PortalContext = createContext()
const PortalProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, {id, out, children, type}) => {
    switch (type) {
      case 'in':
        return {...state, [out]: {...state[out], [id]: children}, count: (state.count || 0) + 1 }
      default:
        return state
    }
  }, {})
  return <PortalContext.Provider value={[state, dispatch]}>
    {children}
    </PortalContext.Provider>
}


export const PortalOut = ({id}) => {
  const [state] = useContext(PortalContext)
  return <>
    {(state && id && Object.values(state[id] || {}))}
  </>
}

export const PortalIn = ({out, id, children, deps = []}) => {
  const [state, dispatch] = useContext(PortalContext)
  useEffect(() => {
    dispatch({type: 'in', id, out, children})
  }, deps)
  return null
}

export default PortalProvider
export { PortalContext }
