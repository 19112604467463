import React, { useState, useContext, useRef, useLayoutEffect, useEffect } from "react"
import { Page, ShowTutorial, TopContainer, FloatingContainer, Container, BottomLeftBox } from '../components/Layout'
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { DescriptionBox, Scene, Icon, Tutorial, Button, Link } from "../components"
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../contexts/Responsive'
import Tooltip from '../components/Infopoint/Tooltip'
import InfopointsList from '../components/Infopoint/InfopointsList'
import { handleResize, getStepData } from '../utils'
import { useTranslation } from "react-i18next"

import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import DataLayerProvider, { EVENT_TYPES } from '../contexts/DataLayer'

gsap.registerPlugin(Draggable, InertiaPlugin)

const Neighborhood = () => {
	const [showTutorial, setShowTutorial] = useState(false)
	const { hide, appData, experience, setExperience, setNewPage, newPage, logPageLanding } = useOutletContext()
	const { showTooltip = false } = experience
	const params = useParams()
	const stepData = getStepData('neighborhood', appData, params)
	const { components } = stepData
	const { descriptionBox, infopoints } = components
	const { checkBreakpoint } = useContext(ThemeContext)
	const hasInfopoints = infopoints && infopoints.length
	const floatingContainer = useRef(null)
	const isMobile = window.innerWidth < 768
	const mainRef = useRef(null)
	const navigate = useNavigate()
	const { lang } = useParams()
	const { t } = useTranslation()

	useLayoutEffect(() => {
		handleResize(floatingContainer)

		if (isMobile) {
			Draggable.create(floatingContainer.current, {
				type: 'x',
				edgeResistance: 1,
				bounds: mainRef.current,
				inertia: true,
			})
		}

		window.addEventListener('resize', () => handleResize(floatingContainer))

		return () => {
			if (isMobile && Draggable.get(floatingContainer.current)) Draggable.get(floatingContainer.current).kill()
			window.removeEventListener('resize', () => handleResize(floatingContainer))
		}
	}, [])

	const addBuildingTypeInfo = (pushObj) => {
		if (!pushObj) {
			return
		}

		const buildingType = pushObj.eventAction
		const eventAction = pushObj.eventLabel

		// swap eventAction and eventLabel
		pushObj.eventAction = eventAction
		pushObj.eventLabel = buildingType
		pushObj.buildingType = buildingType

		console.debug({ pushObj })
	}

	return (
		<Page ref={mainRef}>
			<FloatingContainer ref={floatingContainer}>
				<Scene experience={experience} setExperience={setExperience} setNewPage={setNewPage} data={stepData} />
				{hasInfopoints ? (
					<DataLayerProvider
						for={EVENT_TYPES.neighborhood}
						customBaseConfig={{ eventLabel: params.neighborhood }}
						mergeFn={addBuildingTypeInfo}
					>
						<InfopointsList
							infopoints={infopoints}
							setExperience={setExperience}
							experience={experience}
							hide={newPage}
						/>
					</DataLayerProvider>
				) : null}
			</FloatingContainer>
			<Container hide={hide}>
				<BottomLeftBox>
					<DescriptionBox {...descriptionBox} />
				</BottomLeftBox>
				<Responsive on={checkBreakpoint.not.desktop}>
					<Tooltip
						show={showTooltip !== false}
						{...(infopoints && infopoints[showTooltip] ? infopoints[showTooltip].tooltip : {})}
					/>
				</Responsive>
			</Container>
			<TopContainer hide={hide}>
				<div>
					<Link label={t('torna ai quartieri')} iconLeft icon={'chevron-left'} to={`/${lang}`}/>
				</div>
                <div>
                    <ShowTutorial>
                        <Button tertiary icon={'tutorial-icon'} handleClick={() => setShowTutorial(true)} small/>
                    </ShowTutorial>
                </div>
			</TopContainer>
			<Tutorial isVisible={showTutorial} setShowTutorial={setShowTutorial} />
		</Page>
	)
}

export default Neighborhood
