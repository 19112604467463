import styled from 'styled-components/macro'
import styledMap, { mapToTheme as theme } from 'styled-map'
import { LinkLabel } from '../../components/Typography'
import TrackedLink from '../Link/TrackedLink'

export const StyledMenu = styled.nav`
  display: grid;
  flex: 1 0 auto;
  place-content: center;
`

export const StyledMenuList = styled.ul`
  display: flex;
  align-items: center;
`

export const StyledMenuItemLink = styled(TrackedLink)`
  color: ${styledMap`
    default:  ${({ theme }) => theme.colors.gray4};
  `};

  &.active {
    color: ${({ theme }) => theme.colors.primary.default};
  }

  &:hover {
    color: ${theme('menu.itemColorHover')};
  }
`

export const StyledSubMenu = styled.div`
  padding-top: ${({ theme }) => theme.rem(40)};
  position: fixed;
  left: 0;
  top: ${({ theme }) => theme.rem(82)};
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 20px rgba(144, 144, 144, 0.2);
  opacity: ${({ isVisible }) => isVisible ? '1' : '0'};
  visibility: ${({ isVisible }) => isVisible ? 'visible' : 'hidden'};
  transition: opacity 0.3s, visibility 0.3s;
  z-index: -1;

  ${({ theme }) => theme.mediaQuery.desktop(`
    width: ${theme.rem(212)};
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: ${theme.rem(4)};
    top: ${theme.rem(78)};
  `)}

  span {
    pointer-events: all;
  }
`

export const StyledMenuItem = styled.li`
  padding: ${({ theme }) => `${theme.rem(32)} ${theme.rem(16)}`};

  ${({ theme }) => theme.mediaQuery.desktop(`
    position: relative;
  `)}

  &:hover {
    ${StyledMenuItemLink} {
      color: ${theme('menu.itemColorHover')};
    }
  }
`

export const StyledSubMenuLink = styled(LinkLabel)`
  font-weight: ${({ isActive }) => isActive ? 600 : 400 };
  color: ${({ theme, isActive }) => isActive ? theme.colors.primary.default : theme.colors.grey4};
  &:hover {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary.default};
  }
`
