import arrowSmall from './arrow-small'
import arrowLarge from './arrow-large'
import Logo from './logo'
import menuApp from './menu-app'
import check from './check'
import chevronLeft from './chevron-left'
import chevronRight from './chevron-right'
import LogoCatalog from './logo-catalog'
import LogoHome from './logo-home'
import LogoShowroom from './logo-showroom'
import LogoTraining from './logo-training'
import LogoVideo from './logo-video'
import LogoLoyalty from './logo-loyalty'
import LogoDiscountInvoice from './logo-discount-invoice'
import LogoEventLearningHub from './logo-event-learning-hub'
import LogoFileCadRepoPl from './logo-file-cad-repo-pl'
import LogoLeadManagementPl from './logo-lead-management-pl'
import LogoMediaCenter from './logo-media-center'
import LogoPriceListPl from './logo-price-list-pl'
import LogoSystemConfigurator from './logo-system-configurator'
import LogoSystemPortfolio from './logo-system-portfolio'
import LogoTechnicalDocRepo from './logo-technical-doc-repo'
import search from './search'
import user from './user'
import settings from './settings'
import facebook from './facebook'
import instagram from './instagram'
import youtube from './youtube'
import chevronDownBtn from './chevron-down-btn'
import searchBg from './searchBg'
import chevronDown from './chevron-down'
import download from './download'
import view from './view'
import document from './document'
import plusCircle from './plus-circle'
import close from './close'
import cube from './3d'
import arrowLeft from './arrow-left'
import plus from './plus'
import minus from './minus'
import info from './info'
import filters from './filters'
import tutorial3d from './tutorial-3d'
import tutorialInfopoint from './tutorial-infopoint'
import tutorialMove from './tutorial-move'
import tutorialSwitch from './tutorial-switch'
import tutorialSwipe from './tutorial-swipe'
import tutorialTap from './tutorial-tap'
import tutorialPinch from './tutorial-pinch'
import tutorialIcon from './tutorial-icon'
import swipeRight from './swipe-right'
import map from './map'
import photovoltaic from './photovoltaic'
import typology from './typology'
import pin from './pin'
import exit from './exit'
import oneteamShowroom from './oneteam-showroom'

export default {
  'arrow-small': arrowSmall,
  'arrow-large': arrowLarge,
  'logo': Logo,
  'menu-app': menuApp,
  'check': check,
  'chevron-left': chevronLeft,
  'chevron-right': chevronRight,
  'logo-catalog': LogoCatalog,
  'logo-home': LogoHome,
  'logo-showroom': LogoShowroom,
  'logo-training': LogoTraining,
  'logo-video': LogoVideo,
  'logo-loyalty': LogoLoyalty,
  'logo-discount-invoice': LogoDiscountInvoice,
  'logo-event-learning-hub': LogoEventLearningHub,
  'logo-file-cad-repo-pl': LogoFileCadRepoPl,
  'logo-lead-management-pl': LogoLeadManagementPl,
  'logo-media-center': LogoMediaCenter,
  'logo-price-list-pl': LogoPriceListPl,
  'logo-system-configurator': LogoSystemConfigurator,
  'logo-system-portfolio': LogoSystemPortfolio,
  'logo-technical-doc-repo': LogoTechnicalDocRepo,
  'search': search,
  'user': user,
  'settings': settings,
  'facebook': facebook,
  'instagram': instagram,
  'youtube': youtube,
  'chevron-down-btn': chevronDownBtn,
  'searchBg': searchBg,
  'chevron-down': chevronDown,
  'download': download,
  'view': view,
  'document': document,
  'plus-circle': plusCircle,
  'close': close,
  'cube': cube,
  'arrow-left': arrowLeft,
  'plus': plus,
  'minus': minus,
  'info': info,
  'filters': filters,
  'tutorial-3d': tutorial3d,
  'tutorial-infopoint': tutorialInfopoint,
  'tutorial-move': tutorialMove,
  'tutorial-switch': tutorialSwitch,
  'tutorial-swipe': tutorialSwipe,
  'tutorial-tap': tutorialTap,
  'tutorial-pinch': tutorialPinch,
  'tutorial-icon': tutorialIcon,
  'swipe-right': swipeRight,
  'map': map,
  'photovoltaic': photovoltaic,
  'typology': typology,
  'pin': pin,
  'exit': exit,
  'oneteam-showroom': oneteamShowroom,
}
