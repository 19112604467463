import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'
import { resetButton } from '../../Button/styled'
import { LinkLabel } from '../../Typography'
import { Container, Grid, GridItem } from '../../Layout'
import Icon from '../../Icon'
import TrackedLink from '../../Link/TrackedLink'

export const StyledMenuMobile = styled.div`
  position: fixed;
  z-index: 10;
  top: ${({ theme }) => theme.rem(79)};
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;

  justify-content: start;
  flex-direction: column;

  transition: opacity 0.2s, visibility 0.2s;

  color: ${({ theme }) => theme.colors.tertiary.dark};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 4px rgba(144, 144, 144, 0.2);
`

export const StyledMenuMobileNav = styled.nav`
  padding: ${({ theme }) => theme.rem(40)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
`

export const StyledMenuMobileAction = styled.nav`
  padding: ${({ theme }) => theme.rem(40)} ${({ theme }) => theme.rem(32)};
`

const paddedMenuCss = css`
  padding-left: ${({ theme }) => theme.rem(32)};
  padding-right: ${({ theme }) => theme.rem(32)};
`

export const StyledMenuMobileCurrent = styled.li`
  display: flex;
  padding-top: 0;
  padding-bottom: ${({ theme }) => theme.rem(8)};
  ${paddedMenuCss}

  color: ${({ theme }) => theme.colors.tertiary.dark };
  letter-spacing: 0.53px;
  i {
    margin-right: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledMenuMobileItemsList = styled.ul`
  overflow-x: visible;
`

export const StyledMenuMobileItem = styled.li`
  display: block;
  text-transform: capitalize;
  padding: ${({ theme }) => theme.rem(16)} 0;

  color: ${styledMap`
    disabled: ${({ theme }) => theme.colors.tertiary.dark};
    default: ${({ theme }) => theme.colors.gray5};
  `};
  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    ${LinkLabel} {
      pointer-events: none;
    }
  `}
`

export const StyledMenuMobilePanel = styled.div`
  position: fixed;
  z-index: 2;
  top: ${({ theme }) => theme.rem(83)};
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s;
  transform: translateX(${({ isVisible }) => isVisible ? '0' : '100%'});
  background-color: ${({ theme }) => theme.colors.white };
`

export const StyledMenuMobilePanelButton = styled.div`
  ${resetButton}
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.rem(36)};
  background-color: rgba(144, 182, 189, 0.2);

  i {
    transform: rotate(180deg);
    margin-right: ${({ theme }) => theme.rem(8)};
  }
`

export const StyledMenuMobileContainer = styled(Container)`
  overflow: hidden;
  height: 0;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
`

export const StyledMenuMobileGrid = styled(Grid)`
  padding-top: ${({ theme }) => theme.rem(24)};
`

export const StyledMenuMobileSubMenuGridItem = styled(GridItem)`
  padding-left: 0;
  padding-right: 0;
`

export const StyledMenuMobileSubMenuLink = styled(TrackedLink)`
  color: ${({ theme }) => theme.colors.gray4};
  background-color: ${({ theme }) => theme.colors.gray1};
  // background-color: #f3f3f3;
  padding: ${({ theme }) => theme.rem(theme.grid.gutter.mobile)} 0;
  pointer-events: all;

  width: 100%;
  display: block;
  position: relative;
  font-weight: lighter;

  &.active {
    color: ${({ theme }) => theme.colors.primary.default};
    font-weight: normal;
  }
`

export const StyledMenuMobileLinkLabel = styled(LinkLabel)`
  ${paddedMenuCss}

  padding-right: ${({ theme }) => theme.rem(8)};
  font-weight: ${({ semiBold }) => semiBold ? '600' : 'inherit'};
`

export const StyledMenuMobileDropdownIcon = styled(Icon)`
  transition: transform 0.2s ease;
  transform: rotate(${({ open }) => open ? -180 : 0}deg);
`
