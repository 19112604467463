import gsap from "gsap"

const config = {
  duration: 0.4
}

const open = ({content, wrapper}, handleOnComplete) => {
  const height = content.current.offsetHeight

  gsap.to(wrapper.current, {
    height: `${height}px`,
    duration: config.duration,
    onComplete: () => {
      if (handleOnComplete) {
        handleOnComplete()
      }
    }
  })
}

const close = ({wrapper}, handleOnComplete) => {
  gsap.to(wrapper.current, {
    height: `0px`,
    duration: config.duration,
    onComplete: () => {
      if (handleOnComplete) {
        handleOnComplete()
      }
    }
  })
}

export {
  open,
  close
}
