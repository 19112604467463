import React from 'react'
import PropTypes from 'prop-types'
import glyphs from './glyphs'
import { StyledIcon } from './styled'

const Icon = ({ name, ...props }) => {
  const Glyph = glyphs[name]

  return (
    <StyledIcon {...props} aria-label={name}>
      {Glyph ? <Glyph /> : null}
    </StyledIcon>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default Icon
