import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../'
import { StyledButton } from './styled'
import { ButtonLabel } from '../Typography'
import { useTranslation } from 'react-i18next'

const Button = ({ href, handleClick, useIconColor, ...props }) => {
  const { label, icon } = props
  const { t } = useTranslation()

  return (
    <StyledButton {...props} href={href} as={href ? 'a' : 'button'} onClick={handleClick}>
      {icon && <Icon name={icon} useIconColor={useIconColor} />}
      {label && <ButtonLabel {...props}>{t(label.toLowerCase())}</ButtonLabel>}
    </StyledButton>
  )
}

Button.propTypes = {
  label: PropTypes.string
}

export default Button
