export default {
  paths: {
    assets: '../assets'
  },
  fonts: {
    default: `'Proxima Nova'`,
  },
  colors: {
    white: "#ffffff",
    black: "#0a0a0a",
    gray1: "#f7f7f7",
    gray2: "#dedede",
    gray3: "#909090",
    gray4: "#585857",
    gray5: "#333333",
    gray6: "#DBEAED",
    background: '#E5E5E5',
    primary: {
      default: "#ad0021",
      dark: "#8a151e",
      light: "#c41130"
    },
    secondary: {
      default: "#90b6bd",
      dark: "#72989f",
      light: "#a4cad1"
    },
    tertiary: {
      default: "#a3acb2",
      dark: "#858e94",
      light: "#cbd4da",
      light2: "#EAEEF0",
      light4: "#F5F6F8",
    },
    status: {
      default: "#5dd39e",
      warning: "#ffc857",
      error: "#f24767"
    }
  },
  typography: {
    h1: {
      'font-size': 36,
      mq: {
          desktop: {
              'font-size': 48,
          }
      }
    },
    h2: {
      'font-size': 32,
      mq: {
          desktop: {
              'font-size': 44,
          }
      }
    },
    h3: {
      'font-size': 24,
      mq: {
          desktop: {
              'font-size': 36,
          }
      }
    },
    h4: {
      'font-size': 20,
      'line-height': 24,
      mq: {
          desktop: {
              'font-size': 32,
              'line-height': 38,
          }
      }
    },
    h5: {
      'font-size': 16,
      mq: {
          desktop: {
              'font-size': 24,
          }
      }
    },
    h6: {
      'font-size': 12,
      mq: {
          desktop: {
              'font-size': 16,
          }
      }
    },
    paragraphRegular: {
      'font-size': 14,
      'line-height': 20,
      mq: {
        desktop: {
          'font-size': 14,
          'line-height': 20,
        }
      }
    }
  },
  layers: {
    submenu: 4,
    header: 25,
    modal: 30,
    loader: 35
  },
  breakpoints: {
    tablet: 768,
    desktop: 1024,
    wide: 1280
  },
  grid: {
    columns: 12,
    gutter: {
      mobile: 16,
      desktop: 24
    }
  },
  containerMaxWidths: {
    tablet: 720,
    desktop: 992,
    wide: 1184
  },
  defaults: {
    size: 8,
    duration: '.3s',
    baseTransition: '.4s ease-out 0s',
    fastTransition: '.2s ease-out 0s',
  },
  commons: {
    boxShadow: '0 0 20px rgba(144, 144, 144, 0.2)'
  }
}
