import React from 'react'

export default () => (
  <svg viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="32" fill="#CBD4DA" fillOpacity="0.4"/>
    <ellipse opacity="0.36" cx="32" cy="31.8939" rx="20" ry="19.8939" fill="#90B6BD"/>
    <g filter="url(#filter0_d_737_37961)">
      <ellipse cx="31.9999" cy="31.8943" rx="11.4286" ry="11.3679" fill="#A4CAD1"/>
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M35.8095 32.1306H32.238V35.6831H31.7619V32.1306H28.1904V31.657H31.7619V28.1045H32.238V31.657H35.8095V32.1306Z" fill="white"/>
    <path d="M50.8802 49.2594L44.0806 42.4956L49.6559 40.1188C49.7672 40.0717 49.8359 39.9593 49.8274 39.8393C49.8192 39.7193 49.7349 39.618 49.6182 39.5866L34.858 35.6338C34.7598 35.6073 34.6551 35.6355 34.5833 35.707C34.5115 35.7784 34.4834 35.8826 34.5097 35.98L38.4836 50.6618C38.5151 50.7781 38.617 50.8617 38.7377 50.8701C38.8617 50.8769 38.9713 50.8103 39.0187 50.6996L41.4081 45.1536L48.208 51.9173C48.2634 51.9724 48.336 52.0001 48.4087 52.0001C48.4814 52.0001 48.554 51.9724 48.6094 51.9173L50.8802 49.6586C50.9912 49.5482 50.9912 49.3698 50.8802 49.2594Z" fill="#72989F"/>
    <defs>
      <filter id="filter0_d_737_37961" x="8.86396" y="8.81904" width="46.2716" height="46.15" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="5.85366"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_737_37961"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_737_37961" result="shape"/>
      </filter>
    </defs>
  </svg>
)
