import React, { useState, useContext, useLayoutEffect, useRef, useCallback, useEffect } from 'react'
import { Page, Container, FloatingContainer, ShowTutorial, TopContainer } from '../components/Layout'
import {
  CatalogDetailsModal,
  RoomExplorer,
  Modal,
  SystemModal,
  Tutorial,
  Icon,
  Button,
  SystemExplorerModal,
  RecapModal,
} from '../components'
import { useOutletContext, useParams } from 'react-router-dom'
import { handleResize, getStepData, getApartmentData } from '../utils'
import { Scene } from '../components'
import InfopointsList from '../components/Infopoint/InfopointsList'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../contexts/Responsive'
import { useTranslation } from 'react-i18next'
import { roomReveal } from '../animations/room'
import DataLayerProvider, { EVENT_TYPES } from '../contexts/DataLayer'
import { AuthContext } from '../contexts/Auth'

gsap.registerPlugin(Draggable, InertiaPlugin)

const Room = () => {
  const { authToken } = useContext(AuthContext)
  const { lang } = useParams()
  const [ catalogDetails, setCatalogDetails ] = useState({
      isVisible: false,
      id: false,
      type: false,
      link: false
  })
  const [ systemModalOpen, setSystemModalOpen ] = useState(false)
  const [ systemExplorerModalOpen, setSystemExplorerModalOpen ] = useState(false)
  const [ showTutorial, setShowTutorial ] = useState(false)
  const [ showRecapModal, setShowRecapModal ] = useState(false)
  const { hide, experience, setExperience, setNewPage, appData, logPageLanding } = useOutletContext()
  const params = useParams()
  const { room } = params
  const stepData = getStepData('room', appData, params)
  const previousStepData = getStepData('house', appData, params)
  const apartmentData = getApartmentData(previousStepData, params)
  const { components } = stepData
  const { 
      infopoints = false, 
      roomExplorer = false, 
      systemModal = false, 
      systemExplorerModal = false,
      recapModal = false
  } = components
  const hasInfopoints = infopoints && infopoints.length
  const floatingContainer = useRef(null)
  const mainRef = useRef(null)
  const roomExplorerRef = useRef(null)
  const animationTimeline = useRef(null)
  const roomRevealed = useRef(null)
  const { checkBreakpoint } = useContext(ThemeContext)
  const { t } = useTranslation()
  const isMobile = window.innerWidth < 768

  const openSystemModal = useCallback(() => setSystemModalOpen(true), [])
  const closeSystemModal = useCallback(() => setSystemModalOpen(false), [])
  const openSystemExplorerModal = useCallback(() => setSystemExplorerModalOpen(true), [])
  const closeSystemExplorerModal = useCallback(() => setSystemExplorerModalOpen(false), [])

  useLayoutEffect(() => {
    handleResize(floatingContainer)

    // if (isMobile) {
    //     Draggable.create(floatingContainer.current, {
    //         type: "x",
    //         edgeResistance: 1,
    //         bounds: mainRef.current,
    //         inertia: true,
    //     })
    // }

    if (isMobile) {
      animationTimeline.current = roomReveal(floatingContainer.current, roomExplorerRef.current).pause()
    }

    if (!isMobile) {
      window.addEventListener('resize', () => handleResize(floatingContainer))
    }

    return () => {
      // if (isMobile && Draggable.get(floatingContainer.current)) Draggable.get(floatingContainer.current).kill()
      window.removeEventListener('resize', () => handleResize(floatingContainer))
    }
  }, [])

  const showCatalogDetails = async (id, type, link) => {
    setCatalogDetails({ isVisible: true, id, type, link })
  }

  return (
    <Page ref={mainRef}>
      <FloatingContainer ref={floatingContainer}>
        <Scene
          experience={experience}
          setExperience={setExperience}
          setNewPage={setNewPage}
          data={stepData}
          playedCallback={() => {
            if (!roomRevealed.current && isMobile) {
              animationTimeline.current.play()
              roomRevealed.current = true
            }

            if (room === 'end') {
              setShowRecapModal(true)
            }
          }}
        />
        <Responsive on={checkBreakpoint.not.desktop}>
          {hasInfopoints ? (
            <DataLayerProvider
              for={EVENT_TYPES.building}
              customBaseConfig={{
                action: 'open',
                eventAction: apartmentData.title,
              }}
            >
              <InfopointsList
                infopoints={infopoints}
                setExperience={setExperience}
                experience={experience}
                hide={hide}
                showCatalogDetails={showCatalogDetails}
                gtmImmediateSubmit
                room
              />
            </DataLayerProvider>
          ) : null}
        </Responsive>
      </FloatingContainer>
      <Responsive on={checkBreakpoint.desktop}>
        {hasInfopoints ? (
          <DataLayerProvider
            for={EVENT_TYPES.building}
            customBaseConfig={{
              action: 'open',
              eventAction: apartmentData.title,
            }}
          >
            <InfopointsList
              infopoints={infopoints}
              setExperience={setExperience}
              experience={experience}
              hide={hide}
              showCatalogDetails={showCatalogDetails}
              gtmImmediateSubmit
            />
          </DataLayerProvider>
        ) : null}
      </Responsive>
      <Container hide={hide} noTransition room>
        {roomExplorer ? (
          <RoomExplorer
            roomExplorerRef={roomExplorerRef}
            roomRevealed={roomRevealed}
            setSystemModalOpen={openSystemModal}
            setSystemExplorerModalOpen={openSystemExplorerModal}
            reverseRoomAnimation={() => {
              if (isMobile && roomRevealed.current) {
                animationTimeline.current.reverse()
              }
            }}
            {...roomExplorer}
          />
        ) : null}
      </Container>
      <TopContainer hide={hide}>
        <div>
          <Responsive on={checkBreakpoint.desktop}>
            <Button tertiary icon={'map'} label={t('sistema')} handleClick={() => openSystemModal()} small />
          </Responsive>
          <ShowTutorial>
            <Button tertiary icon={'tutorial-icon'} handleClick={() => setShowTutorial(true)} small/>
          </ShowTutorial>
        </div>
      </TopContainer>
      <Tutorial isVisible={showTutorial} setShowTutorial={setShowTutorial} />
      {catalogDetails ? (
        <DataLayerProvider
          for={EVENT_TYPES.building}
          customBaseConfig={{
            action: 'gotocatalog',
            eventAction: apartmentData.title,
          }}
        >
          <CatalogDetailsModal {...catalogDetails} setProductDetails={setCatalogDetails} />
        </DataLayerProvider>
      ) : null}
      {systemModal ? (
        <Modal open={systemModalOpen} onCloseRequest={closeSystemModal} systemModal>
          <SystemModal system={systemModal} />
        </Modal>
      ) : null}
      {systemExplorerModal ? (
        <Modal open={systemExplorerModalOpen} onCloseRequest={closeSystemExplorerModal}>
          <SystemExplorerModal modal={systemExplorerModal} closeModal={closeSystemExplorerModal} />
        </Modal>
      ) : null}
      {recapModal ? (
        <RecapModal
          openSystemModal={openSystemModal}
          openSystemExplorerModal={openSystemExplorerModal}
          systemModalOpen={systemModalOpen}
          systemExplorerModalOpen={systemExplorerModalOpen}
          show={showRecapModal}
          {...recapModal}
        />
      ) : null}
    </Page>
  )
}

export default Room
