import React, { useState, useRef, useLayoutEffect, useCallback, useEffect } from "react"
import { Page, FloatingContainer, TopContainer, ShowTutorial } from '../components/Layout'
import { Scene, ApartmentSelector, Modal, SystemModal, Tutorial, Button, Link } from "../components"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { handleResize, getStepData } from "../utils"
import { apartmentSelectorReveal } from "../animations/floating-container"
import DataLayerProvider, { EVENT_TYPES } from '../contexts/DataLayer'
import { useTranslation } from 'react-i18next'

const House = () => {
  const [selectedApartment, setSelectedApartment] = useState(0)
  const [systemModalOpen, setSystemModalOpen] = useState(false)
  const [showTutorial, setShowTutorial] = useState(false)
  const { hide, experience, setExperience, setNewPage, newPage, appData, logPageLanding } = useOutletContext()
  const params = useParams()
  const stepData = getStepData('house', appData, params)
  const { components } = stepData
  const { apartmentSelector } = components
  const floatingContainer = useRef(null)
  const panel = useRef(null)
  const panelSlider = useRef(null)
  const panelInfopoints = useRef(null)
  const isMobile = window.innerWidth < 768
  const mainRef = useRef(null)
  const panelTimeline = useRef(null)
  const videoTimeline = useRef(null)
  const masterTimeline = useRef(null)
  const navigate = useNavigate()
  const { lang } = params
  const { t } = useTranslation()

  console.debug({ components })

  const open = useCallback(() => setSystemModalOpen(true), [])

  const close = useCallback(() => setSystemModalOpen(false), [])

  useLayoutEffect(() => {
    // handleResize(floatingContainer)
    // window.addEventListener('resize', () => handleResize(floatingContainer))

    masterTimeline.current = apartmentSelectorReveal(
      floatingContainer.current,
      panel.current,
      panelSlider.current,
      panelInfopoints.current
    ).pause()

    return () => {
      // window.removeEventListener('resize', () => handleResize(floatingContainer))
    }
  }, [])

  const injectParams = (pushObj) => {
    if (pushObj) {
      const action = pushObj.eventLabel
      pushObj.eventLabel = `${action} - system`
      pushObj.action = `${action} - system`
    }
  }

  return (
    <Page ref={mainRef}>
      <FloatingContainer ref={floatingContainer} fixed>
        <Scene
          experience={experience}
          setExperience={setExperience}
          setNewPage={setNewPage}
          data={stepData}
          playedCallback={() => {
            // panelTimeline.current.play()
            // videoTimeline.current.play()
            masterTimeline.current.play()
          }}
        />
      </FloatingContainer>
      <DataLayerProvider for={EVENT_TYPES.building}>
        <ApartmentSelector
          wrapperRef={panel}
          sliderRef={panelSlider}
          infopointsRef={panelInfopoints}
          data={apartmentSelector}
          setSelectedApartment={setSelectedApartment}
          setSystemModalOpen={open}
          rewindAnimations={() => {
            // panelTimeline.current.reverse()
            // videoTimeline.current.reverse()
            masterTimeline.current.reverse()
          }}
        />
      </DataLayerProvider>
      <TopContainer hide={hide} above>
        <div>
          <Link label={t('torna ai quartieri')} iconLeft icon={'chevron-left'} to={`/${lang}`}/>
				</div>
        <div>
          {/* <ShowTutorial>
            <Button tertiary icon={'tutorial-icon'} handleClick={() => setShowTutorial(true)} small/>
          </ShowTutorial> */}
        </div>
      </TopContainer>
      <Tutorial isVisible={showTutorial} setShowTutorial={setShowTutorial} />
      <Modal open={systemModalOpen} onCloseRequest={close} systemModal>
        <DataLayerProvider
          for={EVENT_TYPES.building}
          customBaseConfig={{ eventAction: params.house }}
          mergeFn={injectParams}
        >
          <SystemModal system={apartmentSelector.apartments[selectedApartment].systemModal} />
        </DataLayerProvider>
      </Modal>
    </Page>
  )
}

export default House
