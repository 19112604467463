import React, { useEffect, useState } from 'react'

function useOutsideTarget (ref) {
  const [isOutsideTarget, setOutsideTarget] = useState(false)

  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOutsideTarget(true)
      } else {
        setOutsideTarget(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
        document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])

  return isOutsideTarget
}

export default useOutsideTarget
