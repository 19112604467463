import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import {
  StyledHeader,
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledHeaderHead,
  StyledHeaderHeadTitle,
  StyledHeaderLogo,
  StyledHeaderIconSearch
} from './styled'
import { primaryTheme, secondaryTheme } from './config'
import { Container } from '../Layout'
import glyphs from '../Icon/glyphs'
import { useTranslation } from 'react-i18next'

const EmptyHeader = (props) => {
  const { t } = useTranslation()
  const Logo = glyphs['logo']
  const { checkBreakpoint } = useContext(ThemeContext)
  const { current, logoUrl } = props

  const secondary = false

  return (
    <ThemeProvider theme={secondary ? secondaryTheme : primaryTheme}>
      <StyledHeader>
        <StyledHeaderContainer>
          <Container>
            <StyledHeaderWrapper>
              <StyledHeaderHead>
                <a href={logoUrl}>
                  <StyledHeaderLogo><Logo /></StyledHeaderLogo>
                  <Responsive on={checkBreakpoint.tablet}>
                    <StyledHeaderHeadTitle as="h5" semiBold>{t(current.toLowerCase())}</StyledHeaderHeadTitle>
                  </Responsive>
                </a>
              </StyledHeaderHead>
            </StyledHeaderWrapper>
          </Container>
        </StyledHeaderContainer>
      </StyledHeader>
    </ThemeProvider>
  )
}

EmptyHeader.propTypes = {
  current: PropTypes.string,
  logoUrl: PropTypes.string
}

EmptyHeader.defaultProps = {}

export default EmptyHeader
