import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'

export const StyledIcon = styled.i`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => styledMap`
    huge: ${theme.rem(40)};
    big: ${theme.rem(20)};
    default: ${theme.rem(16)};
  `};
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'defult'};

  svg {
    width: 1em;
    height: 1em;
  }

  path {
    ${({ useIconColor, color = 'currentColor' }) => !useIconColor && css`
      fill: ${color};
    `}
  }

  ${({ round, theme }) => round && css`
    padding: ${theme.rem(8)};
    background-color: ${theme.colors.white};
    box-shadow: ${theme.commons.boxShadow};
    border-radius: 50%;
  `}
`
