import React, { useContext } from 'react'
import {
  StyledTutorial,
  StyledTutorialBackdrop,
  StyledTutorialContent,
  StyledTutorialClose,
  StyledTutorialHeading,
  StyledTutorialItems,
  StyledTutorialItem,
  StyledTutorialActions
 } from './styled'
import TutorialFixtures from '../../fixtures/Tutorial'
import { Icon, Button } from '../'
import { Spacer } from '../Layout/index'
import { Heading, Paragraph } from '../Typography'
import { ThemeContext } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import { useTranslation } from 'react-i18next'
import { setCookie } from '../../utils'

const Tutorial = ({isVisible, setShowTutorial}) => {
  const { checkBreakpoint } = useContext(ThemeContext)
  const { t } = useTranslation()

  return (
    <StyledTutorial isVisible={isVisible}>
      <StyledTutorialBackdrop onClick={() => setShowTutorial(false)} />
      <StyledTutorialContent>
        <StyledTutorialClose onClick={() => setShowTutorial(false)}>
          <Icon name={'close'} />
        </StyledTutorialClose>
        <StyledTutorialHeading>
          <Heading as={'h5'} center uppercase bold>{t('scopri come navigare lo showroom virtuale')}</Heading>
        </StyledTutorialHeading>
        <Spacer xs={3}/>
        <StyledTutorialItems>
          {TutorialFixtures.map((element, i) => {
            return (
              <StyledTutorialItem key={i}>
                <Responsive on={checkBreakpoint.not.desktop}>
                  <Icon name={element.icon.mobile} useIconColor />
                </Responsive>
                <Responsive on={checkBreakpoint.desktop}>
                  <Icon name={element.icon.desktop} useIconColor />
                </Responsive>
                <Paragraph large>
                  {t(element.text)}
                </Paragraph>
              </StyledTutorialItem>
            )
          })}
        </StyledTutorialItems>
        <Spacer xs={5}/>
        <StyledTutorialActions>
          <Button label={t('inizia a esplorare')} handleClick={() => setShowTutorial(false)} small/>
          <Button label={t('non mostrare più')} small tertiary handleClick={() => {
            setCookie()
            setShowTutorial(false)
          }} />
        </StyledTutorialActions>
      </StyledTutorialContent>
    </StyledTutorial>
  )
}

export default Tutorial
