import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	StyledMenu,
	StyledMenuList,
	StyledMenuItem,
	StyledMenuItemLink,
	StyledSubMenu,
	StyledSubMenuLink,
} from './styled'
import { ButtonLabel } from '../Typography'
import { Container, Grid, GridItem } from '../Layout'
import TrackedLink from '../Link/TrackedLink'

const Menu = ({ items }) => {
	const [isSubmenuShown, setSubmenuShown] = useState(false)

	const handleMenuItemClick = (menuItemLabel, menuSubitemLabel, fn, evt) => {
		evt?.stopPropagation()

		fn?.()
	}

	return (
		<StyledMenu>
			<StyledMenuList>
				{items.length &&
					items.map(({ ...item }, i) => {
						const hasSubmenuItems = item.items && item.items.length
						return (
							<StyledMenuItem
								key={i}
								onMouseEnter={hasSubmenuItems ? () => setSubmenuShown(i) : () => {}}
								onMouseLeave={hasSubmenuItems ? () => setSubmenuShown(false) : () => {}}
							>
								<StyledMenuItemLink to={item.url}>
									<ButtonLabel
										semiBold
										onClick={handleMenuItemClick.bind(
											this,
											item.label,
											undefined,
											hasSubmenuItems ? () => setSubmenuShown(false) : () => {}
										)}
									>
										{item.label}
									</ButtonLabel>
								</StyledMenuItemLink>
								{hasSubmenuItems && (
									<StyledSubMenu isVisible={isSubmenuShown === i}>
										<Container>
											<Grid>
												<GridItem sm={10} offsetSm={1}>
													<Grid>
														{item.items.map((link, i) => (
															<GridItem sm={12} key={i}>
																<TrackedLink
																	to={link.link.url}
																	onClick={handleMenuItemClick.bind(
																		this,
																		item.label,
																		link.name,
																		hasSubmenuItems ? () => setSubmenuShown(false) : () => {}
																	)}
																>
																	<StyledSubMenuLink>{link.name}</StyledSubMenuLink>
																</TrackedLink>
															</GridItem>
														))}
													</Grid>
												</GridItem>
											</Grid>
										</Container>
									</StyledSubMenu>
								)}
							</StyledMenuItem>
						)
					})}
			</StyledMenuList>
		</StyledMenu>
	)
}

Menu.propTypes = {
	items: PropTypes.array,
}

Menu.defaultProps = {
	items: [],
}

export default Menu
