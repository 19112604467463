import styled, { css } from 'styled-components/macro'

export const StyledScene = styled.div`
    /* position: absolute;
    left: 0;
    right: 0;
    top: ${({ theme }) => theme.rem(80)};
    width: 100%;
    height: calc(100% - ${({ theme }) => theme.rem(80)});
    overflow: hidden; */

    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .plyr {
        width: 100%;
        height: 100%;
        pointer-events: none!important;
    }

    .plyr__control,
    .plyr__controls {
        display: none!important;
    }

    .plyr__poster {
        background-size: cover;
    }

    z-index: 10;
`

export const StyledSceneVideo = styled.video`
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    /* left: 50%;
    transform: translateX(-50%); */
    pointer-events: none;
    object-fit: cover!important;
`

export const StyledSceneImage = styled.img`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    transition: opacity ${({ theme }) => theme.defaults.baseTransition};
    /* opacity: 0; */
    object-fit: cover;

    ${({ hide }) => hide && css`display: none;`};
    
    /* ${({ homepage }) => homepage && css`opacity: 1;`}; */
    
    /* ${({ played, withPanels }) => played && withPanels && css`object-fit: cover;`}; */
`

export const StyledSceneButton = styled.div`
    position: absolute;
    left: 50%;
    bottom: ${({ theme }) => theme.rem(160)};
    transform: translateX(-50%);
    z-index: 10;
    transition: opacity ${({ theme }) => theme.defaults.baseTransition};
    opacity: 0;

    ${({ playing }) => playing && css`opacity: 1;`};
    
    button {
        min-width: ${({ theme }) => theme.rem(144)};
    }
`

export const StyledSceneAssetsContainer = styled.div`
    width: 100vw;
    height: 100vh;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`