import styled, { css } from 'styled-components/macro'
import { NavLink } from "react-router-dom"
import { Heading } from '../Typography'
import Icon from '../Icon'

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.header};

  a {
    pointer-events: all;
  }

  ${({ disable }) => disable && css`

    button,
    a,
    div {
      pointer-events: none!important;
    }
  `}
`

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  background-color:  ${({ theme }) => theme.backgroundColor};
  box-shadow: 0 0 20px rgb(144 144 144 / 20%);
  min-height: ${({ theme }) => theme.rem(80)};

  ${({ theme }) => theme.mediaQuery.desktop(`
    min-height: ${theme.rem(80)};
  `)}
`

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1 0 auto;
  flex-wrap: wrap;
  pointer-events: all;

  ${({ theme }) => theme.mediaQuery.desktop(`
    justify-content: space-between;
  `)}
`

export const StyledHeaderHead = styled.div`
  position: relative;

  display: flex;

  align-items: center;
  color: ${({ theme }) => theme.color};

  a {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  svg {
    width: 51px;
    height: 30px;
  }
`

export const StyledHeaderHeadTitle = styled(Heading)`
  padding-left: ${({ theme }) => theme.rem(8)};
  color: ${({ theme }) => theme.colors.gray5};
  letter-spacing: 0.5px;


`

export const StyledHeaderLogo = styled.i`
  padding-right: ${({ theme }) => theme.rem(8)};
  border-right: 1px solid ${({ theme }) => theme.colors.gray2};

  ${({ theme }) => theme.mediaQuery.tablet(`
    border-right: 1px solid ${theme.colors.gray2};
  `)}
`

export const StyledHeaderIconSearch = styled(NavLink)`
  padding: ${({ theme }) => theme.rem(16)};
  i {
    font-size: ${({ theme }) => theme.rem(20)};
  }

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding: ${theme.rem(8)};
  `)}

  &:hover {
    color: ${({ theme }) => theme.colors.primary.default};
  }

  &.active {
    color: ${({ theme }) => theme.colors.primary.default};
  }
`
