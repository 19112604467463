import styled, { css } from 'styled-components/macro'
import { StyledIcon } from '../Icon/styled'

export const StyledModalContent = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.rem(550)};
  min-height: initial;
  /* padding: ${({ theme }) => theme.rem(48, 32, 40)}; */
  position: relative;
  transform: translate3d(0, 50%, 0);
  background-color: ${({ theme }) => theme.color('white')};
  border-radius: ${({ theme }) => theme.rem(4)};
  transition: transform ${({ theme }) => `${theme.defaults.duration} ${theme.defaults.duration}`};
  will-change: transform;
  align-items: center;

  & > ${StyledIcon} {
    width: ${({ theme }) => theme.rem(24)};
    height: ${({ theme }) => theme.rem(24)};
    position: absolute;
    top: ${({ theme }) => theme.rem(-40)};
    right: 0;
    z-index: 2;
    transition: color ${({ theme }) => theme.defaults.duration};
    color: ${({ theme }) => theme.colors.white};

    html:not(.touch) &:hover {
      color: ${({ theme }) => theme.colors.primary.default};
    }

    @media (pointer: fine) {
      &:hover {
        color: ${({ theme }) => theme.colors.primary.default};
      }
    }
  }

  ${({ fullscreen }) => fullscreen && css`
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    padding: 0;

    > ${StyledIcon} {
      display: none;
    }
  `}

  ${({ systemModal, theme }) => systemModal && css`
      ${theme.mediaQuery.desktop(`
        width: ${theme.rem(370)};
      `)}
  `}
`

export const StyledModal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${({ theme, fullscreen }) => !fullscreen && theme.rem(80, 8)};
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme, open }) => open ? theme.layers.modal : -999};
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: ${({ open }) => open ? 1 : 0};
  transition-property: z-index, opacity;
  transition-duration: 0s, ${({ theme }) => theme.defaults.duration};
  transition-delay: ${({ theme, open }) => open ? '0s' : theme.defaults.duration}, 0s;
  pointer-events: ${({ open }) => open ? 'all' : 'none'};

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding: ${({ theme, fullscreen }) => !fullscreen && theme.rem(20)};
    align-items: center;
  `)}

  ${({ open }) => open ? `
    ${StyledModalContent} {
      transform: none;
      transition-delay: 0s;
    }
  ` : null}

`
