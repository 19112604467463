import React from 'react'
import PropTypes from 'prop-types'
import * as Components from '../'
import { Heading } from '../Typography'
import { Container, Spacer } from '../Layout'
import { StyledSection, StyledSectionWrapper, StyledSectionTitle } from './styled'

const WithContainer = ({ fluid, children }) => {
  return (
    fluid ? <>{ children }</> : <Container>{ children }</Container>
  )
}

const Section = ({children, spacing, fluid, title, withPadding}) => {
  return (
    <StyledSection spacing={spacing}>
      <WithContainer fluid={fluid}>
          { title ? (
            <StyledSectionTitle withPadding={withPadding}>
              <Heading as="h2" bold uppercase>{ title }</Heading>
            </StyledSectionTitle>
          ) : null }
          { title ? <Spacer xs={3} /> : null}
          <StyledSectionWrapper fluid={fluid} withPadding={withPadding}>
            {children}
          </StyledSectionWrapper>
          { title ? <Spacer xs={6} /> : null}
      </WithContainer>
    </StyledSection>
  )
}

Section.propTypes = {
  data: PropTypes.array
}

Section.defaultProps = {
  data: []
}

export default Section
