import gsap, { Power2 } from 'gsap'

const duration = 0.6
const delay = 0
const isMobile = window.innerWidth <= 768

export const showPanel = (wrapper, slider, infopoints) => {
    const tl = gsap.timeline({ defaults: { duration, delay, ease: Power2.easeOut }})

    if (isMobile) {
        tl.to(wrapper, {y: '0'})
        tl.set(wrapper, {height: 'calc(100vh - 5rem)'})
    } else {
        tl.to(wrapper, {x: '0'})
        tl.set(wrapper, {width: '100vw'})
    }

    tl.to(slider, { opacity: 1})
    tl.to(infopoints, { opacity: 1})

    return tl
}

export const shrinkVideo = (container) => {
    const tl = gsap.timeline({ defaults: { duration: duration * 0.88, delay, ease: Power2.easeOut }})
    const containerHeight = container.offsetHeight
    
    if (isMobile) {
        tl.set(container, {
            top: `0`,
            left: 'auto',
            width: '100%',
            transform: 'none'
          })
          .to(container, { height: '46vh'})
    } else {
        tl.set(container, {
            top: `0`,
            transform: 'none',
            left: 'auto',
            height: '100vh'
          })
        tl.to(container, { width: '70vw' })
    }

    return tl
}

export const moveVideo = (container) => {
    const video = container.querySelector('video')
    const tl = gsap.timeline({ defaults: { duration: duration * 0.98, delay, ease: Power2.easeOut }})
    
    if (isMobile) {
        tl.set(container, {
            top: `0`,
            left: 'auto',
            width: '100%',
            transform: 'none'
          })
        .to(container, { height: '46vh'})
    } else {
        tl.to(container, { width: '70vw' })
    }

    return tl
}

export const apartmentSelectorReveal = (container, wrapper, slider, infopoints) => {
    const tl = gsap.timeline()

    console.log({tl})

    tl.add(showPanel(wrapper, slider, infopoints), 0)
    tl.add(moveVideo(container), 0)

    return tl
}