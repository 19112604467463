import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom"
import './i18n/i18n'
import ThemeProvider from './contexts/Theme'
import ResponsiveProvider from './contexts/Responsive'
import GtmProvider, { GtmContext } from './contexts/Gtm'
import PortalProvider from './contexts/Portal'
import { PortalOut } from './contexts/Portal'
import { SharedLayout } from './components'
import Homepage from './pages/Homepage'
import Neighborhood from './pages/Neighborhood'
import Apartment from './pages/Apartment'
import House from './pages/House'
import Room from './pages/Room'
import Auth from './pages/Auth'
import data from './data'
import { getData } from './api'
import { Spinner } from './components'

import '../node_modules/swiper/swiper-bundle.min.css'
import '../node_modules/plyr/dist/plyr.css'
import ErrorPageLayout from './pages/ErrorPageLayout'
import Error404 from './pages/Error404'
import AuthProvider from './contexts/Auth'
import Logout from './pages/Logout'
import { routesConfig } from './routes/config'

const App = () => {
  const [appData, setAppData] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const lang = window.location.pathname.split('/')[1]
  const { pushError } = useContext(GtmContext)

  useEffect(() => {
    setIsLoading(true)
    getData(lang)
        .then((res) => {
        
        setAppData(res)
        setIsLoading(false)
    })
    .catch((/** @type {ApiError} */ error) => {
        if (error.statusCode) {
            const statusCode = parseInt(error.statusCode)
            pushError(statusCode)
        }
  
        setIsLoading(false)
    })
  }, [])

  return (
    <BrowserRouter>
      <ThemeProvider>
        <ResponsiveProvider>
          <PortalProvider>
            {isLoading ? <Spinner /> : null}
            {!isLoading && appData ? (
              <Routes>
                <Route path={"/:lang/login"} element={<Auth />} />
                <Route path="/:lang" element={
                  <AuthProvider>
                    <GtmProvider>
                      <SharedLayout appData={appData} />
                    </GtmProvider>
                  </AuthProvider>
                } >
                  { routesConfig?.map(route => (
                      <Route key={route.path} path={route.path?.substring(1)} element={<route.Page />} {...route.props} />
                    )) }
                  {/* <Route path="*" element={<Error404 />} /> */}
                </Route>
                <Route path="*" element={<ErrorPageLayout />} />
              </Routes>
            ) : null}
            <PortalOut id="modal-portal" />
          </PortalProvider>
        </ResponsiveProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
