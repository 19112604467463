import React from 'react'
import PropTypes from 'prop-types'
import { Page } from '../components/Layout'
import { useTranslation } from 'react-i18next'
import { Button } from '../components'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading } from '../components/Typography'
import { Container, FlexContainer, Spacer } from '../components/Layout'

const Error404 = ({cta}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {lang} = useParams()

  if (!cta) {
    cta = {
      url: `/${lang}`,
      label: 'torna alla homepage',
      internal: true
    }
  }

  const goToCTA = () => {
    if (cta.internal) {
      navigate(cta.url)
    } else {
      window.location = cta.url
    }
  }

  const flexProps = {
    justifyContent: 'center',
    alignItems: 'center'
  }

  return (
    <Page justifyContent="flex-start">
      <Container>

        <FlexContainer {...flexProps}>
          <picture>
            <source srcSet="/assets/images/illustration_404.png" type="image/png" />
            <img src="/assets/images/illustration_404.png" alt="" />
          </picture>
        </FlexContainer>

        <Spacer xs={ 5 } />

        <Heading bold center>{t('pagina non trovata').toUpperCase()}</Heading>

        <Spacer xs={ 5 } />

        <FlexContainer {...flexProps}>
          <Button label={t(cta.label).toUpperCase()} onClick={goToCTA}/>
        </FlexContainer>
      </Container>
    </Page>
  )
}

Error404.propTypes = {
  cta: PropTypes.object
}

Error404.defaultProps = {
  cta: null
}

export default Error404
