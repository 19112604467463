export class ApiError extends Error {
  constructor (message, statusCode) {
    super(message)
    this.statusCode = statusCode
  }
}

export async function handleErrors (response) {
  if (!response.ok) {
    let message = null
    try {
      const { error } = await response.json()
      message = error.message
      // eslint-disable-next-line no-empty
    } catch (error) { }

    throw new ApiError(message || response.statusText, response.status)
  }

  return response
}
