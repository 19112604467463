import React from 'react'

export default () => (
  <svg viewBox="0 0 72 72" fill="none">
    <path d="M50.3005 68.0074H41.5004C40.8954 68.0074 40.4004 67.5124 40.4004 66.9074V52.6074H31.6003V66.9074C31.6003 67.5124 31.1053 68.0074 30.5003 68.0074H21.7002C21.0952 68.0074 20.6002 67.5124 20.6002 66.9074V46.0074H15.1001C14.6601 46.0074 14.2476 45.7324 14.0826 45.3199C13.9176 44.9074 14.0276 44.4124 14.3576 44.1099L35.2579 24.3099C35.6704 23.8974 36.3579 23.8974 36.7704 24.3099L57.6706 44.1099C58.0006 44.4124 58.1106 44.9074 57.9456 45.3199C57.7806 45.7324 57.3681 46.0074 56.9281 46.0074H51.428V66.9074C51.4005 67.5124 50.9055 68.0074 50.3005 68.0074ZM42.6004 65.8074H49.2005V44.9074C49.2005 44.3024 49.6955 43.8074 50.3005 43.8074H54.1506L36.0004 26.6199L17.8501 43.8074H21.7002C22.3052 43.8074 22.8002 44.3024 22.8002 44.9074V65.8074H29.4003V51.5074C29.4003 50.9024 29.8953 50.4074 30.5003 50.4074H41.5004C42.1054 50.4074 42.6004 50.9024 42.6004 51.5074V65.8074Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M30.9941 15.1272C32.5813 14.4697 34.2824 14.1313 36.0003 14.1313C37.7182 14.1313 39.4193 14.4697 41.0065 15.1272C42.5937 15.7846 44.0358 16.7482 45.2505 17.963C45.6254 18.3379 45.6254 18.9458 45.2505 19.3206C44.8755 19.6955 44.2677 19.6955 43.8928 19.3206C42.8564 18.2841 41.6259 17.4619 40.2717 16.901C38.9175 16.3401 37.4661 16.0513 36.0003 16.0513C34.5345 16.0513 33.0831 16.3401 31.7289 16.901C30.3747 17.4619 29.1442 18.2841 28.1078 19.3206C27.7329 19.6955 27.1251 19.6955 26.7501 19.3206C26.3752 18.9458 26.3752 18.3379 26.7501 17.963C27.9648 16.7482 29.4069 15.7846 30.9941 15.1272Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.5147 6.72851C30.205 5.61411 33.0884 5.04053 36.0003 5.04053C38.9122 5.04053 41.7956 5.61411 44.4859 6.72851C47.1761 7.84291 49.6205 9.4763 51.6794 11.5354C52.0543 11.9103 52.0543 12.5182 51.6793 12.8931C51.3044 13.268 50.6966 13.2679 50.3217 12.893C48.441 11.0122 46.2083 9.52023 43.751 8.50234C41.2938 7.48444 38.66 6.96053 36.0003 6.96053C33.3405 6.96053 30.7068 7.48444 28.2495 8.50234C25.7922 9.52023 23.5595 11.0122 21.6789 12.893C21.304 13.2679 20.6962 13.268 20.3212 12.8931C19.9463 12.5182 19.9463 11.9103 20.3212 11.5354C22.3801 9.4763 24.8245 7.84291 27.5147 6.72851Z" fill="#333333"/>
    <path d="M63.5007 57.0079H54.7006C54.0956 57.0079 53.6006 56.5129 53.6006 55.9079C53.6006 55.3029 54.0956 54.808 54.7006 54.808H62.4007V40.5079C62.4007 39.9029 62.8957 39.408 63.5007 39.408H65.2333L53.6006 27.7754L48.7881 32.5879C48.348 33.028 47.6605 33.028 47.2205 32.5879C46.7805 32.1479 46.7805 31.4604 47.2205 31.0205L52.8031 25.438C53.2431 24.9979 53.9306 24.9979 54.3706 25.438L68.6708 39.7379C68.9733 40.0405 69.0833 40.5354 68.9183 40.948C68.7533 41.3605 68.3408 41.6354 67.9008 41.6354H64.6007V55.9354C64.6007 56.5129 64.1057 57.0079 63.5007 57.0079Z" fill="#333333"/>
    <path d="M17.3002 57.0079H8.50006C7.89506 57.0079 7.40005 56.5129 7.40005 55.9079V41.6079H4.10001C3.66001 41.6079 3.2475 41.333 3.0825 40.9204C2.9175 40.5079 3 40.0405 3.33 39.7379L17.6302 25.438C18.0702 24.9979 18.7577 24.9979 19.1977 25.438L24.7803 31.0205C25.2203 31.4604 25.2203 32.1479 24.7803 32.5879C24.3403 33.028 23.6527 33.028 23.2127 32.5879L18.4002 27.7754L6.76754 39.408H8.50006C9.10507 39.408 9.60008 39.9029 9.60008 40.5079V54.808H17.3002C17.9052 54.808 18.4002 55.3029 18.4002 55.9079C18.4002 56.5129 17.9052 57.0079 17.3002 57.0079Z" fill="#333333"/>
  </svg>
  
)
