import { ENDPOINTS } from './config'
import { handleErrors, ApiError } from './errors'

export const getApiUrl = (lang) => {
  return `${process.env.API_URL}${lang}`
}

export const getShowroomApiUrl = (lang) => {
  return `${process.env.SHOWROOM_API_URL}${lang}`
}

const fetchWrapper = (lang, token, endpoint, options = {}) => {
  const url = getApiUrl(lang)

  return fetch(
    `${url}/${endpoint}`,
    {
      ...options,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
  )
    .then(handleErrors)
    .then(res => res.json())
    .catch(error => {
      throw new ApiError(error.message, error.statusCode)
    })
}

export const getData = async (lang) => {
  const url = getShowroomApiUrl(lang)
  
  return fetch(
    `${url}/${ENDPOINTS.json}`,
  )
    .then(handleErrors)
    .then(res => res.json())
    .catch(error => {
      throw new ApiError(error.message, error.statusCode)
    })
}

// eslint-disable-next-line space-before-function-paren
export const validateUserToken = async (token, lang) => {
  return fetchWrapper(lang, token, ENDPOINTS.user)
}

export const getCatalogDetailsData = async (lang, authToken, id, type = 'product') => {
  const baseEndpoint = ENDPOINTS[`${type}`]
  const endpoint = `${baseEndpoint}${id}/showroom`
  return fetchWrapper(lang, authToken, endpoint)
}

export const getServiceMenuData = async (lang, authToken) => {
  return fetchWrapper(lang, authToken, ENDPOINTS.servicesMenu)
}

export const getSystemExplorerModalData = async (lang, authToken, system) => {
  return Promise.all(system.map(({id, type}) => {
    const baseEndpoint = ENDPOINTS[`${type}`]
    const endpoint = `${baseEndpoint}${id}/showroom`
    return fetchWrapper(lang, authToken, endpoint)
  }))
}
