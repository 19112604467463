import styled, { css } from 'styled-components/macro'

export const StyledImg = styled.img`
  ${({hide}) => css`
    opacity: ${hide ? 0 : 1};
    ${hide || 'transition: opacity 0.3s;'}
  `};
  width: 100%;
  ${({ aspectRatio }) => aspectRatio && css`
  aspect-ratio: ${aspectRatio};
  `};
  ${({ fit }) => fit && css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `};
`
