import React from 'react'

export default () => (
  <svg viewBox="0 0 40 40" fill="none">
    <circle opacity="0.2" cx="20" cy="20" r="20" fill="#A4CAD1"/>
    <path d="M22.1782 30C22.8477 29.1716 23.4634 28.3457 23.891 27.6392C24.1151 27.2689 23.9867 26.7081 23.5686 26.4454C23.2195 26.226 22.7922 26.2686 22.5202 26.5186C22.8053 26.0172 22.6218 25.3548 22.0989 25.0262L22.0665 25.0058C21.6517 24.7452 21.15 24.7754 20.8035 25.0393C20.8035 25.0393 21.3464 24.0577 20.4225 23.4771L20.3924 23.4582C19.9402 23.1741 19.3937 23.177 19.0396 23.5995L21.9734 18.7518C22.3935 18.0577 22.1818 17.148 21.5006 16.72C20.8194 16.2919 19.9267 16.5076 19.5066 17.2017L15.9207 23.1266L14.2109 22.0522C13.7928 21.7895 13.2593 21.8981 13.0193 22.2947L11.2807 25.1674C10.52 26.4242 11.8557 28.5861 11 30H22.1782Z" fill="#A4CAD1"/>
    <path d="M28.9808 14.9757L27.9473 11.7833C27.8785 11.5708 27.6433 11.4513 27.4252 11.5189C27.2055 11.5856 27.0835 11.8122 27.1522 12.0249L27.8833 14.283C25.5771 13.1865 23.0246 12.6088 20.4388 12.6088C17.3456 12.6088 14.3232 13.4324 11.6991 14.9904C11.5029 15.1069 11.4411 15.3555 11.5615 15.5455C11.6401 15.6699 11.7768 15.7383 11.9172 15.7383C11.9912 15.7383 12.0665 15.7192 12.1344 15.6788C14.6275 14.1986 17.4994 13.4162 20.4388 13.4162C22.8905 13.4162 25.3103 13.9616 27.4973 14.9982L25.1116 15.7103C24.8915 15.776 24.7682 16.0019 24.8357 16.215C24.8911 16.3884 25.0559 16.5 25.2341 16.5C25.2744 16.5 25.3159 16.4941 25.3566 16.4819L28.7058 15.4823C28.812 15.4507 28.9007 15.3794 28.9519 15.2843C29.0036 15.1893 29.0142 15.0782 28.9808 14.9757Z" fill="#72989F"/>
    <path opacity="0.61" d="M14.2124 24.9346C14.0023 24.8092 13.937 24.5428 14.0666 24.3395L15.2393 22.499L16 22.953L14.8272 24.7935C14.6977 24.9968 14.4224 25.06 14.2124 24.9346Z" fill="#90B6BD"/>
  </svg>
)
