import React from 'react'

export default () => (
  <svg viewBox="0 0 40 40" fill="none">
    <circle opacity="0.2" cx="20" cy="20" r="20" fill="#A4CAD1"/>
    <path d="M17.4643 13.6376C17.3718 13.4228 17.1355 13.3292 16.9353 13.4281L15.2764 14.2501L16.3679 11.077C16.4442 10.8551 16.3387 10.6088 16.1322 10.5268C15.9261 10.4444 15.6966 10.5582 15.6201 10.7801L14.5278 13.9554L13.7617 12.1712C13.6694 11.9564 13.4328 11.8628 13.2327 11.9617C13.0328 12.0608 12.9456 12.3152 13.0377 12.53L14.2065 15.252C14.2993 15.4681 14.5411 15.5578 14.7355 15.4615L17.2693 14.2059C17.4692 14.1068 17.5564 13.8523 17.4643 13.6376Z" fill="#72989F"/>
    <path d="M13.795 17.2482C13.7924 17.2421 13.7873 17.2385 13.7844 17.2326C13.6783 17.0121 13.4499 16.9514 13.2705 17.0376C13.269 17.0383 13.2674 17.038 13.266 17.0387L10.7322 18.294C10.5323 18.3931 10.4451 18.6475 10.5372 18.8621C10.6296 19.0768 10.8659 19.1705 11.0662 19.0716L12.7234 18.2504L11.6273 21.4226C11.5506 21.6444 11.6559 21.8909 11.8623 21.973C11.908 21.9912 11.9549 22 12.001 22C12.1628 22 12.3152 21.8932 12.3747 21.7207L13.4729 18.5425L14.2398 20.3279C14.3071 20.4845 14.4513 20.5767 14.602 20.5767C14.6578 20.5767 14.7147 20.5641 14.7688 20.5374C14.9687 20.4383 15.0559 20.1839 14.9638 19.9692L13.795 17.2482Z" fill="#72989F"/>
    <path d="M26.9322 20.769C26.5648 20.0032 25.8152 19.6515 24.6478 19.799L24.6461 19.8025C24.2166 19.3205 23.5456 19.0437 22.5412 19.1365L22.5395 19.1397C22.2618 17.5544 21.7446 16.0846 21.1579 15.1372C20.8228 14.596 20.1375 14.3468 19.5546 14.5984C18.8089 14.9202 18.5541 15.8144 18.9703 16.4733C19.6644 17.5722 20.0319 20.0871 19.8682 22.1259C19.8089 22.8644 18.8934 23.1711 18.392 22.6278L17.5803 21.7483C17.0147 21.1454 16.0696 21.1173 15.4695 21.6856C14.8694 22.2539 14.8415 23.2033 15.4071 23.8062L19.7699 29.5H27.4493L28.844 24.1757C29.2515 22.6202 28.9461 20.9332 26.9322 20.769Z" fill="#A4CAD1"/>
  </svg>
)
