export const getStepData = (step, appData, params) => {
  const { lang, neighborhood, house, apartment, room } = params
  let data

  switch (step) {
    case 'neighborhood':
      data = appData.home.neighborhood[`${neighborhood}`]
      break
    case 'house':
      data = appData.home.neighborhood[`${neighborhood}`].house[`${house}`]
      break
    case 'room':
      data =
        appData.home.neighborhood[`${neighborhood}`].house[`${house}`].apartment[`${apartment}`].room[`${room}`]
      break
    default:
      data = appData.home
      break
  }

  return data
}

export const getStep = (params) => {
  const { neighborhood, house, room } = params
  let step = 'home'

  if (room) step = 'room'
  if (house) step = 'house'
  if (neighborhood) step = 'neighborhood'

  return step
}

export const getApartmentData = (stepData, params) => {
  const { apartment } = params
  const targetApartment = stepData.components.apartmentSelector.apartments.find((a) => a.key === apartment)

  return targetApartment
}

export const debounce =
  (fn, delay = 350, timeout = 3500) =>
  (args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn(args), delay)
  }

export const setCookie = (name = 'tutorialViewed') => {
  if (!checkCookieExists(name)) {
    document.cookie = `${name}=true;expires=;path=/`
  }
}

export const checkCookieExists = (name = 'tutorialViewed') => {
  if (document.cookie.split(';').some((item) => item.trim().startsWith(`${name}=`))) {
    return true
  }
  return false
}

export const handleResize = (container) => {
  if (!container) return
  const width = window.innerWidth
  const height = window.innerHeight
  const landscape = 16 / 9
  const portrait = 9 / 16
  const floatingContainerWidth = 2 * 0.1 * landscape + Math.max(height * landscape, width)
  const floatingContainerHeight = 2 * 0.1 + Math.max(width * portrait, height)

  container.current.style.width = `${floatingContainerWidth}px`
  container.current.style.height = `${floatingContainerHeight}px`
}
