import styled from 'styled-components/macro'
import { resetButton } from '../../Button/styled'

export const StyledMenuBurger = styled.button`
  ${resetButton}
  flex: 0 0 auto;

  max-height: 50%;
  margin-right: ${({ theme }) => theme.rem(16)};
  padding: ${({ theme }) => theme.rem(8)};
`

export const StyledMenuBurgerContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width:  ${({ theme }) => theme.rem(20)};
  height:  ${({ theme }) => theme.rem(14)};
`

export const StyledMenuBurgerWrapper = styled.div`
  position: relative;

  width: ${({ theme, isOpen }) => isOpen ? theme.rem(10) : theme.rem(20)};
  height:  ${({ theme, isOpen }) =>  isOpen ? theme.rem(10) : theme.rem(14)};

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;

    opacity: ${({ isOpen }) => isOpen ? 1 : 0};

    width: ${({ theme }) => theme.rem(24)};
    height: ${({ theme }) => theme.rem(24)};

    content: "";
    transition: opacity 0.2s;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.gray2};
  }
`

export const StyledMenuBurgerLine = styled.span`
  display: inline-block;
  position: absolute;

  width: 100%;
  height: 2px;

  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  background-color: ${({ theme }) => theme.colors.black};

  .Menu[data-state="open"] & {
    height: 1px;
  }

  &.line1 {
    top: ${({ isOpen }) => isOpen ? '50%' : 0};
    left: 0;

    ${({ isOpen }) => isOpen && 'transform: rotate(-45deg);'};
  }

  &.line2 {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity:  ${({ isOpen }) => isOpen ? 0 : 1};
  }

  &.line3 {
    ${({ isOpen }) => isOpen && 'top:50%;'};
    bottom: 0;
    left: 0;
    ${({ isOpen }) => isOpen && 'transform: rotate(45deg);'};
  }

`
