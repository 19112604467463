import React from 'react'

export default () => (
  <svg viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="32" fill="#CBD4DA" fillOpacity="0.4"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M49.5385 37.3811C49.5385 35.2719 49.0041 33.4174 47.2202 31.832C46.9187 31.5641 46.848 31.1102 47.0862 30.7846C47.3289 30.4529 47.7989 30.3819 48.1089 30.6518C50.33 32.5855 51 34.9082 51 37.3811C51 40.3513 49.3095 42.9889 46.5407 44.9731C43.7727 46.9568 39.8714 48.337 35.2538 48.8299C34.8524 48.8727 34.4928 48.5775 34.4505 48.1706C34.4083 47.7636 34.6994 47.3989 35.1008 47.3561C39.5383 46.8825 43.183 45.5646 45.6972 43.7629C48.2106 41.9617 49.5385 39.7265 49.5385 37.3811ZM16.5048 30.9333C16.7706 31.2413 16.7398 31.7094 16.4361 31.9789C14.768 33.4589 14.4615 35.2018 14.4615 37.3813C14.4615 40.0697 16.0659 42.5807 19.023 44.4547C21.9294 46.2966 26.0826 47.4731 31.0291 47.5123L29.6099 46.0732C29.3273 45.7867 29.3273 45.3264 29.6099 45.0399C29.8981 44.7477 30.3695 44.7477 30.6577 45.0399L33.3111 47.7304C33.4481 47.8693 33.5251 48.0578 33.5251 48.2543C33.5251 48.4508 33.4481 48.6393 33.311 48.7783L30.6577 51.4686C30.3695 51.7608 29.898 51.7608 29.6099 51.4686C29.3273 51.1821 29.3273 50.7217 29.6099 50.4352L31.031 48.9943C25.8725 48.9554 21.4353 47.731 18.2484 45.7114C15.0098 43.659 13 40.7335 13 37.3813C13 35.0505 13.329 32.7664 15.4736 30.8636C15.7774 30.5942 16.239 30.6254 16.5048 30.9333Z" fill="#72989F"/>
    <path d="M44.8711 19.2984C44.8088 19.1743 44.7111 19.0723 44.5909 19.0061L31.7033 12.0798C31.6058 12.0274 31.4973 12 31.3872 12C31.277 12 31.1686 12.0274 31.0711 12.0798L18.1835 19.0061C18.0635 19.0724 17.9659 19.174 17.9033 19.2977L31.3872 26.5455L44.8711 19.2984Z" fill="#A4CAD1"/>
    <path d="M31.3872 26.2655L31.3872 42.3037C31.4967 42.3038 31.6045 42.2774 31.7014 42.2269L44.5111 35.5443C44.6197 35.4875 44.7107 35.4025 44.7741 35.2982C44.8376 35.194 44.8711 35.0746 44.8711 34.9529V19.5828C44.8699 19.4746 44.8419 19.3684 44.7895 19.2734L31.3872 26.2655Z" fill="#72989F"/>
    <path d="M17.9849 19.2734C17.9327 19.3687 17.9047 19.4751 17.9033 19.5835L17.9033 34.9531C17.9033 35.0748 17.9368 35.1942 18.0003 35.2985C18.0637 35.4027 18.1547 35.4877 18.2633 35.5445L31.073 42.2269C31.1699 42.2774 31.2777 42.3038 31.3872 42.3037L31.3872 26.2659L17.9849 19.2734Z" fill="#90B6BD"/>
  </svg>
)
