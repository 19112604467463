import { createGlobalStyle } from 'styled-components'

import lightWoff from './fonts/ProximaNova/ProximaNova-Light.woff'
import lightWoff2 from './fonts/ProximaNova/ProximaNova-Light.woff2'
import regularWoff from './fonts/ProximaNova/ProximaNova-Regular.woff'
import regularWoff2 from './fonts/ProximaNova/ProximaNova-Regular.woff2'
import semiboldWoff from './fonts/ProximaNova/ProximaNova-Semibold.woff'
import semiboldWoff2 from './fonts/ProximaNova/ProximaNova-Semibold.woff2'
import boldWoff from './fonts/ProximaNova/ProximaNova-Bold.woff'
import boldWoff2 from './fonts/ProximaNova/ProximaNova-Bold.woff2'

const FontStyles = createGlobalStyle`

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url(${lightWoff}) format('woff2'),
        url(${lightWoff2}) format('woff');
  };

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url(${regularWoff}) format('woff2'),
        url(${regularWoff2}) format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url(${semiboldWoff}) format('woff2'),
        url(${semiboldWoff2}) format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url(${boldWoff}) format('woff2'),
        url(${boldWoff2}) format('woff');
  }
`

export default FontStyles
