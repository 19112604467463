import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'
import { resetButton } from '../Button/styled'
import { Paragraph, ButtonLabel } from '../Typography'

export const StyledMenuDropdown = styled.div`
  position: relative;
  z-index: 2;
`
export const StyledMenuDropdownIconButton = styled.button`
  ${resetButton}
  padding: ${({ theme }) => theme.rem(16)};
  i {
    font-size: ${({ theme }) => theme.rem(20)};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary.default};
  }

  ${({ theme }) => theme.mediaQuery.tablet(`
    margin-left: ${theme.rem(12)};
  `)}

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding: ${theme.rem(8)};
  `)}
`

export const StyledMenuDropdownButton = styled.button`
  ${resetButton}
  display: inline-flex;

  justify-content: space-between;
  align-items: center;

  max-width: ${styledMap`
    full: none;
    default: ${({ theme }) => theme.rem(116)};
  `};
  width: ${styledMap`
    full: 100%;
    default: auto;
  `};
  padding: ${({ theme }) => theme.rem(14)};

  transition: background-color 0.2s;

  border-radius: ${({ theme }) => theme.rem(52)};
  background-color: rgba(164, 202, 209, 0.4);

  ${ButtonLabel} {
    margin-left: ${({ theme }) => theme.rem(12)};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white };
    background-color: ${({ theme }) => theme.colors.secondary.light };
    ${ButtonLabel} {
      color: ${({ theme }) => theme.colors.white };
    }
  }

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding: ${theme.rem(8)};
    ${ButtonLabel} {
      margin-left: 0;
    }
  `)}

  ${({ theme }) => theme.mediaQuery.tablet(`
    margin-left: ${theme.rem(12)};
  `)}
`

export const StyledMenuDropdownButtonIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 40%;
`

export const StyledMenuDropdownButtonLabel = styled.div`
  flex: 0 0 60%;
`

export const StyledMenuDropdownContent = styled.div`
  position: absolute;
  ${({ position }) => position === 'right' ? `right: 0;` : `left: 0;`}
  overflow: hidden;

  width:  ${({ theme }) => theme.rem(210)};
  height: auto;
  max-height:  ${({ theme }) => theme.rem(343)};
  margin-top:  ${({ theme }) => theme.rem(24)};
  transition: opacity 0.3s, visibility 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.secondary.light };
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white };
  box-shadow: 0 0 10px rgba(144, 182, 189, 0.4);
`

export const StyledMenuDropdownHead = styled.div`
  padding: ${({ theme }) => theme.rem(16)} 0;
  text-align: center;
  background-color: rgba(144, 182, 189, 0.4);

  ${Paragraph} {
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const StyledMenuDropdownItems = styled.nav`
  overflow-y: scroll;

  max-height:  ${({ theme }) => theme.rem(296)};
  padding: 0  ${({ theme }) => theme.rem(16)};
`


export const StyledMenuDropdownItemLink = styled.a`
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding: ${({ theme }) => theme.rem(16)} 0;

  border-bottom: 1px solid rgba(144, 182, 189, 0.2);

  i {
    margin-right: ${({ theme }) => theme.rem(8)};
  }
`


export const StyledMenuDropdownItem = styled.li`
  list-style: none;

  color: ${styledMap`
    active: ${({ theme }) => theme.colors.primary.default };
    default: ${({ theme }) => theme.colors.gray4 };
  `};

  ${({ disabled }) => disabled && css`
    opacity: 0.3;
    ${StyledMenuDropdownItemLink} {
      pointer-events: none;
    }
  `}

  &:hover {
    ${Paragraph} {
      color: ${({ theme, disabled }) => !disabled && theme.colors.primary.default };
    }
    i {
      color: ${({ theme, disabled }) => !disabled && theme.colors.primary.default };
    }
  }
`
