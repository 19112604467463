import React from 'react'

export default () => (
  <svg viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="32" fill="#CBD4DA" fillOpacity="0.4"/>
    <rect x="13.333" y="23.1113" width="37.3333" height="14" rx="7" fill="#90B6BD"/>
    <rect x="14.2222" y="24" width="18.6667" height="12.4444" rx="6.22222" fill="white"/>
    <path d="M35.8932 44.1203L29.8491 38.1081L34.805 35.9954C34.9039 35.9535 34.9649 35.8536 34.9574 35.7469C34.95 35.6403 34.8751 35.5502 34.7714 35.5223L21.6513 32.0087C21.564 31.9851 21.4709 32.0102 21.407 32.0737C21.3432 32.1372 21.3182 32.2298 21.3417 32.3164L24.874 45.3669C24.902 45.4703 24.9926 45.5446 25.0998 45.5521C25.2101 45.5581 25.3075 45.4989 25.3496 45.4005L27.4736 40.4707L33.5179 46.483C33.5671 46.5319 33.6317 46.5565 33.6963 46.5565C33.7609 46.5565 33.8255 46.5319 33.8747 46.483L35.8932 44.4752C35.9918 44.3771 35.9918 44.2184 35.8932 44.1203Z" fill="#72989F"/>
  </svg>
)
