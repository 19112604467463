import React, { useContext } from 'react'
import { StyledInfopointTooltip } from './styled'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'
import { Link } from '../'
import { DataLayerContext } from '../../contexts/DataLayer'

/**
 * @param {object} param0
 * @param {boolean} param0.preventGtLog Set to true to prevent logging to the GTM instance when clicking on the tooltip link
 * @returns
 */
const Tooltip = ({ show, title = '', text = '', link = { url: '', label: '' }, preventGtmLog }) => {
  const { pushToDataLayer } = useContext(DataLayerContext)
  const handleLinkClick = (ev) => {
		if (preventGtmLog) {
			return
		}
		
    ev.stopPropagation()
    pushToDataLayer?.({
      eventAction: title,
    })
  }

  return (
    <StyledInfopointTooltip show={show}>
      <Heading as='h6' bold>
        {title}
      </Heading>
      <Spacer xs={1} />
      <Paragraph small>{text}</Paragraph>
      <Spacer xs={2} />
      <Link label={link.label} to={link.url} primary small={1} onClick={(event) => {
        handleLinkClick(event)
      }} />
    </StyledInfopointTooltip>
  )
}

export default Tooltip
