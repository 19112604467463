import styled, { css } from 'styled-components/macro'

export const StyledApartmentSelector = styled.div`
    width: 100%;
    /* height: calc(50vh - ${({ theme }) => theme.rem(80)}); */
    /* height: calc(100vh - ${({ theme }) => theme.rem(80)}); */
    height: calc(55vh - ${({ theme }) => theme.rem(80)});

    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(100%);
    z-index: 1;

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 30vw;
        height: calc(100vh - ${theme.rem(80)});
        display: flex;
        flex-wrap: wrap;
        left: auto;
        right: 0;
        transform: translateX(100%);
        z-index: 20;
    `)}
`

export const StyledApartmentSelectorSliderWrapper = styled.div`
    width: 100vw;
    height: calc(55vh - ${({ theme }) => theme.rem(80)});
    padding: ${({ theme }) => theme.rem(24, 32, 32)};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.rem(8, 8, 0, 0)};
    position: absolute;
    left: 0;
    bottom: 0;

    overflow: scroll;
    
    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 30vw;
        height: calc(100vh - ${theme.rem(80)});
        padding: ${theme.rem(96, 56)};
        border-radius: 0;
        left: auto;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `)}

    .swiper,
    .swiper-wrapper,
    .swiper-slide {
        height: 100%;
    }

    .swiper-pagination {

        ${({ theme }) => theme.mediaQuery.desktop(`
            bottom: ${theme.rem(32)};
        `)}
    }

    .swiper-pagination-bullet {
        margin: 0 ${({ theme }) => theme.rem(8)}!important;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            width: ${({ theme }) => theme.rem(8)};
            height: ${({ theme }) => theme.rem(8)};
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid transparent;
            border-radius: 50%;
            background-color: transparent;
            z-index: -1;
        }
    }

    .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.colors.primary.default};
        
        &:after {
            border: 1px solid ${({ theme }) => theme.colors.primary.default};
            animation: dot infinite 1.2s ease-out;
        }
    }

    @keyframes dot {
        0% {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
        100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(4);
        }
    }
`

export const StyledApartmentSelectorSliderContent = styled.div`
    height: ${({ theme }) => theme.rem(240)};
    opacity: 0;

    ${({ theme }) => theme.mediaQuery.desktop(`
        height: auto;
    `)}
`

export const StyledApartmentSelectorInfopointWrapper = styled.div`
    height: 46vh;
    position: relative;
    opacity: 0;

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 70vw;
        height: 100%;
    `)}
`

export const StyledApartmentSelectorRevealImage = styled.img`
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity ${({ theme }) => theme.defaults.fastTransition};

    ${({ show }) => show && css`opacity: 1;`}

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: 100%;
        height: 100vh;
    `)}
`

export const StyledApartmentSelectorSlide = styled.div`

`

export const StyledApartmentSelectorSlideButton = styled.div`

    button {
        width: 100%;
    }
`

export const StyledApartmentSelectorSlideList = styled.ul`
    margin-bottom: ${({ theme }) => theme.rem(16)};
`

export const StyledApartmentSelectorSlideListItem = styled.li`
    padding: ${({ theme }) => theme.rem(12, 0)};
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};

    div {
        display: flex;
        align-items: center;

        i {
            margin-right: ${({ theme }) => theme.rem(8)};
            padding-bottom: ${({ theme }) => theme.rem(4)};
        }
    }
`

export const StyledApartmentSelectorSliderSlides = styled.div`
    text-align: center;
`

export const StyledApartmentSelectorSmallImage = styled.img`
    object-fit: cover;
    width: 100%;
    aspect-ratio: 16 / 9;
    margin-bottom: ${({ theme }) => theme.rem(32)};
`

export const StyledApartmentSelectorSliderCommands = styled.div`
    width: 100%;
    padding: ${({ theme }) => theme.rem(0, 56)};
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: ${({ theme }) => theme.rem(40)};
    right: 0;
`

export const StyledApartmentSelectorSliderButton = styled.div`
    width: ${({ theme }) => theme.rem(32)};
    height: ${({ theme }) => theme.rem(24)};
    border-radius: ${({ theme }) => theme.rem(24)};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.commons.boxShadow};
    color: ${({ theme }) => theme.colors.primary.default};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const StyledApartmentSelectorSliderButtonPrev = styled(StyledApartmentSelectorSliderButton)`
    margin-right: ${({ theme }) => theme.rem(16)};
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}

    i {
        transform: scaleX(-1);
    }
`

export const StyledApartmentSelectorSliderButtonNext = styled(StyledApartmentSelectorSliderButton)`
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colors.gray3}; pointer-events: none;`}
`
