import React, { useContext } from 'react'
import { StyledInfopoint } from './styled.js'
import { Icon } from '../'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import Tooltip from './Tooltip.jsx'

const Infopoint = ({ x, y, to, tooltip, handleClick, showDetails }) => {
	const navigate = useNavigate()
	const { checkBreakpoint } = useContext(ThemeContext)

	return (
		<StyledInfopoint
			x={x}
			y={y}
			onClick={(e) => {
				if (to) {
					navigate(to)
				} else {
					handleClick()
				}
			}}
			pulse={showDetails}
		>
			<Icon name='plus' />
			<Responsive on={checkBreakpoint.desktop}>
				{tooltip && !to ? <Tooltip show={showDetails} {...tooltip} /> : null}
			</Responsive>
		</StyledInfopoint>
	)
}

export default Infopoint
