import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme, helpers, GlobalStyles, FontStyles } from '../theme'

export default ({ children }) => (
  <ThemeProvider theme={{ ...theme, ...helpers }}>
    <GlobalStyles />
    <FontStyles />
    {children}
  </ThemeProvider>
)
