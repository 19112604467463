import React from 'react'

export default () => (
  <svg viewBox="0 0 72 72" fill="none">
    <circle cx="36" cy="36" r="36" fill="#CBD4DA" fillOpacity=".4"/>
    <path d="M54.28 56a1.713 1.713 0 0 1-1.218-.504l-9.703-9.703a1.722 1.722 0 0 1 2.435-2.434l9.702 9.703A1.722 1.722 0 0 1 54.28 56Z" fill="#72989F"/>
    <path opacity="0.5" d="M32.354 47.848c8.557 0 15.493-6.937 15.493-15.494 0-8.557-6.936-15.493-15.493-15.493s-15.493 6.936-15.493 15.493 6.936 15.494 15.493 15.494Z" fill="#A4CAD1"/>
    <path d="M32.354 48.71a16.354 16.354 0 1 1 16.354-16.355 16.373 16.373 0 0 1-16.354 16.354Zm0-30.989a14.632 14.632 0 1 0 14.632 14.634 14.65 14.65 0 0 0-14.632-14.633Z" fill="#90B6BD"/>
  </svg>
)
