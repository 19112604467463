import React, { useState, useLayoutEffect, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import {
  StyledScene,
  StyledSceneVideo,
  StyledSceneImage,
  StyledSceneButton,
} from './styled.js'
import { TopGradient, BottomGradient } from '../Layout/index.js'
import Plyr from 'plyr'
import Hls from 'hls.js'
import Button from '../Button/index.jsx'
import Spinner from '../Spinner/index.jsx'

const Scene = ({experience, setExperience, setNewPage, playedCallback, data, homepage, startExperience, introPlayed = false}) => {
  const [ isLoading, setIsLoading ] = useState(false)
  const location = useLocation()
  const params = useParams()
  const videoRef = useRef(null)
  const player = useRef(null)
  const imageRef = useRef(null)
  const hls = new Hls()
  const { playing, played, loading } = experience
  const { assets } = data
  const imagePost = assets.image.post
  const imagePre = assets.image.pre
  let image

  // console.log({data})

  if (played || introPlayed) {
    image = imagePost
  } else if (playing) {
    image = imagePre
  } else {
    image = imagePre
  }

  const handleSkipVideo = () => {
    setExperience(prevState => ({
      ...prevState,
      playing: false,
      played: true
    }))
    setNewPage(false)
  }

  useLayoutEffect(() => {
    player.current = new Plyr(videoRef.current)

    return () => {
      player.current.destroy()
    }    
  }, [])

  useLayoutEffect(() => {
    if (assets.video && !playing && !played) {
      if (Hls.isSupported()) {
        const hlsSource = assets.video.enter
        player.current.poster = image
    
        hls.loadSource(hlsSource)
        hls.attachMedia(videoRef.current)
    
        hls.startLevel = 4
    
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
            console.debug('MANIFEST_PARSED')
        })

        player.current.on('loadstart', () => {
          if (homepage) return
          setIsLoading(true)
        })

        player.current.on('canplay', function (event) {
          setIsLoading(false)
          if (!homepage || startExperience) {
            player.current.play()
          }
        })
    
        player.current.on('playing', () => {
          setExperience(prevState => ({
            ...prevState,
            playing: true,
          }))
        })
    
        player.current.on('ended', () => {
          setExperience(prevState => ({
            ...prevState,
            playing: false,
            played: true
          }))
          setNewPage(false)
          if (typeof playedCallback === 'function') playedCallback()
        })

      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegURL')) {
        // const newSource = document.createElement('source')
        // newSource.src = assets.video.enter
        // newSource.type = 'application/vnd.apple.mpegURL'
        const hasSource = videoRef.current.querySelector('source')
        // if (!hasSource) videoRef.current.appendChild(newSource)
        videoRef.current.src = assets.video.enter

        player.current.on('loadstart', () => {
          if (homepage) return
          setIsLoading(true)
        })
        
        player.current.on('loadedmetadata', function (event) {
          setIsLoading(false)
          if (!homepage || startExperience) {
            player.current.play()
          }
        })

        player.current.on('playing', () => {
          setExperience(prevState => ({
            ...prevState,
            playing: true
          }))
          console.info('now is playing')
        })
    
        player.current.on('ended', () => {
          setExperience(prevState => ({
            ...prevState,
            playing: false,
            played: true
          }))
          setNewPage(false)
          if (typeof playedCallback === 'function') playedCallback()
        })
      }
  
      
    }
  }, [experience, startExperience])

  return (
    <StyledScene>
      <StyledSceneVideo
        muted
        ref={videoRef}
        playsInline
      />
      <StyledSceneImage src={image} hide={playing} ref={imageRef} played={played} homepage={homepage} />
      <TopGradient src="/assets/images/top-gradient.png" />
      <BottomGradient src="/assets/images/bottom-gradient.png" />
      {/* <StyledSceneButton playing={playing}>
        <Button small label="SKIP" onClick={() => handleSkipVideo()} />
      </StyledSceneButton> */}
      { isLoading ? (
        <Spinner center />
      ) : null }
    </StyledScene>
  )
}

export default Scene
