import styled, { css } from 'styled-components/macro'

export const StyledSystemModal = styled.div`
    /* width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50; */
    /* opacity: 0;
    visibility: hidden; */
    /* transition: all ${({ theme }) => theme.defaults.baseTransition}; */
    /* transition: all .2s ease-out .2s; */

    /* ${({ isVisible }) => isVisible && css`
        opacity: 1;
        visibility: visible;
    `} */
`

export const StyledSystemModalBackdrop = styled.div`
    /* width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: .8; */
`   

export const StyledSystemModalContent = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    z-index: 10;
    border-radius: ${({ theme }) => theme.rem(4)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(370)};
    `)}
`

export const StyledSystemModalClose = styled.div`
    position: absolute;
    right: 0;
    top: ${({ theme }) => theme.rem(-40)};
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    transition: color ${({ theme }) => theme.defaults.baseTransition};

    &:hover {
      color: ${({ theme }) => theme.colors.primary.default};
    }

    i {
        font-size: ${({ theme }) => theme.rem(24)};
    }
`

export const StyledSystemModalWrapper = styled.div`
    padding: ${({ theme }) => theme.rem(0, 16)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(0, 24)};
    `)}
`

export const StyledSystemModalImage = styled.img`
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    margin-bottom: ${({ theme }) => theme.rem(16)};
    object-fit: contain;
    object-position: center;
`

export const StyledSystemModalPretitle = styled.div`
    margin-bottom: ${({ theme }) => theme.rem(16)};
    color: ${({ theme }) => theme.colors.gray4};
    text-transform: uppercase;

    h6 {
        display: flex;
        align-items: center;
    }
    
    i {
        margin-bottom: ${({ theme }) => theme.rem(4)};
        margin-right: ${({ theme }) => theme.rem(4)};
    }
`

export const StyledSystemModalFooter = styled.div`
    padding: ${({ theme }) => theme.rem(24, 16)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: ${({ theme }) => theme.commons.boxShadow};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(24)};
    `)}
`

export const StyledSystemModalFooterItem = styled.div`
    &:last-child {
        display: flex;
        justify-content: center;
    }

    a {
        &:first-child {
            margin-right: ${({ theme }) => theme.rem(16)};
        }
    }
`
