import React from 'react'
import Error404 from './Error404'
import Header from '../components/Header/empty'
import { Main } from '../components/Layout'
import ErrorPage from '../fixtures/ErrorPage'

const ErrorPageLayout = () => {
  return (
    <>
      <Header {...ErrorPage.header} />
      <Main>
        <Error404 {...ErrorPage.error} />
      </Main>
    </>
  )
}

export default ErrorPageLayout
