import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledMenuBurger,
  StyledMenuBurgerContainer,
  StyledMenuBurgerWrapper,
  StyledMenuBurgerLine
  } from './styled'

const MenuBurger = ({ isOpen, onMenuClick }) => {
  return (
    <StyledMenuBurger onClick={onMenuClick}>
      <StyledMenuBurgerContainer>
        <StyledMenuBurgerWrapper isOpen={isOpen}>
          <StyledMenuBurgerLine className="line1" isOpen={isOpen}/>
          <StyledMenuBurgerLine className="line2" isOpen={isOpen}/>
          <StyledMenuBurgerLine className="line3" isOpen={isOpen}/>
        </StyledMenuBurgerWrapper>
      </StyledMenuBurgerContainer>
    </StyledMenuBurger>
  )
}

MenuBurger.propTypes = {
  isOpen: PropTypes.bool,
  onMenuClick: PropTypes.func
}

export default MenuBurger
