import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useNavigate, matchPath, useResolvedPath } from "react-router-dom"
import { GtmContext } from "../../contexts/Gtm"
import { routesConfig } from "../../routes/config"
import { StyledLink } from "./styled"

const TrackedLink = ({ to, children, onClick, ...props }) => {
  const { pushPageLanding } = useContext(GtmContext)
  const resolvedPath = useResolvedPath(to)
  const navigate = useNavigate()

  const handleLinkClick = (evt) => {
    evt.preventDefault()

    const pageType = routesConfig
      ?.find(r => !!matchPath(`/:lang${r.path}`, resolvedPath.pathname))
      ?.pageType
    pushPageLanding && pushPageLanding({ pageType })

    if (typeof onClick === 'function') {
      onClick(evt)
    }

    navigate(to)
  }

  return (
    <StyledLink to={to || "#"} {...props} onClick={handleLinkClick}>
      { children }
    </StyledLink>
  )
}

TrackedLink.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
}

export default TrackedLink
