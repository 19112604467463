import React from 'react'

export default () => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.7668 22.6691H13.8335C13.6318 22.6691 13.4668 22.5041 13.4668 22.3025V17.5358H10.5334V22.3025C10.5334 22.5041 10.3684 22.6691 10.1668 22.6691H7.2334C7.03173 22.6691 6.86673 22.5041 6.86673 22.3025V15.3358H5.03337C4.8867 15.3358 4.7492 15.2441 4.6942 15.1066C4.6392 14.9691 4.67587 14.8041 4.78587 14.7033L11.7526 8.1033C11.8901 7.9658 12.1193 7.9658 12.2568 8.1033L19.2235 14.7033C19.3335 14.8041 19.3702 14.9691 19.3152 15.1066C19.2602 15.2441 19.1227 15.3358 18.976 15.3358H17.1427V22.3025C17.1335 22.5041 16.9685 22.6691 16.7668 22.6691ZM14.2001 21.9358H16.4002V14.9691C16.4002 14.7675 16.5652 14.6025 16.7668 14.6025H18.0502L12.0001 8.8733L5.95005 14.6025H7.2334C7.43507 14.6025 7.60007 14.7675 7.60007 14.9691V21.9358H9.80009V17.1691C9.80009 16.9675 9.9651 16.8025 10.1668 16.8025H13.8335C14.0351 16.8025 14.2001 16.9675 14.2001 17.1691V21.9358Z" fill="#858E94"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M10.3314 5.04239C10.8604 4.82324 11.4275 4.71045 12.0001 4.71045C12.5727 4.71045 13.1398 4.82324 13.6688 5.04239C14.1979 5.26153 14.6786 5.58274 15.0835 5.98767C15.2085 6.11264 15.2085 6.31525 15.0835 6.44021C14.9585 6.56518 14.7559 6.56517 14.6309 6.4402C14.2855 6.09471 13.8753 5.82065 13.4239 5.63367C12.9725 5.44669 12.4887 5.35045 12.0001 5.35045C11.5115 5.35045 11.0277 5.44669 10.5763 5.63367C10.1249 5.82065 9.71474 6.09471 9.36927 6.4402C9.2443 6.56517 9.04168 6.56518 8.91671 6.44021C8.79174 6.31525 8.79173 6.11264 8.9167 5.98767C9.32161 5.58274 9.80231 5.26153 10.3314 5.04239Z" fill="#858E94"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M9.17157 2.24284C10.0683 1.87137 11.0295 1.68018 12.0001 1.68018C12.9707 1.68018 13.9319 1.87137 14.8286 2.24284C15.7254 2.6143 16.5402 3.15877 17.2265 3.84514C17.3514 3.97011 17.3514 4.17273 17.2264 4.29769C17.1015 4.42265 16.8989 4.42264 16.7739 4.29767C16.147 3.67073 15.4028 3.17341 14.5837 2.83411C13.7646 2.49481 12.8867 2.32018 12.0001 2.32018C11.1135 2.32018 10.2356 2.49481 9.41651 2.83411C8.59742 3.17341 7.85318 3.67073 7.2263 4.29767C7.10134 4.42264 6.89872 4.42265 6.77375 4.29769C6.64877 4.17273 6.64876 3.97011 6.77373 3.84514C7.46004 3.15877 8.27483 2.6143 9.17157 2.24284Z" fill="#858E94"/>
  <path d="M21.1669 19.0026H18.2335C18.0319 19.0026 17.8669 18.8376 17.8669 18.636C17.8669 18.4343 18.0319 18.2693 18.2335 18.2693H20.8002V13.5026C20.8002 13.301 20.9652 13.136 21.1669 13.136H21.7444L17.8669 9.25848L16.2627 10.8626C16.116 11.0093 15.8868 11.0093 15.7402 10.8626C15.5935 10.716 15.5935 10.4868 15.7402 10.3401L17.601 8.47932C17.7477 8.33265 17.9769 8.33265 18.1235 8.47932L22.8903 13.246C22.9911 13.3468 23.0278 13.5118 22.9728 13.6493C22.9178 13.7868 22.7803 13.8785 22.6336 13.8785H21.5336V18.6451C21.5336 18.8376 21.3686 19.0026 21.1669 19.0026Z" fill="#858E94"/>
  <path d="M5.76672 19.0026H2.83336C2.63169 19.0026 2.46668 18.8376 2.46668 18.636V13.8693H1.36667C1.22 13.8693 1.0825 13.7776 1.0275 13.6402C0.9725 13.5027 1 13.3468 1.11 13.246L5.87672 8.47932C6.02339 8.33265 6.25256 8.33265 6.39923 8.47932L8.26009 10.3401C8.40675 10.4868 8.40675 10.716 8.26009 10.8626C8.11342 11.0093 7.88425 11.0093 7.73758 10.8626L6.13339 9.25848L2.25585 13.136H2.83336C3.03502 13.136 3.20003 13.301 3.20003 13.5026V18.2693H5.76672C5.96839 18.2693 6.13339 18.4343 6.13339 18.636C6.13339 18.8376 5.96839 19.0026 5.76672 19.0026Z" fill="#858E94"/>
</svg>
)
