import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  StyledMenuDropdown,
  StyledMenuDropdownContent,
  StyledMenuDropdownHead,
  StyledMenuDropdownItems,
} from './styled'
import Icon from '../Icon'
import MenuDropdownItem from './MenuDropdownItem'
import MenuDropdownButton from './MenuDropdownButton'
import { Paragraph } from '../Typography'
import useOutsideTarget from '../../hooks/useOutsideTarget'
import { useTranslation } from 'react-i18next'

const MenuDropdown = ({ label = '', icon, position, asButton, content }) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const isOutside = useOutsideTarget(ref)
  const { head, items } = content
  const { t } = useTranslation()

  const toggleContent = () => setOpen(!isOpen)

  useEffect(() => {
    if (isOutside && isOpen) toggleContent()
  }, [isOutside])

  return (
    <StyledMenuDropdown ref={ref}>
      <MenuDropdownButton
        icon={icon}
        label={t(label.toLowerCase())}
        asButton={asButton}
        onButtonClick={toggleContent}
      />
      {
        isOpen &&
          <StyledMenuDropdownContent position={position}>
            {
              head && head.label &&
              <StyledMenuDropdownHead>
                <Paragraph small>{t(head.label.toLowerCase())}</Paragraph>
              </StyledMenuDropdownHead>
            }
            <StyledMenuDropdownItems>
              <ul>
                {
                  items.map((item, i) => (
                    <MenuDropdownItem key={i} {...item}/>
                  ))
                }
              </ul>
            </StyledMenuDropdownItems>
          </StyledMenuDropdownContent>
      }
    </StyledMenuDropdown>
  )
}

MenuDropdown.propTypes = {
  label: PropTypes.string,
  position: PropTypes.string,
  asButton: PropTypes.bool,
  content: PropTypes.object,
}

export default MenuDropdown
