export default {
  header: {
    current: "System Showroom",
    logoUrl: "//www.ariston.com/"
  },
  error: {
    cta: {
      url: "https://www.ariston.com/",
      label: "Go to Ariston.com",
      internal: false
    }
  },
  footer: {
    copyright: "©2022 Ariston Holding N.V.",
    links: [
      {
        url: "//www.ariston.com/",
        label: "Go to Ariston.com"
      }
    ],
    socials: []
  }
}
