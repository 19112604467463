import { theme } from '../../theme'

export const primaryTheme = {
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  menu: {
    itemColor: {
      active: theme.colors.primary.default,
      default: theme.colors.gray4,
    },
    itemColorHover: {
      default: theme.colors.primary.default,
    }
  }
}

export const secondaryTheme = {
  backgroundColor: theme.colors.gray5,
  color: theme.colors.white,
  menu: {
    itemColor: {
      active: theme.colors.white,
      default: theme.colors.gray3,
    },
    itemColorHover: {
      default: theme.colors.white,
    }
  }
}
