import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'
import { paddedElementStyling } from '../Layout'

const helpers = css`
  font-weight: ${styledMap`
    semiBold: 600;
    bold: 700;
    default: 400;
  `};
  text-align: ${styledMap`
    center: center;
    right: right;
    default: inherit;
  `};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;' };
`

const base = css`
  ${helpers}
  font-family: ${({ theme }) => theme.fonts.default };
  font-style: normal;
`

const baseHeadingStyle = css`
  ${base}
  letter-spacing: 2px;
  line-height: 1.2;
  color: ${styledMap`
    primary: ${({ theme }) => theme.colors.primary.default };
    tertiary: ${({ theme }) => theme.colors.tertiary.default };
    gray3: ${({ theme }) => theme.colors.gray3 };
    gray4: ${({ theme }) => theme.colors.gray4 };
    gray5: ${({ theme }) => theme.colors.gray5 };
    black: ${({ theme }) => theme.colors.black };
    default: inherit;
  `};
`

export const Heading = styled.h1`
  ${baseHeadingStyle}

  ${({ gutter }) => gutter && paddedElementStyling}
  ${({ theme, as }) => (theme.typo(as || 'h1'))};
  ${({ toUpper }) => toUpper && css`text-transform: uppercase;`}

  pointer-events: none;
  touch-action: none;
`

export const Paragraph = styled.p`
  ${base}
  font-size: ${styledMap`
    small: ${({ theme }) => theme.rem(12)};
    regular: ${({ theme }) => theme.rem(16)};
    default: ${({ theme }) => theme.rem(14)};
  `};
  line-height: ${styledMap`
    small: ${({ theme }) => theme.rem(15)};
    regular: ${({ theme }) => theme.rem(22)};
    default: ${({ theme }) => theme.rem(20)};
  `};
  color: ${styledMap`
    primary: ${({ theme }) => theme.colors.primary.default };
    gray3: ${({ theme }) => theme.colors.gray3 };
    gray4: ${({ theme }) => theme.colors.gray4 };
    gray5: ${({ theme }) => theme.colors.gray5 };
    default: inherit;
  `};
  ${({ grow }) => grow && `flex-grow: 1`};

  pointer-events: none;
  touch-action: none;
`


export const Span = styled.span`
  ${base}
  font-size: ${styledMap`
    small: ${({ theme }) => theme.rem(12)};
    large: ${({ theme }) => theme.rem(16)};
    default: ${({ theme }) => theme.rem(14)};
  `};
  line-height: ${styledMap`
    small: ${({ theme }) => theme.rem(15)};
    large: ${({ theme }) => theme.rem(22)};
    default: ${({ theme }) => theme.rem(20)};
  `};
  color: ${styledMap`
    gray3: ${({ theme }) => theme.colors.gray3 };
    gray4: ${({ theme }) => theme.colors.gray4 };
    gray5: ${({ theme }) => theme.colors.gray5 };
    default: inherit;
  `};

  pointer-events: none;
  touch-action: none;
`

export const LinkLabel = styled.span`
  ${base}
  font-size: ${styledMap`
    small: ${({ theme }) => theme.rem(12)};
    large: ${({ theme }) => theme.rem(16)};
    default: ${({ theme }) => theme.rem(14)};
  `};
  line-height: 1;
  letter-spacing: 0;
  color: ${styledMap`
    gray: ${({ theme }) => theme.colors.gray5 };
    gray4: ${({ theme }) => theme.colors.gray4 };
    default: inherit;
  `};

  pointer-events: none;
  touch-action: none;
`

export const ButtonLabel = styled.span`
  ${base}
  font-size: ${styledMap`
    tiny: ${({ theme }) => theme.rem(10)};
    small: ${({ theme }) => theme.rem(12)};
    large:  ${({ theme }) => theme.rem(16)};
    regular:  ${({ theme }) => theme.rem(14)};
  `};
  text-transform: uppercase;
  line-height: 1.2;
  position: relative;

  pointer-events: none;
  touch-action: none;
`

export const Label = styled.label`
  ${base}
  ${({ gutter }) => gutter && paddedElementStyling}
  font-size: ${styledMap`
    large:  ${({ theme }) => theme.rem(11)};
    regular:  ${({ theme }) => theme.rem(11)};
  `};
  line-height: 1.2;
  text-transform: uppercase;
  color: ${styledMap`
    black: ${({ theme }) => theme.colors.black };
    default: inherit;
  `};

  pointer-events: none;
  touch-action: none;
`
