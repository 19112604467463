import React, { useState, useLayoutEffect, useRef, useContext, useEffect } from 'react'
import { Main, FloatingContainer, Page } from '../Layout'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import SceneBackground from '../Scene/SceneBackground'
import { getStepData, getStep, handleResize } from '../../utils'
import gsap from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from 'gsap/InertiaPlugin'
import getHeader from './../../fixtures/Header'
import Header from './../Header'
import DataLayerProvider, { EVENT_TYPES } from '../../contexts/DataLayer'
import { GtmContext } from '../../contexts/Gtm'

gsap.registerPlugin(Draggable, InertiaPlugin)

const SharedLayout = ({ appData }) => {
    const [experience, setExperience] = useState({
        playing: false,
        played: false,
        loading: false,
        showTooltip: false,
    })
    const [newPage, setNewPage] = useState(false)
    const params = useParams()
    const [stepData, setStepData] = useState(getStepData(getStep(params), appData, params))
    const location = useLocation()
    const navigate = useNavigate()
    const floatingContainer = useRef(null)
    const mainRef = useRef(null)
    const isMobile = window.innerWidth < 768

    const { pushPageLanding } = useContext(GtmContext)

    useLayoutEffect(() => {

        if (params.room) {
            if (experience.played) setNewPage(true)
            setExperience(prevState => ({
                ...prevState,
                played: false,
                showTooltip: false
            }))
            setNewPage(true)
            return
        }

        if (params.house) {
            if (experience.played) setNewPage(true)
            setExperience(prevState => ({
                ...prevState,
                played: false,
                showTooltip: false
            }))
            setNewPage(true)
            return
        }

        if (params.neighborhood) {
            if (experience.played) setNewPage(true)
            setExperience(prevState => ({
                ...prevState,
                played: false,
                showTooltip: false
            }))
            setNewPage(true)
            return
        }

        setExperience(prevState => ({
            ...prevState,
            played: false,
            showTooltip: false
        }))

        setStepData(getStepData(getStep(params), appData, params))
    }, [location.pathname])

    return (
        <Main>
            <DataLayerProvider for={EVENT_TYPES.menu} customBaseConfig={{ eventAction: 'Header' }}>
                <Header {...getHeader(params.lang, navigate)} lang={params.lang} disable={experience.playing} />
            </DataLayerProvider>
            <Outlet
                context={{
                    hide: newPage,
                    experience: experience,
                    setExperience: setExperience,
                    setNewPage: setNewPage,
                    appData: appData,
                    newPage: newPage,
                    logPageLanding: pushPageLanding
                }}
            />
        </Main>
    )
}

export default SharedLayout