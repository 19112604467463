import React from 'react'

export default () => (
  <svg viewBox="0 0 40 40" fill="none">
    <path d="M15.8333 24.2505C15.8333 24.4806 15.6467 24.6671 15.4166 24.6671C15.1865 24.6671 14.9999 24.4806 14.9999 24.2505C14.9999 24.0203 15.1865 23.8338 15.4166 23.8338C15.6467 23.8338 15.8333 24.0203 15.8333 24.2505Z" fill="currentColor"/>
    <path d="M17.5 25.4174C17.5 25.6476 17.3134 25.8341 17.0833 25.8341C16.8532 25.8341 16.6666 25.6476 16.6666 25.4174C16.6666 25.1873 16.8532 25.0008 17.0833 25.0008C17.3134 25.0008 17.5 25.1873 17.5 25.4174Z" fill="currentColor"/>
    <path d="M19.1667 26.2508C19.1667 26.4809 18.9801 26.6674 18.75 26.6674C18.5199 26.6674 18.3333 26.4809 18.3333 26.2508C18.3333 26.0207 18.5199 25.8341 18.75 25.8341C18.9801 25.8341 19.1667 26.0207 19.1667 26.2508Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.2041 4.55702C12.3524 4.45811 12.5404 4.43989 12.705 4.50847L22.7052 8.67514C22.9039 8.75795 23.0334 8.95214 23.0334 9.16745V30.8341C23.0334 31.0251 22.9313 31.2015 22.7657 31.2966C22.6001 31.3917 22.3962 31.391 22.2313 31.2948L12.2312 25.4615C12.0673 25.3659 11.9666 25.1905 11.9666 25.0008V5.00078C11.9666 4.82246 12.0557 4.65594 12.2041 4.55702ZM13.0332 5.80078V24.6945L21.9667 29.9056V9.523L13.0332 5.80078Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.7195 17.8804C14.8768 17.7833 15.0731 17.7744 15.2385 17.8571L20.2385 20.3571C20.4192 20.4474 20.5333 20.6321 20.5333 20.8341V24.1674C20.5333 24.3523 20.4376 24.524 20.2804 24.6211C20.1232 24.7183 19.9268 24.7271 19.7615 24.6445L14.7614 22.1445C14.5807 22.0541 14.4666 21.8695 14.4666 21.6674V18.3341C14.4666 18.1493 14.5623 17.9776 14.7195 17.8804ZM15.5333 19.1971V21.3378L19.4667 23.3045V21.1637L15.5333 19.1971Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M33.9104 27.167C33.9104 25.553 33.5106 24.1342 32.1748 22.9217C31.9524 22.7198 31.8998 22.383 32.0744 22.1386C32.2582 21.8812 32.6211 21.8265 32.8567 22.0375C34.503 23.5124 35.0002 25.2827 35.0002 27.167C35.0002 29.436 33.7397 31.4508 31.6752 32.9665C29.6113 34.4818 26.7024 35.5361 23.2594 35.9126C22.9601 35.9453 22.692 35.7199 22.6605 35.409C22.629 35.0981 22.8461 34.8195 23.1453 34.7868C26.4541 34.425 29.1717 33.4183 31.0463 32.042C32.9203 30.6661 33.9104 28.9587 33.9104 27.167ZM9.27978 22.2416C9.47794 22.4769 9.45499 22.8345 9.22852 23.0404C7.98476 24.1709 7.75626 25.5023 7.75626 27.1672C7.75626 29.2208 8.95252 31.1389 11.1574 32.5704C13.3245 33.9774 16.4212 34.8762 20.1094 34.9062L19.0419 33.7972C18.835 33.5822 18.835 33.2421 19.0419 33.0272C19.2603 32.8003 19.6234 32.8003 19.8418 33.0272L21.8109 35.0728C21.9131 35.1789 21.9705 35.3229 21.9705 35.473C21.9705 35.6231 21.9131 35.7671 21.8109 35.8732L19.8418 37.9187C19.6234 38.1455 19.2603 38.1455 19.0419 37.9187C18.835 37.7037 18.835 37.3636 19.0419 37.1486L20.1108 36.0383C16.2646 36.0085 12.9561 35.0732 10.5798 33.5304C8.16503 31.9626 6.6665 29.7279 6.6665 27.1672C6.6665 25.3868 6.91181 23.6419 8.5109 22.1884C8.73737 21.9826 9.08161 22.0064 9.27978 22.2416Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M19.8843 2.81348C19.9834 2.79145 20.0868 2.79818 20.1823 2.83289L29.349 6.16622C29.5598 6.24287 29.7001 6.44318 29.7001 6.66745V27.5008C29.7001 27.7028 29.586 27.8875 29.4053 27.9778L22.7385 31.3111L22.2615 30.3571L28.6334 27.1712V7.04101L19.9639 3.88848L12.6156 5.52141L12.3842 4.48015L19.8843 2.81348Z" fill="currentColor"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3128 8.66807L28.9795 6.16807L29.354 7.16682L22.6873 9.66682L22.3128 8.66807Z" fill="currentColor"/>
  </svg>

)
