import React from 'react'
import { StyledDescriptionBox } from './styled.js'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'

const DescriptionBox = ({title, text, as = 'h5'}) => {

  return (
    <StyledDescriptionBox>
      <Heading as={as} uppercase bold>
        {title}
      </Heading>
      <Spacer xs={1} />
      <Paragraph large>
        {text}
      </Paragraph>
    </StyledDescriptionBox>
  )
}

export default DescriptionBox
