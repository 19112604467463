import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'
import { StyledModal, StyledModalContent } from './styled'

const Modal = ({ open, onCloseRequest, children, fullscreen, ...props }) => {
  const handleKeyDown = e => ['Escape', 'Esc', 27].includes(e.key || e.keyCode) && onCloseRequest(e)

  useEffect(() => {
    if (open && typeof onCloseRequest === 'function' && !fullscreen) {
      window.addEventListener('keydown', handleKeyDown)
    } else {
      window.removeEventListener('keydown', handleKeyDown)
    }

    return () => open && window.removeEventListener('keydown', handleKeyDown)
  }, [open, onCloseRequest])

  return (
    <StyledModal {...props} open={open} onClick={onCloseRequest} fullscreen={fullscreen}>
      <StyledModalContent fullscreen={fullscreen} onClick={e => e.stopPropagation()} {...props}>
        <Icon name="close" huge onClick={onCloseRequest} />
        {children}
      </StyledModalContent>
    </StyledModal>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  onCloseRequest: PropTypes.func
}

export default Modal
