import React, { useState, useEffect, useContext } from 'react'
import {
  StyledSystemModal,
  StyledSystemModalBackdrop,
  StyledSystemModalContent,
  StyledSystemModalClose,
  StyledSystemModalImage,
  StyledSystemModalPretitle,
  StyledSystemModalFooter,
  StyledSystemModalFooterItem,
  StyledSystemModalWrapper,
} from './styled.js'
import { Icon, Spinner, Button } from '../'
import { Spacer } from '../Layout'
import { Heading, Paragraph } from '../Typography'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import DataLayerProvider, { DataLayerContext } from '../../contexts/DataLayer'

const SystemModal = ({ isVisible, system, setSystemModal }) => {
  const [data, setData] = useState(false)
  const { lang } = useParams()
  const { t } = useTranslation()
  const { pushToDataLayer } = useContext(DataLayerContext)

  useEffect(() => {
    if (!system) return
    setData(system)
  }, [system])

  const handleClose = () => {
    setSystemModal({ isVisible: false, system })
    setTimeout(() => {
      setData(false)
    }, 400)
  }

  return (
    <StyledSystemModal isVisible={isVisible}>
      <StyledSystemModalBackdrop onClick={() => handleClose()} />
      {data ? (
        <StyledSystemModalContent>
          <StyledSystemModalImage src={system.image} />
          <StyledSystemModalWrapper>
            <StyledSystemModalPretitle>
              <Heading as={'h6'} bold>
                <Icon name={'map'} /> {t('sistema')}
              </Heading>
            </StyledSystemModalPretitle>
            <Heading as={'h4'} bold>
              {data.title}
            </Heading>
            <Spacer xs={2} />
            <Paragraph bold regular gray5>
              {data.subtitle}
            </Paragraph>
            <Spacer xs={2} />
            <Paragraph>{data.description}</Paragraph>
            <Spacer xs={5} />
          </StyledSystemModalWrapper>
          <StyledSystemModalFooter>
            <StyledSystemModalFooterItem>
              <Paragraph regular bold>
                {t('schema del sistema')}
              </Paragraph>
              <Paragraph regular>{data.file.info}</Paragraph>
            </StyledSystemModalFooterItem>
            <StyledSystemModalFooterItem>
              <Button
                icon={'download'}
                secondary
                href={data.file.url}
                download
                small
                handleClick={() => pushToDataLayer({ eventLabel: 'download' })}
                target={'_blank'}
              />
              <Button
                icon={'view'}
                href={data.file.url}
                target={'_blank'}
                small
                handleClick={() => pushToDataLayer({ eventLabel: 'download' })}
                useIconColor
              />
            </StyledSystemModalFooterItem>
          </StyledSystemModalFooter>
        </StyledSystemModalContent>
      ) : (
        <Spinner />
      )}
    </StyledSystemModal>
  )
}

export default SystemModal
