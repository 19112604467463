import React, { useState, useEffect, useRef, useContext } from 'react'
import { 
  StyledSystemExplorerModal,
  StyledSystemExplorerModalHeader,
  StyledSystemExplorerModalSlider,
  StyledSystemExplorerModalSliderSlide,
  StyledSystemExplorerModalSliderImage,
  StyledSystemExplorerModalSliderCommands,
  StyledSystemExplorerModalSliderButtonPrev,
  StyledSystemExplorerModalSliderButtonNext,
  StyledSystemExplorerModalSliderPagination,
  StyledSystemExplorerModalList,
  StyledSystemExplorerModalListItem,
  StyledSystemExplorerModalListItemImage,
  StyledSystemExplorerModalFooter
 } from './styled.js'
import { Spinner, Icon, Button } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer, BottomGradient } from '../Layout'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getSystemExplorerModalData } from '../../api'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AuthContext } from '../../contexts/Auth.jsx'

const reduceData = (fetchedData, modal) => {
  return modal.reduce((acc, item) => {
    const system = []
    item.system.map(({ id, code = false }) => {
      const fetchedProductInfo = fetchedData.find(({ data }) => data.id === id)

      system.push({
        title: fetchedProductInfo.data.attributes.title,
        image: fetchedProductInfo.data.attributes.gallery[0],
        item_code: fetchedProductInfo.data.attributes.item_code ? fetchedProductInfo.data.attributes.item_code : code,
      })
    })

    return [
      ...acc,
      {
        ...item,
        system
      }
    ]
  }, [])
}

const SystemExplorerModal = ({ modal, closeModal }) => {
  const [ data, setData ] = useState(false)
  const idsToFetch = []
  const [ currentSlideIndex, setCurrentSlideIndex ] = useState(1)
  const swiperRef = useRef(null)
  const { lang, neighborhood, house, apartment} = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { authToken } = useContext(AuthContext)
  
  modal.map(({ system }) => {
    system.map(item => {
      idsToFetch.push(item)
    })
  })

  const config = {
    spaceBetween: 0,
    centeredSlides: true,
    slidesPerView: 3,
  }

  useEffect(() => {
    if (!data) {
      getSystemExplorerModalData(lang, authToken, idsToFetch).then(data => {
        setData(reduceData(data, modal))
      })
    }
  }, [data])

  return data ? (
    <StyledSystemExplorerModal>
      <StyledSystemExplorerModalHeader>
        <Heading as={'h5'} bold uppercase black>
          {t('esplora il sistema')}
        </Heading>
        <Spacer xs={1} />
        <Paragraph gray5>
          {t('seleziona un ambiente per visualizzarlo e per scoprire tutti i dettagli sui prodotti utilizzati')}.
        </Paragraph>
      </StyledSystemExplorerModalHeader>
      <StyledSystemExplorerModalSlider>
        <Swiper 
          ref={swiperRef} 
          onSlideChange={(slide) => setCurrentSlideIndex(slide.activeIndex + 1)}  
          {...config}
        >
          {data.map(({ image, name }, index) => {
            return (
              <SwiperSlide key={index}>
                <StyledSystemExplorerModalSliderSlide>
                  <StyledSystemExplorerModalSliderImage src={image} />
                  <Paragraph small bold>
                    {name}
                  </Paragraph>
                  <BottomGradient src="/assets/images/bottom-gradient.png" />
                </StyledSystemExplorerModalSliderSlide>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </StyledSystemExplorerModalSlider>
      <StyledSystemExplorerModalSliderCommands center={data.length === 1}>
        {data.length > 1 ? (
          <StyledSystemExplorerModalSliderButtonPrev
            onClick={() => swiperRef.current.swiper.slidePrev()}
            isDisabled={currentSlideIndex === 1}
          >
            <Icon name="arrow-small" />
          </StyledSystemExplorerModalSliderButtonPrev>
        ) : null}
        <StyledSystemExplorerModalSliderPagination>
          {data.length > 1 ? (
            <Heading as={'h6'} gray4>
              {currentSlideIndex} / {data.length }
            </Heading>
          ) : null}
          <Heading bold as={'h5'} uppercase black>
            {data[currentSlideIndex - 1].name}
          </Heading>
        </StyledSystemExplorerModalSliderPagination>
        {data.length > 1 ? (
          <StyledSystemExplorerModalSliderButtonNext
            onClick={() => swiperRef.current.swiper.slideNext()}
            isDisabled={currentSlideIndex === data.length}
          >
            <Icon name="arrow-small" />
          </StyledSystemExplorerModalSliderButtonNext>
        ) : null}
      </StyledSystemExplorerModalSliderCommands>
      <StyledSystemExplorerModalList>
          {data[currentSlideIndex - 1].system.map(({ title, image, item_code }, index) => {
          return (
            <StyledSystemExplorerModalListItem key={index}>
              <StyledSystemExplorerModalListItemImage src={`${process.env.CDN_URL}${lang}/${image}`} />
              <Paragraph small semiBold gray5>
                {title}
              </Paragraph>
              <Paragraph small gray5>
                {item_code ? item_code : ''}
              </Paragraph>
            </StyledSystemExplorerModalListItem>
          )
        })}
      </StyledSystemExplorerModalList>
      <StyledSystemExplorerModalFooter>
        <Button small label={`${t('vai a')} ${data[currentSlideIndex - 1].name}`} 
          handleClick={() => {
            closeModal()
            setCurrentSlideIndex(1)
            if (data[currentSlideIndex - 1].url) {
              setTimeout(() => {
                navigate(`/${lang}/${neighborhood}/${house}/${apartment}/${data[currentSlideIndex - 1].url}`)
                setData(false)
              }, 400)
            }
          }} 
        />
      </StyledSystemExplorerModalFooter>
    </StyledSystemExplorerModal>
  ) : <Spinner />
}

export default SystemExplorerModal
