import theme from './config'

export function rem (...values) {
  return values.map(px => `${parseInt(px) / 16}rem`).join(' ')
}

const processCss = (css) => {
  let injectedCss = ''
  if (typeof css === 'string') return css
  for (const [key, value] of Object.entries(css)) {
    let processedValue = value
    if (key === 'font-size' || key === 'line-height') processedValue = rem(value)
    injectedCss += `${key}: ${processedValue}; `
  }

  return injectedCss
}

export function color (key, variant = 'default') {
  if (!theme.colors[key]) return 'inherit'
  return theme.colors[key][variant] ? theme.colors[key][variant] : theme.colors[key]
}

export function spacing (scalar = 1) {
  return rem(Number(scalar) * theme.defaults.size)
}

export function typo (key) {
  const rules = []

  if (theme.typography[key]) {
    for (const [rule, ruleValue] of Object.entries(theme.typography[key])) {
      if (rule === 'mq') {
        for (const [mq, mqValue] of Object.entries(ruleValue)) {
          rules.push(mediaQuery[mq](mqValue))
        }
      } else {
        let processedValue = ruleValue
        if (rule === 'font-size' || rule === 'line-height') processedValue = rem(ruleValue)
        rules.push(`${rule}: ${processedValue};`)
      }
    }

    return [...rules]
  }
}

export const mediaQuery = {
  tablet: function (css) {
    return `
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${processCss(css)}
      }
    `
  },
  desktop: function (css) {
    return `
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        ${processCss(css)}
      }
    `
  },
  wide: function (css) {
    return `
      @media screen and (min-width: ${theme.breakpoints.wide}px) {
        ${processCss(css)}
      }
    `
  }
}

export const checkBreakpoint = {
  tablet: ({ width }) => (width >= theme.breakpoints.tablet),
  desktop: ({ width }) => (width >= theme.breakpoints.desktop),
  wide: ({ width }) => (width >= theme.breakpoints.wide),
  not: {
    tablet: ({ width }) => (width < theme.breakpoints.tablet),
    desktop: ({ width }) => (width < theme.breakpoints.desktop),
    wide: ({ width }) => (width < theme.breakpoints.wide)
  }
}
