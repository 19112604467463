import styled, { css } from 'styled-components'
import styledMap from 'styled-map'

export const resetButton = css`
  overflow: visible;

  width: auto;
  margin: 0;
  padding: 0;

  cursor: pointer;
  text-align: inherit;

  color: inherit;
  border: none;
  background: transparent;

  line-height: normal;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;;
`

export const StyledButton = styled.button`
  ${resetButton}
  display: inline-flex;

  justify-content: center;
  align-items: center;

  transition: background 0.2s;
  text-transform: uppercase;

  background-color: ${styledMap`
    secondary: ${({ theme }) => theme.colors.white};
    tertiary: ${({ theme }) => theme.colors.white};
    quaternary: ${({ theme }) => theme.colors.secondary.default};
    default:  ${({ theme }) => theme.colors.primary.default};
  `};

  color: ${styledMap`
    secondary: ${({ theme }) => theme.colors.primary.default};
    tertiary: ${({ theme }) => theme.colors.primary.default};
    quaternary: ${({ theme }) => theme.colors.white};
    default:  ${({ theme }) => theme.colors.white};
  `};

  border: ${styledMap`
    secondary: 1px solid ${({ theme }) => theme.colors.primary.default};
    tertiary: ${({ theme }) => theme.colors.white};
    quaternary: ${({ theme }) => theme.colors.secondary.default};
    default:  1px solid ${({ theme }) => theme.colors.primary.default};
  `};

  border-radius: 2px;
  padding: ${({ theme }) => theme.rem(16)} ${({ theme }) => theme.rem(24)};

  padding: ${styledMap`
    small: ${({ theme, label, icon }) => theme.rem(label && icon ? 8 : 12)} ${({ theme, label, icon }) => theme.rem(label && icon ? 16 : 12)};
    large:  ${({ theme, label, icon }) => theme.rem(label && icon ? 24 : 32)} ${({ theme }) => theme.rem(32)};
    regular:  ${({ theme, label, icon }) => theme.rem(label && icon ? 16 : 24)} ${({ theme }) => theme.rem(24)};
  `};


  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    &:hover {
      background-color: ${styledMap`
        secondary: ${({ theme }) => theme.colors.primary.default};
        tertiary: ${({ theme }) => theme.colors.primary.default};
        quaternary: ${({ theme }) => theme.colors.secondary.default};
        default:  ${({ theme }) => theme.colors.white};
      `};
      color: ${styledMap`
        secondary: ${({ theme }) => theme.colors.white};
        tertiary: ${({ theme }) => theme.colors.white};
        quaternary: ${({ theme }) => theme.colors.white};
        default:  ${({ theme }) => theme.colors.primary.default};
      `};
    } 
  }



  // increase selector specificity
  && i {
    margin-right: ${({ theme, label, icon }) => label && icon ? theme.rem(8) : 0};
  }

  ${({ disabled }) => disabled && css`
    pointer-events: none;
    opacity: 0.5;
  `}

  ${({ fullWidth }) => fullWidth && css`
    width: 100%;
  `}

`
