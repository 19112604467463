const it = require('./it.json')
const en = require('./en.json')
const pl = require('./pl.json')
const ro = require('./ro.json')
const hu = require('./hu.json')

module.exports = {
  it,
  en,
  pl,
  ro,
  hu
}
