import styled, { css } from 'styled-components/macro'

export const StyledRoomExplorer = styled.div`
    width: 100%;
    /* height: 20vh; */
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border-radius: ${({ theme }) => theme.rem(8, 8, 0, 0)};
    position: absolute;
    transform: translateY(100%);
    bottom: 0;
    
    ${({ theme }) => theme.mediaQuery.desktop(`
        height: auto;
        display: flex;
        padding-bottom: ${theme.rem(60)};
        justify-content: space-between;
        color: ${theme.colors.white};
        background-color: transparent;
        border-radius: 0;
        position: relative;
        bottom: auto;
        transform: none;
    `)}
`

export const StyledRoomExplorerItem = styled.div`
    pointer-events: all;

    &:first-child {
      text-align: center;
      padding: ${({ theme }) => theme.rem(24, 32)};
      
      ${({ theme }) => theme.mediaQuery.desktop(`
        padding: 0;
        text-align: left;
      `)}
    }

    &:last-child {
      padding: ${({ theme }) => theme.rem(24, 32)};
      box-shadow: ${({ theme }) => theme.commons.boxShadow};
      
      ${({ theme }) => theme.mediaQuery.desktop(`
        padding: 0;
        box-shadow: none;      
      `)}

      button {
        width: 100%;
      }
    }

    ${({ theme }) => theme.mediaQuery.desktop(`
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    `)}
`

export const StyledRoomExplorerInfo = styled.div`

`

export const StyledRoomExplorerShowRoomModal = styled.div`
  width: ${({ theme }) => theme.rem(32)};
  height: ${({ theme }) => theme.rem(32)};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.desktop(`
    margin-right: ${theme.rem(16)};
  `)}

  i {
    /* font-size: ${({ theme }) => theme.rem(24)}; */
  }
`

export const StyledRoomExplorerButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.rem(20)};

  button {
    width: ${({ theme }) => theme.rem(150)};
  }
`