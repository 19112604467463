import routes from '../../routes/config'

export default (lang, navigate) => ({
  current: 'Showroom Virtuale',
  actions: [
    {
      type: 'MenuDropdown',
      icon: 'user',
      position: 'right',
      content: {
        head: {
          label: 'Impostazioni Utente',
          icon: 'user'
        },
        items: [
          {
            label: 'Gestisci il tuo profilo',
            url: '#',
            icon: 'settings',
            disabled: true
          },
          {
            label: 'esci',
            onLinkClick: () => {
              navigate(`/${lang}${routes.logout}`)
            },
            icon: 'exit'
          }
        ]
      }
    },
    {
      type: 'MenuDropdown',
      label: 'Cambia Area',
      asButton: true,
      icon: 'menu-app',
      position: 'right',
      content: {
        head: {
          label: 'Seleziona Servizio'
        },
        back: {
          label: 'Torna Indietro'
        },
        items: [],
        isServices: true
      }
    }
  ]
})
