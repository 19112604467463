import React from 'react'

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.50006 14.55C9.50006 14.6881 9.38813 14.8 9.25006 14.8C9.11199 14.8 9.00006 14.6881 9.00006 14.55C9.00006 14.4119 9.11199 14.3 9.25006 14.3C9.38813 14.3 9.50006 14.4119 9.50006 14.55Z" fill="#858E94"/>
    <path d="M10.5001 15.2502C10.5001 15.3882 10.3881 15.5002 10.2501 15.5002C10.112 15.5002 10.0001 15.3882 10.0001 15.2502C10.0001 15.1121 10.112 15.0002 10.2501 15.0002C10.3881 15.0002 10.5001 15.1121 10.5001 15.2502Z" fill="#858E94"/>
    <path d="M11.5001 15.7502C11.5001 15.8882 11.3882 16.0002 11.2501 16.0002C11.112 16.0002 11.0001 15.8882 11.0001 15.7502C11.0001 15.6121 11.112 15.5002 11.2501 15.5002C11.3882 15.5002 11.5001 15.6121 11.5001 15.7502Z" fill="#858E94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.32253 2.73392C7.41156 2.67457 7.52436 2.66364 7.62312 2.70479L13.6232 5.20479C13.7424 5.25448 13.8201 5.37099 13.8201 5.50018V18.5002C13.8201 18.6148 13.7589 18.7206 13.6595 18.7777C13.5601 18.8347 13.4378 18.8343 13.3389 18.7766L7.3388 15.2766C7.24049 15.2192 7.18004 15.114 7.18004 15.0002V3.00018C7.18004 2.89318 7.23351 2.79327 7.32253 2.73392ZM7.82004 3.48018V14.8164L13.1801 17.943V5.71351L7.82004 3.48018Z" fill="#858E94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.83182 10.728C8.92617 10.6697 9.04397 10.6644 9.14317 10.714L12.1432 12.214C12.2516 12.2682 12.3201 12.379 12.3201 12.5002V14.5002C12.3201 14.6111 12.2627 14.7141 12.1683 14.7724C12.074 14.8307 11.9562 14.836 11.857 14.7864L8.85695 13.2864C8.74854 13.2322 8.68006 13.1214 8.68006 13.0002V11.0002C8.68006 10.8893 8.73748 10.7863 8.83182 10.728ZM9.32006 11.5179V12.8024L11.6801 13.9824V12.6979L9.32006 11.5179Z" fill="#858E94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.3463 16.2999C20.3463 15.3315 20.1064 14.4802 19.305 13.7527C19.1715 13.6316 19.14 13.4295 19.2448 13.2828C19.355 13.1284 19.5728 13.0956 19.7141 13.2222C20.7019 14.1072 21.0002 15.1693 21.0002 16.2999C21.0002 17.6613 20.2439 18.8702 19.0052 19.7796C17.7669 20.6888 16.0215 21.3214 13.9557 21.5473C13.7762 21.5669 13.6153 21.4316 13.5964 21.2451C13.5775 21.0586 13.7077 20.8914 13.8873 20.8718C15.8726 20.6547 17.5031 20.0507 18.6279 19.2249C19.7523 18.3994 20.3463 17.3749 20.3463 16.2999ZM5.56796 13.3447C5.68686 13.4858 5.67309 13.7004 5.53721 13.8239C4.79096 14.5022 4.65386 15.3011 4.65386 16.3C4.65386 17.5322 5.37161 18.6831 6.69452 19.542C7.99479 20.3862 9.85281 20.9254 12.0657 20.9434L11.4252 20.278C11.3011 20.149 11.3011 19.945 11.4252 19.816C11.5563 19.6799 11.7741 19.6799 11.9052 19.816L13.0866 21.0434C13.1479 21.1071 13.1824 21.1934 13.1824 21.2835C13.1824 21.3736 13.1479 21.4599 13.0866 21.5236L11.9052 22.7509C11.7741 22.887 11.5563 22.887 11.4252 22.7509C11.3011 22.6219 11.3011 22.4179 11.4252 22.2889L12.0666 21.6227C9.75883 21.6048 7.77375 21.0436 6.34798 20.1179C4.89911 19.1773 4 17.8365 4 16.3C4 15.2318 4.14719 14.1849 5.10664 13.3128C5.24252 13.1892 5.44907 13.2035 5.56796 13.3447Z" fill="#858E94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9307 1.6878C11.9902 1.67458 12.0522 1.67862 12.1095 1.69944L17.6095 3.69944C17.736 3.74543 17.8202 3.86561 17.8202 4.00018V16.5002C17.8202 16.6214 17.7517 16.7322 17.6433 16.7864L13.6432 18.7864L13.357 18.214L17.1802 16.3024V4.22431L11.9784 2.3328L7.56946 3.31256L7.43062 2.6878L11.9307 1.6878Z" fill="#858E94"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M13.3878 5.20055L17.3878 3.70055L17.6125 4.2998L13.6125 5.7998L13.3878 5.20055Z" fill="#858E94"/>
  </svg>
)
