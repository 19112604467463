import React from 'react'
import { useParams, useSearchParams, Navigate } from 'react-router-dom'
import { routes } from '../routes/config'

const Auth = () => {
  const {lang} = useParams()
  const [searchParams] = useSearchParams()

  const token = searchParams.get('token')
  const redirect = searchParams.get('redirect') || `/${lang}${routes.homepage}`

  if (token) {
    localStorage.setItem('authToken', token)
  }

  return (
    <Navigate to={redirect} />
  )
}

export default Auth
