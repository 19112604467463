import React from 'react'

const Spinner = ({center}) => {

  const style = center ? {
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  } : {}

  return (
    <div className="spinner-container" style={style}>
      <div className="spinner" />
    </div>
  )
}

export default Spinner
