import styled, { css } from 'styled-components/macro'

export const StyledRecapModal = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    opacity: 0;
    visibility: hidden;
    /* transition: all ${({ theme }) => theme.defaults.baseTransition}; */
    transition: all ${({ theme }) => theme.defaults.fastTransition};

    ${({ show }) => show && css`
        opacity: 1;
        visibility: visible;
    `}

    ${({ hide }) => hide && css`
        opacity: 0;
        visibility: hidden;
    `}
`

export const StyledRecapModalBackdrop = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: .8;
`

export const StyledRecapModalWrapper = styled.div`
    width: ${({ theme }) => theme.rem(342)};
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    z-index: 10;
    border-radius: ${({ theme }) => theme.rem(4)};
    text-align: center;
    overflow: hidden;

    ${({ theme }) => theme.mediaQuery.desktop(`
        width: ${theme.rem(480)};
    `)}
`

export const StyledRecapModalImage = styled.img`
    margin-bottom: ${({ theme }) => theme.rem(24)};
    width: 100%;
    aspect-ratio: 21 / 9;
    object-fit: cover;
    object-position: center;

    ${({ theme }) => theme.mediaQuery.desktop(`
        margin-bottom: ${theme.rem(32)};
    `)}
`

export const StyledRecapModalContent = styled.div`
    padding: ${({ theme }) => theme.rem(0, 24)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(0, 64)};
    `)}
`

export const StyledRecapModalActions = styled.div`
    padding: ${({ theme }) => theme.rem(50, 24, 32)};

    ${({ theme }) => theme.mediaQuery.desktop(`
        padding: ${theme.rem(32, 86, 60)};
    `)}

    button {
        width: 100%;
    }
`
