import React from 'react'

export default () => (
  <svg viewBox="0 0 64 64" fill="none">
    <circle cx="32" cy="32" r="32" fill="#CBD4DA" fillOpacity="0.4"/>
    <path d="M44.936 34.9507C44.7963 34.9509 44.6578 34.9236 44.5287 34.8703C44.3996 34.817 44.2824 34.7389 44.1838 34.6403L38.1865 28.6689C38.0877 28.5705 38.0094 28.4537 37.9559 28.3252C37.9025 28.1966 37.875 28.0588 37.875 27.9197C37.875 27.7805 37.9026 27.6428 37.9561 27.5142C38.0096 27.3857 38.088 27.2689 38.1868 27.1705C38.2856 27.0722 38.4029 26.9942 38.532 26.9409C38.6611 26.8877 38.7995 26.8603 38.9392 26.8604C39.0789 26.8604 39.2173 26.8878 39.3464 26.9411C39.4755 26.9943 39.5928 27.0724 39.6915 27.1708L45.6883 33.1421C45.837 33.2903 45.9383 33.4791 45.9794 33.6846C46.0204 33.8901 45.9993 34.1031 45.9188 34.2966C45.8383 34.4902 45.7019 34.6557 45.527 34.7721C45.3521 34.8885 45.1464 34.9507 44.936 34.9507Z" fill="#72989F"/>
    <path opacity="0.51" d="M31.3851 29.9342C36.6738 29.9342 40.9611 25.6651 40.9611 20.3988C40.9611 15.1325 36.6738 10.8633 31.3851 10.8633C26.0964 10.8633 21.8091 15.1325 21.8091 20.3988C21.8091 25.6651 26.0964 29.9342 31.3851 29.9342Z" fill="#A4CAD1"/>
    <path d="M31.3849 30.4635C29.3857 30.4635 27.4314 29.8732 25.7692 28.7672C24.1069 27.6612 22.8113 26.0892 22.0463 24.25C21.2812 22.4109 21.0811 20.3871 21.4711 18.4346C21.8611 16.4822 22.8238 14.6887 24.2374 13.281C25.6511 11.8734 27.4521 10.9148 29.4129 10.5264C31.3737 10.138 33.406 10.3374 35.253 11.0992C37.1 11.861 38.6787 13.1511 39.7894 14.8063C40.9001 16.4615 41.4929 18.4075 41.4929 20.3982C41.4899 23.0668 40.424 25.6252 38.5291 27.5122C36.6341 29.3991 34.0648 30.4605 31.3849 30.4635ZM31.3849 11.3925C29.5961 11.3925 27.8476 11.9207 26.3603 12.9102C24.873 13.8998 23.7138 15.3063 23.0293 16.9519C22.3448 18.5975 22.1657 20.4082 22.5146 22.1552C22.8636 23.9021 23.725 25.5068 24.9898 26.7663C26.2546 28.0257 27.8661 28.8834 29.6205 29.2309C31.3748 29.5784 33.1933 29.4001 34.8459 28.7185C36.4985 28.0368 37.9109 26.8825 38.9047 25.4016C39.8985 23.9206 40.4289 22.1794 40.4289 20.3982C40.4262 18.0106 39.4725 15.7215 37.777 14.0332C36.0815 12.3448 33.7827 11.3952 31.3849 11.3925Z" fill="#90B6BD"/>
    <path d="M20 35.0195V45.5085C20 50.0141 23.668 53.6666 28.1928 53.6666V26.8613C23.668 26.8613 20 30.5138 20 35.0195Z" fill="white"/>
    <path d="M36.3857 45.5085V35.0195C36.3857 30.5138 32.7176 26.8613 28.1929 26.8613V53.6666C32.7176 53.6666 36.3857 50.0141 36.3857 45.5085Z" fill="#F5F5F5"/>
    <path d="M28.1929 37.9337C27.5462 37.9337 27.0225 37.4121 27.0225 36.7682V33.2719C27.0225 32.628 27.5462 32.1064 28.1929 32.1064C28.8395 32.1064 29.3633 32.628 29.3633 33.2719V36.7682C29.3633 37.4121 28.8395 37.9337 28.1929 37.9337Z" fill="#72989F"/>
  </svg>
)
