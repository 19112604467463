import Homepage from '../pages/Homepage'
import House from '../pages/House'
import Logout from '../pages/Logout'
import Neighborhood from '../pages/Neighborhood'
import Room from '../pages/Room'

export const PAGE_TYPES = {
  home: 'VSR Home',
  neighborhood: 'VSR Neighborhood',
  building: 'VSR Building',
}

const routeTemplates = {
  homepage: '/',
  neighborhood: '/:neighborhood',
  house: '/:house',
  apartment: '/:apartment',
  room: '/:room',
  logout: '/logout'
}

export default routeTemplates

export const routes = {
  homepage: '/',
  new: '/it/new',
  renovation: '/it/renovation'
}

export const routesConfig = [
  {
    path: '',
    Page: Homepage,
    pageType: PAGE_TYPES.home,
    props: {
      index: true
    }
  },
  {
    path: routeTemplates.neighborhood,
    Page: Neighborhood,
    pageType: PAGE_TYPES.neighborhood
  },
  {
    path: `${routeTemplates.neighborhood}${routeTemplates.house}`,
    Page: House,
    pageType: PAGE_TYPES.building
  },
  {
    path: `${routeTemplates.neighborhood}${routeTemplates.house}${routeTemplates.apartment}${routeTemplates.room}`,
    Page: Room,
    pageType: PAGE_TYPES.building
  },
  {
    path: routeTemplates.logout,
    Page: Logout
  },
]

export function getDynamicRoute (route, params, lang) {
  if (!route) return
  if (!lang) throw new Error('lang paramenter must have a valid value')
  
  if (typeof params === 'undefined' || params === null) return `/${lang}${route}`
  if (typeof params === 'number' || typeof params === 'string') {
    return `/${lang}${route}/${params}`
  }
}
