import React, { useContext } from 'react'
import { 
  StyledRoomExplorer,
  StyledRoomExplorerItem,
  StyledRoomExplorerInfo,
  StyledRoomExplorerShowRoomModal,
  StyledRoomExplorerButtonWrapper
 } from './styled.js'
import { Button, Icon } from '../'
import { Heading, Paragraph } from '../Typography'
import { Spacer } from '../Layout'
import { Link, useParams, useNavigate } from "react-router-dom"
import { ThemeContext, ThemeProvider } from 'styled-components'
import { Responsive } from '../../contexts/Responsive'
import { useTranslation } from 'react-i18next'

const RoomExplorer = (
  {
    label, 
    rooms, 
    link, 
    setSystemModalOpen, 
    setSystemExplorerModalOpen, 
    roomExplorerRef,
    reverseRoomAnimation
  }) => {
  const { lang, neighborhood, house, apartment} = useParams()
  const { checkBreakpoint } = useContext(ThemeContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <StyledRoomExplorer ref={roomExplorerRef}>
      <StyledRoomExplorerItem>
        <Responsive on={checkBreakpoint.desktop}>
          <StyledRoomExplorerShowRoomModal onClick={() => setSystemExplorerModalOpen()}>
            <Icon name={'pin'} useIconColor />
          </StyledRoomExplorerShowRoomModal>
        </Responsive>
        <StyledRoomExplorerInfo>
          <Paragraph>
            {rooms}
          </Paragraph>
          <Spacer xs={1} />
          <Heading as={'h4'} bold uppercase>
            {label}
          </Heading>
          <Responsive on={checkBreakpoint.not.desktop}>
            <StyledRoomExplorerButtonWrapper>
              <Button secondary icon={'pin'} label={t('ambienti')} small handleClick={() => setSystemExplorerModalOpen()}/>
              <Button secondary icon={'map'} label={t('sistema')} small handleClick={() => setSystemModalOpen()}/>
            </StyledRoomExplorerButtonWrapper>
          </Responsive>
        </StyledRoomExplorerInfo>
      </StyledRoomExplorerItem>
      <StyledRoomExplorerItem>
        <Button small label={link.label} handleClick={() => {
          if (link.url === 'end') {
            reverseRoomAnimation()
            setTimeout(() => {
              navigate(`/${lang}/${neighborhood}/${house}/${apartment}/${link.url}`)
            }, 600)
          } else {
            navigate(`/${lang}/${neighborhood}/${house}/${apartment}/${link.url}`)
          }
        }} />
      </StyledRoomExplorerItem>
    </StyledRoomExplorer>
  )
}

export default RoomExplorer
