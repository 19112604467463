import styled, { css } from 'styled-components/macro'
import { Button } from '../Button/styled'

export const paddedElementStyling = css`
  ${({ theme, noGutter }) => css`
    padding-right: ${noGutter ? 0 : theme.rem(theme.grid.gutter.mobile / 2)};
    padding-left: ${noGutter ? 0 : theme.rem(theme.grid.gutter.mobile / 2)};
  `}

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding-right: 0;
    padding-left: 0;
  `)}
`

export const Section = styled.section`
  ${({ theme, spacing }) => spacing && css`
    padding: ${theme.spacing(spacing)} 0;
  `}
`
export const Container = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  z-index: 10;
  opacity: 1;
  pointer-events: none;

  ${({ theme, noTransition}) => !noTransition && css`
    transition: all ${theme.defaults.fastTransition};
  `}

  ${({ theme }) => theme.mediaQuery.tablet(`
    max-width: ${theme.rem(theme.containerMaxWidths.tablet)};
  `)}

  ${({ theme }) => theme.mediaQuery.desktop(`
    max-width: ${theme.rem(theme.containerMaxWidths.desktop)};
  `)}

  ${({ theme }) => theme.mediaQuery.wide(`
    max-width: ${theme.rem(theme.containerMaxWidths.wide)};
  `)}

  ${({ hide }) => hide && css`
    opacity: 0;
    pointer-events: none;
  `};
`

export const Page = styled.div`
  /* border: 10px solid red; */

  visibility: visible;
  display: flex;

  /* ${({ hide }) => hide && css`
  
    div {
      visibility: hidden;
    }

  `}; */

  /* position: absolute;
  left: 0;
  top: ${({ theme }) => theme.rem(80)}; */
  /* left: 50%;
  transform: translateX(-50%); */
  bottom: 0;
  width: 100vw;
  height: 100%;
  /* height: calc(100vh - ${({ theme }) => theme.rem(80)}); */
  /* z-index: 1; */
  overflow: hidden;
  position: relative;

  ${Container} {
    align-self: ${({ justifyContent }) => justifyContent || 'flex-end'};
  }
`

export const Main = styled.main`
  /* display: grid; */
  width: 100%;
  height: 100%;
  padding-top: ${({ theme }) => theme.rem(80)};
  overflow: hidden;
  position: relative;
  /* display: flex; */


  /* grid-template-rows: 1fr auto; */
  background-color: ${({ theme }) => theme.colors.background};
`


export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
`

export const FlexSpacer = styled.div`
  flex-grow: ${({ priority }) => priority || 1};
`

export const Spacer = styled.div`
  ${({ theme, xs, md, lg, xl }) => css`
    ${typeof xs === 'number' && `height: ${theme.spacing(xs)};`}

    ${typeof md === 'number' && `
      ${theme.mediaQuery.tablet(`
        height: ${theme.spacing(md)};
      `)}
    `}

    ${typeof lg === 'number' && `
      ${theme.mediaQuery.desktop(`
        height: ${theme.spacing(lg)};
      `)}
    `}

    ${typeof xl === 'number' && `
      ${theme.mediaQuery.wide(`
        height: ${theme.spacing(xl)};
      `)}
    `}

  `}
`

export const Grid = styled.div`
  display: flex;
  width: calc(100% + ${({ theme, noGutter }) => noGutter ? 0 : theme.rem(theme.grid.gutter.mobile)});
  position: relative;
  left: ${({ theme, noGutter }) => noGutter ? 0 : theme.rem(theme.grid.gutter.mobile * -0.5)};
  flex-wrap: wrap;

  ${paddedElementStyling}

  ${({ theme, noGutter }) => theme.mediaQuery.desktop(`
    width: calc(100% + ${noGutter ? 0 : theme.rem(theme.grid.gutter.desktop)});
    left: ${theme.rem(theme.grid.gutter.desktop / -2)};
  `)}
`

export const GridItem = styled.div`
  width: ${({ theme, sm }) => (sm || theme.grid.columns) / theme.grid.columns * 100}%;
  padding: 0 ${({ theme }) => theme.rem(theme.grid.gutter.mobile / 2)};

  margin-bottom: ${({ theme, noGutter, noMargin, noMarginSm }) =>
    noGutter || noMargin || noMarginSm ? 0 : theme.rem(theme.grid.gutter.mobile)};
  margin-left: ${({ theme, offsetSm }) => (offsetSm || 0) / theme.grid.columns * 100}%;

  ${({ theme, sm, md, offsetSm, offsetMd, noGutter, noMargin, center }) => theme.mediaQuery.tablet(`
    width: ${(md || sm || theme.grid.columns) / theme.grid.columns * 100}%;
    ${ center || `margin-left: ${(offsetMd || offsetSm || 0) / theme.grid.columns * 100}%;`}
    margin-bottom: ${noGutter || noMargin ? 0 : theme.rem(theme.grid.gutter.mobile)};
  `)}

  ${({ theme, sm, md, lg, offsetSm, offsetMd, offsetLg, noGutter, noMargin, center }) => theme.mediaQuery.desktop(`
    width: ${(lg || md || sm || theme.grid.columns) / theme.grid.columns * 100}%;
    ${ center || `margin-left: ${(offsetLg || offsetMd || offsetSm || 0) / theme.grid.columns * 100}%;` }
    padding: 0 ${theme.rem(theme.grid.gutter.desktop / 2)};
    margin-bottom: ${noGutter || noMargin ? 0 : theme.rem(theme.grid.gutter.desktop)};
  `)}

  ${({ theme, sm, md, lg, xl, offsetSm, offsetMd, offsetLg, offsetXl, center }) => theme.mediaQuery.wide(`
    width: ${(xl || lg || md || sm || theme.grid.columns) / theme.grid.columns * 100}%;
    ${ center || `margin-left: ${(offsetXl || offsetLg || offsetMd || offsetSm || 0) / theme.grid.columns * 100}%;` }
  `)}

  ${({ center }) => center && css`
    margin-left: auto;
    margin-right: auto;
  `}

  ${({ hide }) => hide && css`display: none;`}
`

export const ImageFull = styled.img`
    height: 100%;
    width: auto!important;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    object-fit: cover;
`

export const TopGradient = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
`

export const BottomGradient = styled.img`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;
`

export const BottomLeftBox = styled.div`
  /* position: absolute; */
  left: 0;
  bottom: ${({ theme }) => theme.rem(48)};
  pointer-events: none;
`

export const BottomRightBox = styled.div`
  position: absolute;
  right: 0;
  bottom: ${({ theme }) => theme.rem(48)};
`

export const ShowTutorial = styled.div`
  cursor: pointer;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.rem(2)};
  margin-left: ${({ theme }) => theme.rem(16)};

  /* i {
    font-size: ${({ theme }) => theme.rem(32)};
  } */

`

export const FloatingContainer = styled.div`
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.rem(80)});
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* transform: translateX(-50%); */
  overflow: hidden;
  z-index: 1!important;

  ${({ fixed }) => fixed && css`
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transform: none;
  `}


`

export const TopContainer = styled.div`
  width: 90%;
  height: ${({ theme }) => theme.rem(40)};
  position: absolute;
  left: 50%;
  top: ${({ theme }) => theme.rem(40)};
  transform: translateX(-50%);
  z-index: 10;
  opacity: 1;
  display: flex;
  justify-content: end;
  pointer-events: none;

  ${({ theme }) => theme.mediaQuery.desktop(`
    top: ${theme.rem(40)};
  `)}

  ${({ theme, noTransition}) => !noTransition && css`
    transition: all ${theme.defaults.fastTransition};
  `}

  ${({ theme }) => theme.mediaQuery.tablet(`
    max-width: ${theme.rem(theme.containerMaxWidths.tablet)};
  `)}

  ${({ theme }) => theme.mediaQuery.desktop(`
    max-width: ${theme.rem(theme.containerMaxWidths.desktop)};
  `)}

  ${({ theme }) => theme.mediaQuery.wide(`
    max-width: ${theme.rem(theme.containerMaxWidths.wide)};
  `)}

  ${({ above }) => above && css`
    z-index: 20;
    opacity: 1;
  `}

  ${({ hide }) => hide && css`
    opacity: 0;
    
    span,
    button {
      pointer-events: none!important;
    }
  `};


  > div {
    pointer-events: all;
    position: absolute;
    display: flex;
    color: ${({ theme }) => theme.colors.white};
    text-transform: uppercase;

    &:first-child {
      left: 0;
      top: 50%;
      transform: translateY(-50% );
    }

    &:last-child {
      right: 0;
    }

    > button {
      pointer-events: all;
      &:last-child {
        margin-right: ${({ theme }) => theme.rem(16)};
      }
    }
    
    > span {
      pointer-events: all;

      &:hover {
        color: ${({ theme }) => theme.colors.white}!important;
      }
    }
  }
`