import React, { createContext, useEffect, useState } from 'react'
import { validateUserToken } from '../api'
import { Spinner } from '../components'
import { useParams, useLocation } from "react-router-dom"
import { getApiUrl } from '../api/index'
import { ENDPOINTS } from '../api/config'
import ErrorPageLayout from '../pages/ErrorPageLayout'

const AuthContext = createContext()

const redirectToLogin = (lang, currentPath) => {
  const params = new URLSearchParams()

  if (currentPath) {
    params.set('redirect', currentPath)
  }

  window.location = `${getApiUrl(lang)}/${ENDPOINTS.login}?${params.toString()}`
}

const AuthProvider = ({ children }) => {
  const [verifiedToken, setVerifiedToken] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [ssoRoles, setUserSSORoles] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const { lang } = useParams()
  const location = useLocation()

  const token = localStorage.getItem('authToken')

  if (!token) {
    redirectToLogin(lang, location.pathname)
  }

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const { email, sso_roles } = await validateUserToken(token, lang)
        setUserEmail(email)
        setVerifiedToken(token)
        setUserSSORoles(sso_roles)
        setIsLoading(false)
      } catch (error) {
        if (error.statusCode === 401) {
          redirectToLogin(lang, location.pathname)
        } else {
          setIsLoading(false)
        }
      }
  
    }

    verifyToken()
  }, [])

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && !verifiedToken && <ErrorPageLayout />}
      {!isLoading && verifiedToken && <AuthContext.Provider value={{ authToken: verifiedToken, email: userEmail, ssoRoles: ssoRoles }}>{children}</AuthContext.Provider>}
    </>
  )
}

export { AuthContext, redirectToLogin }
export default AuthProvider
