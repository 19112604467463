import React from 'react'

export default () => (
  <svg viewBox="0 0 40 40" fill="none">
    <circle opacity="0.2" cx="20" cy="20" r="20" fill="#A4CAD1"/>
    <path opacity="0.4" d="M18.5403 20.4062C15.7611 20.4062 13.5 18.1282 13.5 15.3281C13.5 12.528 15.7611 10.25 18.5403 10.25C21.3196 10.25 23.5806 12.528 23.5806 15.3281C23.5806 18.1282 21.3196 20.4062 18.5403 20.4062ZM18.5403 11.0625C16.2058 11.0625 14.3065 12.9761 14.3065 15.3281C14.3065 17.6801 16.2058 19.5937 18.5403 19.5937C20.8748 19.5937 22.7742 17.6801 22.7742 15.3281C22.7742 12.9761 20.8748 11.0625 18.5403 11.0625Z" fill="#72989F"/>
    <path d="M18.5403 18.375C16.8727 18.375 15.5161 17.0082 15.5161 15.3281C15.5161 13.648 16.8727 12.2812 18.5403 12.2812C20.2079 12.2812 21.5645 13.648 21.5645 15.3281C21.5645 17.0082 20.2079 18.375 18.5403 18.375ZM18.5403 13.0937C17.3174 13.0937 16.3226 14.0961 16.3226 15.3281C16.3226 16.5601 17.3174 17.5625 18.5403 17.5625C19.7632 17.5625 20.758 16.5601 20.758 15.3281C20.758 14.0961 19.7632 13.0937 18.5403 13.0937Z" fill="#72989F"/>
    <path d="M24.7924 29.7499C25.0879 28.4146 26.0002 24.0934 26.0002 21.625C26.0002 21.206 25.6124 20.8125 25.134 20.8125C24.7345 20.8125 24.4016 21.0675 24.3016 21.4124C24.2864 20.8546 23.802 20.4062 23.2036 20.4062H23.1665C22.692 20.4062 22.2912 20.6894 22.1364 21.0841C22.1364 21.0841 22.0939 20 21.0368 20H21.0024C20.4849 20 20.0334 20.2839 19.9518 20.8125V15.7344C19.9518 14.9491 19.3199 14.3125 18.5405 14.3125C17.7611 14.3125 17.1292 14.9491 17.1292 15.7344V22.0312H15.1728C14.6944 22.0312 14.3066 22.395 14.3066 22.8437V26.0937C14.3066 27.3912 16.1049 28.3729 16.4203 29.7499H24.7924Z" fill="#A4CAD1"/>
    <path opacity="0.6" d="M16.726 24.4687C16.5033 24.4687 16.3228 24.2869 16.3228 24.0625V22.0312H17.1292V24.0625C17.1292 24.2869 16.9487 24.4687 16.726 24.4687Z" fill="#90B6BD"/>
  </svg>
)
