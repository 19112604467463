import styled, { css } from 'styled-components/macro'
import styledMap from 'styled-map'
import { paddedElementStyling } from '../Layout'

export const StyledSection = styled.section`
  ${({ theme, spacing }) => spacing && css`
    padding: ${theme.spacing(spacing)} 0;
  `}

`
export const StyledSectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  ${({withPadding, theme}) => withPadding && css`
    padding: ${theme.rem(32)} ${theme.rem(16)};

    ${theme.mediaQuery.desktop(`
      padding: ${theme.rem(48)} ${theme.rem(40)};
    `)}

  `}
`
export const StyledSectionTitle = styled.div`
  padding-left: ${({ theme }) => theme.rem(16)};
  padding-right: ${({ theme }) => theme.rem(16)};

  ${({ theme }) => theme.mediaQuery.desktop(`
    padding-left: 0;
    padding-right: 0;
  `)}
`
