const newNeighborhoodIt = require('./it/new.json')
const renewNeighborhoodIt = require('./it/renew.json')
const newNeighborhoodPl = require('./pl/new.json')
const renewNeighborhoodPl = require('./pl/renew.json')
const newNeighborhoodHu = require('./hu/new.json')
const renewNeighborhoodHu = require('./hu/renew.json')
const newNeighborhoodRo = require('./ro/new.json')
const renewNeighborhoodRo = require('./ro/renew.json')

const data = {
    it: {
        home: {
            assets: {
                image: {
                    pre: '/assets/images/it/TV-00.jpg',
                    post: '/assets/images/it/TV-01.jpg'
                },
                video: {
                    enter: 'https://vz-aabf811c-fc9.b-cdn.net/572e4b2c-9e1a-4cea-9dc6-914f5d8cd31d/playlist.m3u8',
                    exit: ''
                }
            },
            components: {
                descriptionBox: {
                    title: 'ESPLORA I SISTEMI ARISTON',
                    text: 'Seleziona un quartiere ed esplora i sistemi Ariston'
                },
                infopoints: [
                    {
                        x: '40',
                        y: '60',
                        tooltip: {
                            title: 'IL QUARTIERE RISTRUTTURATO',
                            text: 'Scopri le soluzioni Ariston per la ristrutturazione: soluzioni efficienti e flessibili adatte per ogni tipo di progetto.',
                            link: {
                                url: 'renew',
                                label: 'ESPLORA QUARTIERE'
                            }
                        }
                    },
                    {
                        x: '60',
                        y: '60',
                        tooltip: {
                            title: 'IL QUARTIERE DELLE NUOVE COSTRUZIONI',
                            text: 'Scopri le soluzioni Ariston per le nuove costruzioni: nuovi modi di vivere il comfort.',
                            link: {
                                url: 'new',
                                label: 'ESPLORA QUARTIERE'
                            }
                        }
                    }
                ]
            },
            neighborhood: {
                renew: renewNeighborhoodIt,
                new: newNeighborhoodIt
            }
        }
    },
    pl: {
        home: {
            assets: {
                image: {
                    pre: '/assets/images/pl/TV-00.jpg',
                    post: '/assets/images/pl/TV-01.jpg'
                },
                video: {
                    enter: 'https://vz-aabf811c-fc9.b-cdn.net/4c0c12ad-3f0e-41ee-a7b2-fe91a9a65434/playlist.m3u8',
                    exit: ''
                }
            },
            components: {
                descriptionBox: {
                    title: 'ODKRYJ SYSTEMY ARISTON',
                    text: 'Wybierz okolicę i poznaj systemy Ariston'
                },
                infopoints: [
                    {
                        x: '40',
                        y: '60',
                        tooltip: {
                            title: 'MIESZKANIA REMONTOWANE',
                            text: 'Odkryj nowe i innowacyjne rozwiązania dla remontowanych mieszkań z produktami Ariston.',
                            link: {
                                url: 'renew',
                                label: 'ZWIEDZAJ MIESZKANIE'
                            }
                        }
                    },
                    {
                        x: '60',
                        y: '60',
                        tooltip: {
                            title: 'MIESZKANIA NOWE',
                            text: 'Odkryj nowe i innowacyjne rozwiązania dla NOWYCH mieszkań z produktami Ariston.',
                            link: {
                                url: 'new',
                                label: 'ZWIEDZAJ MIESZKANIE'
                            }
                        }
                    }
                ]
            },
            neighborhood: {
                renew: renewNeighborhoodPl,
                new: newNeighborhoodPl
            }
        }
    },
    hu: {
        home: {
            assets: {
                image: {
                    pre: '/assets/images/hu/TV-00.jpg',
                    post: '/assets/images/hu/TV-01.jpg'
                },
                video: {
                    enter: 'https://vz-aabf811c-fc9.b-cdn.net/1e98c4eb-f036-41fd-994b-e49c6c300616/playlist.m3u8',
                    exit: ''
                }
            },
            components: {
                descriptionBox: {
                    title: 'Ariston rendszerek felfedezése',
                    text: 'Válasszon épülettípust és fedezze fel az Ariston rendszereit'
                },
                infopoints: [
                    {
                        x: '40',
                        y: '60',
                        tooltip: {
                            title: 'FELÚJÍTOTT ÉPÜLETEK',
                            text: 'Fedezzen fel új, innovatív megoldásokat felújított épületek számára az Ariston termékeivel.',
                            link: {
                                url: 'renew',
                                label: 'Épület felfedezése'
                            }
                        }
                    },
                    {
                        x: '60',
                        y: '60',
                        tooltip: {
                            title: 'ÚJ ÉPÍTÉSŰ ÉPÜLETEK',
                            text: 'Fedezzen fel új, innovatív megoldásokat új építésű otthonok részére az Ariston termékeivel.',
                            link: {
                                url: 'new',
                                label: 'Épület felfedezése'
                            }
                        }
                    }
                ]
            },
            neighborhood: {
                renew: renewNeighborhoodHu,
                new: newNeighborhoodHu
            }
        }
    },
    ro: {
        home: {
            assets: {
                image: {
                    pre: '/assets/images/ro/TV-00.jpg',
                    post: '/assets/images/ro/TV-01.jpg'
                },
                video: {
                    enter: 'https://vz-aabf811c-fc9.b-cdn.net/1e98c4eb-f036-41fd-994b-e49c6c300616/playlist.m3u8',
                    exit: ''
                }
            },
            components: {
                descriptionBox: {
                    title: 'Ariston rendszerek felfedezése',
                    text: 'Válasszon épülettípust és fedezze fel az Ariston rendszereit'
                },
                infopoints: [
                    {
                        x: '40',
                        y: '60',
                        tooltip: {
                            title: 'FELÚJÍTOTT ÉPÜLETEK',
                            text: 'Fedezzen fel új, innovatív megoldásokat felújított épületek számára az Ariston termékeivel.',
                            link: {
                                url: 'renew',
                                label: 'Épület felfedezése'
                            }
                        }
                    },
                    {
                        x: '60',
                        y: '60',
                        tooltip: {
                            title: 'ÚJ ÉPÍTÉSŰ ÉPÜLETEK',
                            text: 'Fedezzen fel új, innovatív megoldásokat új építésű otthonok részére az Ariston termékeivel.',
                            link: {
                                url: 'new',
                                label: 'Épület felfedezése'
                            }
                        }
                    }
                ]
            },
            neighborhood: {
                renew: renewNeighborhoodRo,
                new: newNeighborhoodRo
            }
        }
    },
}

export default data
