import React from 'react'
import { useParams } from 'react-router-dom'
import { ENDPOINTS } from '../api/config'
import { getApiUrl } from '../api'
import { Spinner } from '../components/index'

const redirectToLogout = (lang) => {
  window.location = `${getApiUrl(lang)}/${ENDPOINTS.logout}`
}

const Logout = () => {
  const { lang } = useParams()

  localStorage.removeItem('authToken')
  redirectToLogout(lang)

  return (
    <Spinner />
  )
}

export default Logout
